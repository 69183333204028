// Copyright 2021
// ThatWorks.xyz Limited

import { Remirror, useRemirror } from '@remirror/react';
import { QueryBlockNode, TextBlockNode } from '@thatworks/shared-frontend/prosemirror-nodes';
import { useCallback, useEffect } from 'react';
import { InvalidContentHandler } from 'remirror';
import { ChartNodeReact } from './components/pm-nodes/ChartNode';
import { GroupedInsightPillNodeReact } from './components/pm-nodes/GroupedInsightNode';
import { InlineInsightPillNodeReact } from './components/pm-nodes/InlineInsightPillNode';
import { InsightPillNodeReact } from './components/pm-nodes/InsightNode';
import { MetricBoxNodeReact } from './components/pm-nodes/MetricBoxNode';
import { TaskPreviewNode } from './components/pm-nodes/TaskPreviewNode';
import { getDefaultProseMirrorExtensions, ProseWrapper } from './ProseWrapper';

export function WorkspacePostContent(props: { content: string }) {
    const onError: InvalidContentHandler = useCallback(({ json, invalidContent, transformers }) => {
        // Automatically remove all invalid nodes and marks.
        return transformers.remove(json, invalidContent);
    }, []);

    const { manager, state, getContext } = useRemirror({
        extensions: () => {
            return [
                ...getDefaultProseMirrorExtensions(),
                new MetricBoxNodeReact({ disableExtraAttributes: true }),
                new ChartNodeReact({ disableExtraAttributes: true }),
                new InsightPillNodeReact({ disableExtraAttributes: true }),
                new GroupedInsightPillNodeReact({ disableExtraAttributes: true }),
                new InlineInsightPillNodeReact({ disableExtraAttributes: true }),
                new TaskPreviewNode({ disableExtraAttributes: true }),
                new TextBlockNode({ disableExtraAttributes: true }),
                new QueryBlockNode({ disableExtraAttributes: true }),
            ];
        },
        content: props.content ? JSON.parse(props.content) : undefined,
        onError,
    });

    useEffect(() => {
        // Force an update when the content prop changes
        const context = getContext();
        if (context) {
            context.manager.view.update({
                state: context.manager.createState({ content: props.content ? JSON.parse(props.content) : undefined }),
                editable: () => false,
            });
        }
    }, [getContext, props.content]);

    return (
        <ProseWrapper>
            <Remirror manager={manager} initialContent={state} autoRender={'start'} editable={false} />
        </ProseWrapper>
    );
}
