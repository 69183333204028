// Copyright 2021
// ThatWorks.xyz Limited

import { Blank, IconProps } from 'grommet-icons';

export const ThreeDotsVertical = (props: IconProps & JSX.IntrinsicElements['svg']) => (
    <Blank {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
            <path d="M140,128a12,12,0,1,1-12-12A12,12,0,0,1,140,128ZM128,72a12,12,0,1,0-12-12A12,12,0,0,0,128,72Zm0,112a12,12,0,1,0,12,12A12,12,0,0,0,128,184Z"></path>
        </svg>
    </Blank>
);
