// Copyright 2021
// ThatWorks.xyz Limited

export enum FilterHierarchyType {
    Root = 'Root',
    SpecificItem = 'SpecificItem',
    ConnectorTopLevel = 'ConnectorTopLevel',
    JiraBoard = 'JiraBoard',
    JiraSprint = 'JiraSprint',
    JiraEpic = 'JiraEpic',
    JiraBacklog = 'JiraBacklog',
    JiraProject = 'JiraProject',
    JiraProductDiscoveryView = 'JiraProductDiscoveryView',
    ClickUpSpace = 'ClickUpSpace',
    ClickUpFolder = 'ClickUpFolder',
    ClickUpList = 'ClickUpList',
    GoogleDrive = 'GoogleDrive',
    GoogleDriveFolder = 'GoogleDriveFolder',
    EverythingInConnector = 'ConnectorAll',
    FigmaProject = 'FigmaProject',
    MiroTeam = 'MiroTeam',
    ConfluenceSpace = 'ConfluenceSpace',
    AsanaProject = 'AsanaProject',
    AsanaSection = 'AsanaSection',
    GoogleAnalyticsProperty = 'GoogleAnalyticsProperty',
    TogglProject = 'TogglProject',
    LinearProject = 'LinearProject',
    LinearCycle = 'LinearCycle',
    GoogleSheetNamedRange = 'GoogleSheetNamedRange',
    GoogleSheet = 'GoogleSheet',
    NotionDatabase = 'NotionDatabase',
    NotionPage = 'NotionPage',
    GithubRepo = 'GithubRepo',
    GithubBranch = 'GithubBranch',
    GithubPullRequest = 'GithubPullRequest',
    GitlabProject = 'GitlabProject',
    GitlabBranch = 'GitlabBranch',
    GitlabMergeRequest = 'GitlabMergeRequest',
    GitlabCommit = 'GitlabCommit',
    BitbucketRepo = 'BitbucketRepo',
    BitbucketBranch = 'BitbucketBranch',
    BitbucketPullRequest = 'BitbucketPullRequest',
    BitbucketCommit = 'BitbucketCommit',
    SlackChannel = 'SlackChannel',
    MondayGroup = 'MondayGroup',
    MondayBoard = 'MondayBoard',
    MondayFolder = 'MondayFolder',
    MondayWorkspace = 'MondayWorkspace',
    TrelloBoard = 'TrelloBoard',
    TrelloWorkspace = 'TrelloWorkspace',
    TrelloList = 'TrelloList',
    FirefliesTranscript = 'FirefliesTranscript',
    ZoomSummary = 'ZoomSummary',
}

export const EverythingInConnectorId = 'everything_in_connector_id';

export enum CustomFilePickerIds {
    GoogleDriveAllFiles = 'google_drive_all_files',
    GoogleSheetCustomization = 'google_sheet_customization',
}
