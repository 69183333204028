// Copyright 2021
// ThatWorks.xyz Limited

import { Pages } from '@thatworks/shared-frontend/pages';
import { useMemo } from 'react';
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';
import LoginPage from './login';
import LoginPageNew from './login-new';
import Signup from './signup';

function LoginWrapper(): JSX.Element {
    const [searchParams] = useSearchParams();
    const useAuth0 = useMemo(() => {
        const v = searchParams.get('v');

        return v === '1';
    }, [searchParams]);

    return useAuth0 ? <LoginPage /> : <LoginPageNew />;
}

export default function Auth(): JSX.Element {
    return (
        <Routes>
            <Route path="/" element={<Navigate to={Pages.auth.subs.login} replace />} />
            <Route path={Pages.auth.subs.login} element={<LoginWrapper />} />
            <Route path={Pages.auth.subs.signup} element={<Signup />} />
        </Routes>
    );
}
