// Copyright 2021
// ThatWorks.xyz Limited

import { Pages } from '@thatworks/shared-frontend/pages';
import { Button } from 'grommet';
import { Outlet, Route, Routes } from 'react-router-dom';
import { GenericBrandPage } from '../../components/GenericBrandPage';
import { ParamKey } from '../../shared/param-keys';
import { useNavNoRerender } from '../../shared/UseNavNoRerender';
import TwIcon from './../../icons/tw_icon.svg?react';
import { PublicLinkOutlet } from './components/PublicLinkOutlet';

function Page(): JSX.Element {
    const navigate = useNavNoRerender();

    return (
        <GenericBrandPage
            browserPageTitle="That Works"
            innerWidth={{ width: '100%', max: '900px' }}
            backgroundColor="#F6F1ED"
            header={
                <Button
                    primary
                    label="Your account"
                    icon={<TwIcon width="16px" height="16px" fill="#ffffff" />}
                    onClick={() => navigate('/')}
                />
            }
        >
            <Outlet />
        </GenericBrandPage>
    );
}

export default function Public(): JSX.Element {
    return (
        <Routes>
            <Route path={`/${Pages.public.subs.post}`} element={<Page />}>
                <Route path={`:${ParamKey.PublicPostUuid}/*`} element={<PublicLinkOutlet type="post" />} />
            </Route>
            <Route path={`/${Pages.public.subs.token}`} element={<Page />}>
                <Route path={`:${ParamKey.SlackTokenId}/*`} element={<PublicLinkOutlet type="token" />} />
            </Route>
            <Route path="/" element={<Page />} />
        </Routes>
    );
}
