// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { ConnectorName, getFormattedConnectorName } from '@thatworks/connector-api';
import { Box, Spinner, Text, Tip } from 'grommet';
import { CircleAlert, Lock, StatusGood } from 'grommet-icons';
import {
    ConnectorConnectedState,
    ConnectorPermissionStatus,
    UserConnectorDataStatus,
} from '../../../../__generated__/graphql';
import { ConnectorIconSmall } from '../../../../components/ConnectorIcon';
import { IconButtonV2 } from '../../../../components/IconButton';
import { tooltipProps } from '../../../../components/Tooltip';
import { ThreeDotsVertical } from '../../../../icons/ThreeDotsVertical';

function getDataLabel(dataState: UserConnectorDataStatus): string {
    switch (dataState) {
        case UserConnectorDataStatus.Ok:
            return 'Up to date';
        case UserConnectorDataStatus.Processing:
            return 'Processing';
        case UserConnectorDataStatus.Fail:
            return 'Data Error';
        case UserConnectorDataStatus.Stale:
            return 'Stale Data';
    }
}

function getDataTooltip(dataState: UserConnectorDataStatus): string | undefined {
    switch (dataState) {
        case UserConnectorDataStatus.Processing:
            return 'Usually takes a few minutes to complete';
        case UserConnectorDataStatus.Fail:
            return 'There was an error fetching your latest data and we are looking into it. Please check back in about 30 minutes.';
        case UserConnectorDataStatus.Stale:
            return 'Try reconnecting your account';
    }
}

function getDataIcon(dataState: UserConnectorDataStatus | undefined): JSX.Element {
    if (!dataState) {
        return <Spinner width="14px" height="14px" />;
    }
    switch (dataState) {
        case UserConnectorDataStatus.Ok:
            return <StatusGood size="16px" color={Colors.status_ok} />;
        case UserConnectorDataStatus.Processing:
            return <Spinner width="14px" height="14px" />;
        case UserConnectorDataStatus.Fail:
            return <CircleAlert size="16px" color={Colors.status_warning} />;
        case UserConnectorDataStatus.Stale:
            return <CircleAlert size="16px" color={Colors.status_critical} />;
    }
}

function getDataTooltipProps(dataState: UserConnectorDataStatus) {
    const t = getDataTooltip(dataState);
    if (!t) {
        return { plain: true };
    }
    return tooltipProps(getDataTooltip(dataState));
}

export function ConnectedConnectorButton(props: { state: ConnectorConnectedState; onClick?: () => void }): JSX.Element {
    return (
        <Box
            focusIndicator={false}
            border={{ side: 'bottom', size: '1px', color: Colors.border_dark }}
            width={{ width: '820px', max: '820px' }}
            direction="row"
            justify="between"
            onClick={props.state.isConnectedByUser ? props.onClick : undefined}
        >
            <Box align="center" direction="row" gap="xsmall" pad="15px">
                <ConnectorIconSmall name={props.state.connector as ConnectorName} sizePixels={`35px`} />
                <Box width="100%" justify="evenly" gap="xxsmall">
                    <Box wrap>
                        <Text size="14px" wordBreak="break-all" weight={'bold'}>
                            {getFormattedConnectorName(props.state.connector)}: {props.state.accountDisplayName}
                        </Text>
                    </Box>
                    <Box direction="row" align="center" gap="4px">
                        <Lock size="10px" />
                        <Text size="10px">This connection is private to your account</Text>
                    </Box>
                </Box>
            </Box>
            {props.state.isConnectedByUser && (
                <Box direction="row" align="center" gap="xsmall" width={{ min: 'max-content' }}>
                    <Box round="8px" direction="row" align="center" gap="6px">
                        {props.state.status.permissions === ConnectorPermissionStatus.Ok && (
                            <StatusGood size="16px" color="status-ok" />
                        )}
                        {props.state.status.permissions !== ConnectorPermissionStatus.Ok && (
                            <CircleAlert size="16px" color="status-critical" />
                        )}
                        <Text size="14px">Permissions</Text>
                    </Box>
                    <Box round="8px">
                        <Tip {...getDataTooltipProps(props.state.status.data)}>
                            <Box direction="row" align="center" gap="6px">
                                {getDataIcon(props.state.status.data)}
                                {(props.state.firstPollProgress == null || props.state.firstPollProgress === 1) && (
                                    <Text size="14px">{getDataLabel(props.state.status.data)}</Text>
                                )}
                                {props.state.firstPollProgress != null && props.state.firstPollProgress < 1 && (
                                    <Text size="14px">
                                        Processing {Math.round(props.state.firstPollProgress * 100)}%
                                    </Text>
                                )}
                            </Box>
                        </Tip>
                    </Box>
                    {props.onClick && (
                        <IconButtonV2
                            icon={(hover) => (
                                <ThreeDotsVertical
                                    width="14px"
                                    height="14px"
                                    color={hover ? Colors.brand : undefined}
                                />
                            )}
                            onClick={props.onClick}
                        />
                    )}
                </Box>
            )}
        </Box>
    );
}
