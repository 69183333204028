// Copyright 2021
// ThatWorks.xyz Limited

import { useStatsigClient } from '@statsig/react-bindings';
import { Colors } from '@thatworks/colors';
import { Events } from '@thatworks/shared-frontend/metrics';
import { Box, InfiniteScroll, Text } from 'grommet';
import { useMemo } from 'react';
import { GetHomeScopesQuery } from '../../../../__generated__/graphql';
import { TimelineDateSelection } from '../magic-composer/filters/timeline-date-selection';
import { HomeScopeSummarySkeleton, ScopeSummary } from './ScopeSummary';

export function ScopesLoading(): JSX.Element {
    return (
        <Box
            border={{ color: Colors.border_light }}
            round="10px"
            background={{ color: Colors.background_back }}
            elevation={'xsmall'}
            animation={{ type: 'fadeIn', duration: 400 }}
            pad={'small'}
        >
            <HomeScopeSummarySkeleton />
        </Box>
    );
}

export function ScopesEmpty(props: { message?: string }): JSX.Element {
    return (
        <Box
            border={{ color: Colors.border_dark }}
            round="10px"
            background={{ color: Colors.background_back }}
            elevation={'xsmall'}
            animation={{ type: 'fadeIn', duration: 400 }}
            pad="small"
        >
            <Text size="16px">{props.message || 'No updates. Select a different time period.'}</Text>
        </Box>
    );
}

export function ScopesList(props: {
    fromDate: TimelineDateSelection;
    sessionCacheId: string;
    appFilter: { label: string; id: string }[];
    homeScopes: GetHomeScopesQuery['homeScopes'] | undefined;
    loading: boolean;
}): JSX.Element {
    const { logEvent } = useStatsigClient();

    const scopesList = useMemo(() => {
        if (!props.homeScopes) {
            return [];
        }

        if (props.appFilter.length === 0) {
            return props.homeScopes.scopes;
        }

        const ids = new Set(props.appFilter.map((c) => c.id));
        return props.homeScopes.scopes.filter((scope) => ids.has(scope.scope.id));
    }, [props.appFilter, props.homeScopes]);

    // Loading scopes
    if (props.loading) {
        return <ScopesLoading />;
    }

    // No scopes available
    if (!props.homeScopes || scopesList.length === 0) {
        return <ScopesEmpty />;
    }

    // Scopes available
    return (
        <Box overflow={{ vertical: 'auto' }}>
            <InfiniteScroll items={scopesList} step={2} onMore={() => logEvent(Events.FrontendHomePageLoadMore)}>
                {(
                    item: GetHomeScopesQuery['homeScopes']['scopes'][0],
                    index: number,
                    ref: React.LegacyRef<HTMLDivElement>,
                ) => {
                    return (
                        <Box
                            margin={{ bottom: 'small' }}
                            height={{ min: 'fit-content' }}
                            ref={ref}
                            key={`scope-${item.scope.id}-${index}`}
                        >
                            <ScopeSummary
                                scope={item}
                                defaultOpen
                                key={`scope-summary-${item.scope.id}`}
                                sessionCacheId={props.sessionCacheId}
                                fromDate={props.fromDate}
                                maxNumUpdates={props.homeScopes?.maxNumUpdates ?? 0}
                                minNumUpdates={props.homeScopes?.minNumUpdates ?? 0}
                                favorite={item.favorite}
                            />
                        </Box>
                    );
                }}
            </InfiniteScroll>
        </Box>
    );
}
