// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box, BoxProps, Text, TextExtendedProps } from 'grommet';
import { MailOption } from 'grommet-icons';
import { PlainTextInput } from '../../../../../components/PlainTextInput';
import { useUserStateContext } from '../../../../../components/UserContext';
import { FontFamily } from '../../../../../theme';
import { WorkspacesBrowser } from '../../templates/components/WorkspacesBrowser';
import TwIcon from './../../../../../icons/tw_icon.svg?react';
import { EmailList } from './EmailList';
import { AutomationState } from './MagicComposerAutomate';

export const SHARE_SECTION_BOX_PROPS: BoxProps = {
    gap: '12px',
    border: { side: 'bottom', size: '1px', color: Colors.border_dark },
    pad: { horizontal: 'small', vertical: 'xsmall' },
};

export const SHARE_SECTION_TITLE_PROPS: TextExtendedProps = {
    style: { fontFamily: FontFamily.Callout, textTransform: 'uppercase', letterSpacing: '2.1px' },
    size: '14px',
    weight: 'bold',
};

const SHARE_SUB_TITLE_TEXT_PROPS: TextExtendedProps = {
    size: '16px',
    weight: 'bold',
};

export function ShareEmail(props: Parameters<typeof EmailList>[0]): JSX.Element {
    return (
        <Box gap="8px">
            <Box direction="row" align="center" gap="xxsmall">
                <Text {...SHARE_SUB_TITLE_TEXT_PROPS}>Email</Text>
                <MailOption size="14px" />
            </Box>
            <EmailList {...props} />
        </Box>
    );
}

export function ShareThatWorks(props: {
    workspace: AutomationState['workspace'];
    onSelected: (
        s:
            | {
                  id: string;
                  label: string;
              }
            | undefined,
    ) => void;
}): JSX.Element {
    const { organizationId } = useUserStateContext();

    return (
        <Box gap="8px">
            <Box direction="row" align="center" gap="xxsmall">
                <Text {...SHARE_SUB_TITLE_TEXT_PROPS}>Topic</Text>
                <TwIcon width="16px" height="16px" />
            </Box>
            {organizationId && (
                <WorkspacesBrowser
                    organizationId={organizationId}
                    selected={props.workspace}
                    onSelected={props.onSelected}
                />
            )}
        </Box>
    );
}

export function ShareTitleSection(props: { title: string; onChange: (title: string) => void }): JSX.Element {
    return (
        <Box {...SHARE_SECTION_BOX_PROPS}>
            <Text {...SHARE_SECTION_TITLE_PROPS}>Title</Text>
            <PlainTextInput
                fontSize="13px"
                value={props.title}
                placeholder="Untitled"
                style={{
                    fontFamily: FontFamily.Callout,
                    borderRadius: '8px',
                    fontWeight: 'bold',
                    letterSpacing: '-0.02em',
                    color: 'unset',
                    background: 'unset',
                    border: `1px solid ${Colors.black}`,
                    padding: '5px 10px',
                    backgroundColor: Colors.background_front,
                }}
                width="inherit"
                onChange={(e) => props.onChange(e.target.value)}
            />
        </Box>
    );
}
