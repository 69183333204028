// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box, CheckBox, List, Text } from 'grommet';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { GroupSettingsInput } from '../../../../../__generated__/graphql';
import { CtaButtonSpinnerV2 } from '../../../../../components/CtaButtonSpinner';

const StyledList = styled(List)`
    width: 100%;

    li {
        padding: 0 16px;
        height: 60px;
    }

    div:nth-child(1) {
        padding-right: 16px;
    }

    div:nth-child(2) {
        display: none;
    }

    div:nth-child(3) {
        button:first-child {
            margin-right: 16px;
        }
        button {
            padding: 0px;
        }
    }
`;

export function GroupSectionOrdering(props: {
    subgroupNames: string[] | undefined;
    group: GroupSettingsInput;
    onOrderingChange: (newOrder: string[] | undefined) => void;
}): JSX.Element {
    const [enabled, setEnabled] = useState<boolean>(
        props.group.subgroupOrdering != null && props.group.subgroupOrdering.length > 0,
    );

    const [subgroupNames, setSubgroupNames] = useState<string[] | undefined>(() => {
        const existingOrdering = new Set(props.group.subgroupOrdering || []);
        props.subgroupNames?.forEach((name) => {
            if (!existingOrdering.has(name)) {
                existingOrdering.add(name);
            }
        });
        return Array.from(existingOrdering);
    });
    const settingsAreEqual = useMemo(() => {
        return props.group.subgroupOrdering?.join('') === subgroupNames?.join('');
    }, [props.group.subgroupOrdering, subgroupNames]);

    if (!subgroupNames || subgroupNames.length === 0) {
        return <></>;
    }

    return (
        <Box height={{ min: 'min-content' }} border={{ color: Colors.border_dark, size: '1px', side: 'top' }}>
            <Box pad={{ horizontal: 'small', vertical: 'xsmall' }}>
                <CheckBox
                    reverse={true}
                    label={
                        <Text size="16px" weight={enabled ? 'bold' : undefined}>
                            Customize ordering
                        </Text>
                    }
                    checked={enabled}
                    onChange={(e) => {
                        setEnabled(e.target.checked);
                        if (!e.target.checked) {
                            props.onOrderingChange(undefined);
                        }
                    }}
                />
            </Box>
            {enabled && (
                <Box>
                    <Box direction="row">
                        <StyledList
                            data={subgroupNames}
                            onOrder={(ordered) => setSubgroupNames(ordered as string[])}
                            showIndex={false}
                            key={props.group.groupType}
                            // eslint-disable-next-line react/no-children-prop
                            children={(item) => (
                                <Text
                                    size="12px"
                                    style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                                >
                                    {item as string}
                                </Text>
                            )}
                        />
                    </Box>

                    {!settingsAreEqual && (
                        <Box direction="row" pad={{ horizontal: 'small', vertical: 'xsmall' }}>
                            <CtaButtonSpinnerV2
                                style={{ width: '100px' }}
                                label="Apply"
                                onClick={async () => {
                                    props.onOrderingChange(subgroupNames);
                                }}
                            />
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
}
