// Copyright 2021
// ThatWorks.xyz Limited

import { ConnectorName } from '@thatworks/connector-api';
import { UNASSIGNED_GROUP_NAME } from '@thatworks/shared-frontend/grouping';
import {
    SummarizedActivityInlineInsightPill,
    SummarizedActivityNodeBase,
    SummarizedActivityNodeParser,
    SummarizedActivityTableNode,
} from '@thatworks/shared-frontend/prosemirror';
import {
    ActivityItemPropertyType,
    ActivityItemPropertyValueType,
    GetTimelineMetricChartsQuery,
    StringType,
    TimelineActivityQuery,
    TimelineIndicatorsQuery,
} from '../../../../../__generated__/graphql';
import { GroupedInsightNodeData } from '../../ws/components/pm-nodes/GroupedInsightNode';
import { InsightNodeData } from '../../ws/components/pm-nodes/InsightNode';
import { TaskPreviewInfo } from '../../ws/components/pm-nodes/TaskPreviewNode';

export const EmptyGroupMapping: Map<string, string> = new Map([[UNASSIGNED_GROUP_NAME, 'Unassigned items']]);

export type SummarizedActivityNode = SummarizedActivityNodeBase<TaskPreviewInfo, InsightNodeData>;

export function fromActivityItemToPreview(
    item: TimelineActivityQuery['timelineActivity']['items'][0],
): TaskPreviewInfo {
    return {
        title: item.title,
        iconUrl: item.iconUrl || undefined,
        connector: item.connector as ConnectorName,
        id: item.id,
        properties: item.properties.map((l) => ({
            name: l.name,
            value: l.value,
            color: l.color || undefined,
            iconUrl: l.iconUrl || undefined,
            valueType: l.valueType || ActivityItemPropertyValueType.String,
            propertyType: l.propertyType || ActivityItemPropertyType.Unclassified,
        })),
        changeDescription: item.changeDescription,
        parents: item.parents.map((p) => ({
            connectorObjectType: p.readableConnectorObjectType,
            name: p.name,
            url: p.url || undefined,
        })),
        url: item.url || undefined,
        docDiff:
            item.docDiff && item.docDiff.__typename === 'DocDiffSummarized'
                ? { summary: item.docDiff.summary }
                : undefined,
        comments: item.comments || undefined,
    };
}

export class FrontendSummarizedActivityNodeParser extends SummarizedActivityNodeParser<
    TaskPreviewInfo,
    TimelineActivityQuery['timelineActivity'],
    TimelineActivityQuery['timelineActivity']['items'][0],
    GetTimelineMetricChartsQuery['timelineMetricCharts'],
    TimelineIndicatorsQuery['timelineIndicators'],
    SummarizedActivityNode
> {
    fromActivityItemToPreview(item: TimelineActivityQuery['timelineActivity']['items'][0]): TaskPreviewInfo {
        return fromActivityItemToPreview(item);
    }

    parseStringToHtml(text: string): globalThis.Node {
        const parsedHtml = new DOMParser().parseFromString(text, 'text/html');
        return parsedHtml;
    }

    parseActivity(
        title: string | undefined,
        data: TimelineActivityQuery['timelineActivity'],
    ): SummarizedActivityNode[] {
        const nodes: SummarizedActivityNode[] = [];

        // Add the title if received
        if (title) {
            nodes.push({
                type: 'heading',
                attrs: {
                    level: 2,
                },
                content: [{ text: title, type: 'text' }],
            });
        }

        const itemIdMap = new Map(data.items.map((item) => [item.id, item]));
        data.groups.forEach((group) => {
            group.subgroups.forEach((subgroup) => {
                if (subgroup.summary == null) {
                    return;
                }

                if (subgroup.name) {
                    const mappedLabel = EmptyGroupMapping.get(subgroup.name);
                    const subgroupName = mappedLabel || subgroup.name;
                    nodes.push({
                        type: 'heading',
                        attrs: {
                            level: 4,
                        },
                        content: [{ text: subgroupName, type: 'text' }],
                    });
                }

                subgroup.summary.summary.sections.forEach((sectionGroup) => {
                    const table: SummarizedActivityTableNode<TaskPreviewInfo, unknown> = {
                        type: 'table',
                        rows: [],
                    };

                    sectionGroup.forEach((section) => {
                        const pillsInlineData = section.pills?.map((pill) => {
                            const r: SummarizedActivityInlineInsightPill<TaskPreviewInfo>['data'] = {
                                value: pill.value,
                                color: pill.color || undefined,
                                iconUrl: pill.iconUrl || undefined,
                                connector: pill.connector ? (pill.connector as ConnectorName) : undefined,
                                items: this.getPreviewItemsFromUuids(pill.itemUuids, itemIdMap),
                            };
                            return r;
                        });

                        let pillCell: (typeof table)['rows'][0]['cells'][0] | undefined;
                        if (pillsInlineData && pillsInlineData.length > 0) {
                            pillCell = {
                                content: [
                                    {
                                        type: 'paragraph',
                                        attrs: {
                                            class: 'inline-pill-paragraph',
                                        },
                                        content: [
                                            {
                                                type: 'inline-insight-pill',
                                                data: pillsInlineData,
                                            },
                                        ],
                                    },
                                ],
                                attrs: {
                                    colwidth: section.newRowForPills ? [] : [200],
                                },
                            };
                        }

                        const textCell: (typeof table)['rows'][0]['cells'][0] = { content: [] };
                        textCell.content.push({
                            type: 'markdown',
                            text: section.markdown,
                        });

                        if (section.newRowForPills) {
                            if (pillCell) {
                                table.rows.push({
                                    cells: [pillCell],
                                });
                            }
                            table.rows.push({
                                cells: [textCell],
                            });
                        } else {
                            const row: (typeof table)['rows'][0] = {
                                cells: pillCell ? [pillCell, textCell] : [textCell],
                            };
                            table.rows.push(row);
                        }
                    });

                    nodes.push(table);
                });
            });
        });

        if (data.groups.length === 0) {
            nodes.push({
                type: 'paragraph',
                attrs: {},
                content: [{ text: 'No updates found.', type: 'text' }],
            });
        }

        return nodes;
    }

    parseMetricChartData(
        title: string | undefined,
        data: GetTimelineMetricChartsQuery['timelineMetricCharts'],
    ): SummarizedActivityNode[] {
        const res: SummarizedActivityNode[] = [];

        // Add the title if received
        if (title) {
            res.push({
                type: 'heading',
                attrs: {
                    level: 2,
                },
                content: [{ text: title, type: 'text' }],
            });
        }

        if (data.data.length === 0) {
            res.push({
                type: 'paragraph',
                attrs: {},
                content: [{ text: 'No data found.', type: 'text' }],
            });
            return res;
        }
        const mapped = data.data.map((chart) => {
            const n: SummarizedActivityNode = {
                type: 'metric-chart',
                dataJsonString: JSON.stringify(chart),
            };
            return n;
        });
        return res.concat(mapped);
    }

    parseIndicatorData(
        title: string | undefined,
        data: TimelineIndicatorsQuery['timelineIndicators'],
    ): SummarizedActivityNode[] {
        // Initialize nodes
        const nodes: SummarizedActivityNode[] = [];

        // Add the title if received
        if (title) {
            nodes.push({
                type: 'heading',
                attrs: {
                    level: 2,
                },
                content: [{ text: title, type: 'text' }],
            });
        }

        // Check if we have insights
        if (data.insights.length > 0) {
            const itemMap = new Map(data.items.map((item) => [item.id, item]));

            // Add the insights to the nodes
            nodes.push({
                type: 'insight-pill',
                insights: data.insights.map((insight) => {
                    const items: TaskPreviewInfo[] = [];
                    const itemIds = insight.data.flatMap((d) => d.itemUuids);
                    itemIds.forEach((uuid) => {
                        const item = itemMap.get(uuid);
                        if (item) {
                            items.push(this.fromActivityItemToPreview(item));
                        }
                    });

                    const r: InsightNodeData = {
                        identifier: insight.identifier,
                        connector: insight.connector,
                        title: insight.title,
                        iconUrl: insight.iconUrl,
                        data: insight.data,
                        items,
                    };
                    return r;
                }),
            });
        }

        // Check if we have grouped insights
        if (data.groupedInsights.length > 0) {
            const itemMap = new Map(data.items.map((item) => [item.id, item]));
            data.groupedInsights.forEach((group) => {
                // Add the insights to the nodes
                const r: GroupedInsightNodeData = {
                    title: group.title,
                    identifier: group.identifier,
                    description: group.description || undefined,
                    insights: group.insights.map((insight) => {
                        const items: TaskPreviewInfo[] = [];
                        const itemIds = insight.data.flatMap((d) => d.itemUuids);
                        itemIds.forEach((uuid) => {
                            const item = itemMap.get(uuid);
                            if (item) {
                                items.push(this.fromActivityItemToPreview(item));
                            }
                        });

                        const rr: InsightNodeData = {
                            connector: insight.connector,
                            title: insight.title,
                            iconUrl: insight.iconUrl,
                            data: insight.data,
                            items,
                            identifier: insight.identifier,
                        };
                        return rr;
                    }),
                };
                nodes.push({
                    type: 'group-insight-pill',
                    group: r,
                });
            });
        }

        // Check if we have metricBoxes
        if (data.metricBoxes.length > 0) {
            // Add the metrics to the nodes
            nodes.push({
                type: 'indicators',
                data: data.metricBoxes.map((d) => ({
                    parents: d.parents.map((p) => ({
                        connectorObjectType: p.connectorObjectType,
                        url: p.url || undefined,
                        name: p.name,
                    })),
                    identifier: d.identifier,
                    title: d.title,
                    value: d.value,
                    valueFormatted: d.valueFormatted,
                    connector: d.connector || undefined,
                    itemUuid: d.itemUuid || undefined,
                    xAxisValue: d.xAxisValue
                        ? {
                              type: d.xAxisValue.type === StringType.DateIso ? 'date-iso' : 'date-iso',
                              value: d.xAxisValue.value,
                          }
                        : undefined,
                })),
            });
        }

        // Add a message if don't have any data
        if (data.insights.length === 0 && data.metricBoxes.length === 0 && data.groupedInsights.length === 0) {
            nodes.push({
                type: 'paragraph',
                attrs: {},
                content: [{ text: 'No data found or no indicators selected to display.', type: 'text' }],
            });
        }

        // Return the nodes
        return nodes;
    }

    parseMarkdownText(text: string, parseAsSlice?: boolean): SummarizedActivityNode[] {
        return [
            {
                type: 'markdown',
                text,
                parseAsSlice,
            },
        ];
    }
}
