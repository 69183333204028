// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { getFormattedConnectorName } from '@thatworks/connector-api';
import { getQueryParamErrorDescription, joinPagesPaths, Pages, QueryParams } from '@thatworks/shared-frontend/pages';
import { Box, Spinner, Text } from 'grommet';
import { StatusWarning } from 'grommet-icons';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../components/AuthProvider';
import { useTelemetryContext } from '../../components/TelemetryContext';
import { withAuth } from '../../components/withAuth';
import { connectConnectorGetUrl, ConnectConnectorParams, getAbsoluteUrl } from '../../shared/connectors-helper';
import { useNavNoRerender } from '../../shared/UseNavNoRerender';
import TwIcon from './../../icons/tw_icon.svg?react';

export function ConnectPopupOAuthComplete(): JSX.Element {
    const [searchParams] = useSearchParams();
    const [genericError, setGenericError] = useState<{ connector?: string; message: string }>();
    const [timeoutText, setTimeoutText] = useState<string>();

    useEffect(() => {
        let errorFound = false;
        const error = searchParams.get(QueryParams.Error);
        if (error) {
            const connectorNameParam = searchParams.get(QueryParams.ConnectorName);
            const connectorName = connectorNameParam ? getFormattedConnectorName(connectorNameParam) : '';
            setGenericError({ connector: connectorName, message: getQueryParamErrorDescription(error) });
            errorFound = true;
        }

        if (!errorFound) {
            window.close();
            setTimeout(() => setTimeoutText('Done! This window can be closed.'), 3000);
        }
    }, [searchParams]);

    return (
        <Box fill align="center" justify="center" pad="medium">
            {!genericError && !timeoutText && <Spinner />}
            {!genericError && timeoutText && <Text>{timeoutText}</Text>}
            {genericError && (
                <Box gap="xsmall" align="center" justify="center">
                    <Box
                        align="center"
                        justify="center"
                        pad="small"
                        background={{ color: 'status-warning' }}
                        border={{ color: 'status-critical', size: '2px' }}
                        round="10px"
                        gap="xxsmall"
                    >
                        <Box direction="row" gap="4px" align="center">
                            <StatusWarning size="18px" /> <Text weight={'bold'}>Failed To Connect</Text>
                        </Box>
                        <Text textAlign="center">{genericError.message}</Text>
                        <Text textAlign="center">Close this window and try reconnecting.</Text>
                    </Box>
                    <Box
                        border={{ color: 'border', size: '2px' }}
                        style={{ borderRadius: '50%' }}
                        width="50px"
                        height="50px"
                    >
                        <TwIcon style={{ alignSelf: 'center' }} width="30px" fill={Colors.brand} />
                    </Box>
                </Box>
            )}
        </Box>
    );
}

function ConnectPopup(): JSX.Element {
    const { getAccessTokenSilently, isAuthenticated } = useAuth();
    const { logger } = useTelemetryContext();
    const navigate = useNavNoRerender();
    const [searchParams] = useSearchParams();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    useEffect(() => {
        (async () => {
            if (!isAuthenticated()) {
                // Might return early if auth still needs to be kicked off
                return;
            }

            const data = searchParams.get(QueryParams.OAuthConnectionData);
            if (!data) {
                setErrorMessage(`No valid data parameters found`);
                return;
            }

            const obj = JSON.parse(window.atob(data)) as Partial<ConnectConnectorParams> | undefined;
            if (!obj || !obj.connectorName) {
                setErrorMessage(`No valid connector parameters found`);
                return;
            }

            const tokens = await getAccessTokenSilently();
            try {
                const endpoint = await connectConnectorGetUrl(
                    obj.connectorName,
                    tokens,
                    {
                        preAuthState: obj.preAuthState,
                        selectedLinkedApps: obj.selectedLinkedApps,
                    },
                    getAbsoluteUrl(
                        joinPagesPaths([Pages.connector_oauth.root, Pages.connector_oauth.subs.oauthComplete]),
                    ),
                );
                if (!endpoint) {
                    setErrorMessage(`Failed to get endpoint from server`);
                    return;
                }
                window.location.href = endpoint;
            } catch (error) {
                logger.exception(error);
                setErrorMessage(`Failed to connect to server`);
            }
        })();
    }, [getAccessTokenSilently, isAuthenticated, logger, navigate, searchParams]);

    return (
        <Box fill align="center" justify="center">
            {!errorMessage && <Spinner />}
            {errorMessage && <Text>Error: {errorMessage}</Text>}
        </Box>
    );
}

export const ConnectPopupWithAuth = withAuth(ConnectPopup);
