// Copyright 2021
// ThatWorks.xyz Limited

import { autoPlacement, autoUpdate, Placement, useDismiss, useFloating, useInteractions } from '@floating-ui/react';
import { Colors } from '@thatworks/colors';
import { Box } from 'grommet';
import React, { useState } from 'react';
import { IconButtonV2, IconButtonV2Props } from './IconButton';

export function TwDropButton(props: {
    button: IconButtonV2Props;
    menuButtons: IconButtonV2Props[];
    placements?: Placement[];
}): JSX.Element {
    const [showDrop, setShowDrop] = useState(false);

    const { refs, floatingStyles, context } = useFloating({
        whileElementsMounted: autoUpdate,
        middleware: [
            autoPlacement({
                allowedPlacements: props.placements || ['bottom-start'],
            }),
        ],
        open: showDrop,
        onOpenChange: setShowDrop,
    });

    const dismiss = useDismiss(context);
    const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

    return (
        <Box ref={refs.setReference} {...getReferenceProps()} direction="row" align="center">
            <IconButtonV2 {...props.button} onClick={() => setShowDrop(true)} />
            {showDrop && (
                <div ref={refs.setFloating} style={{ ...floatingStyles, zIndex: 1000 }} {...getFloatingProps()}>
                    <Box
                        gap="xxsmall"
                        pad="xxsmall"
                        background={{ color: Colors.background_front }}
                        border={{ color: Colors.border_dark, size: '1px' }}
                        round={'5px'}
                        elevation="xsmall"
                    >
                        {props.menuButtons.map((button, index) => (
                            <IconButtonV2
                                key={`drop-${index}`}
                                {...button}
                                onClick={() => {
                                    setShowDrop(false);
                                    if (button.onClick) {
                                        button.onClick();
                                    }
                                }}
                            />
                        ))}
                    </Box>
                </div>
            )}
        </Box>
    );
}
