// Copyright 2021
// ThatWorks.xyz Limited

import { useQuery } from '@apollo/client';
import { autoPlacement, autoUpdate, useDismiss, useFloating, useInteractions } from '@floating-ui/react';
import { Colors } from '@thatworks/colors';
import { Box } from 'grommet';
import { useMemo, useState } from 'react';
import { GroupSettingsInput, ItemGroup, ItemGroupPredefinedType } from '../../../../../__generated__/graphql';
import { DropdownMenuChecklist } from '../../../../../components/DropdownMenu';
import { ToolbarButton } from '../../../../../components/prosemirror/ToolbarButton';
import { useTelemetryContext } from '../../../../../components/TelemetryContext';
import { useUserStateContext } from '../../../../../components/UserContext';
import { Folder } from '../../../../../icons/Folder';
import { GET_TIMELINE_PROPERTIES } from './filter-toolbar-button/FilterToolbarButton';
import { GroupSectionOrdering } from './GroupSectionOrdering';

interface GroupOption {
    id: ItemGroup['type'];
    label: string;
}

const DefaultGroupTypeArray = Array.from([
    ItemGroupPredefinedType.None,
    ItemGroupPredefinedType.Assignee,
    ItemGroupPredefinedType.Parent,
    ItemGroupPredefinedType.Status,
    ItemGroupPredefinedType.People,
    ItemGroupPredefinedType.StatusLifecycle,
]);

export const DEFAULT_GROUP_SETTINGS: GroupSettingsInput = {
    groupType: ItemGroupPredefinedType.None,
    subgroupOrdering: undefined,
};

function getLabelForItemGroupType(groupType: ItemGroup['type']): string {
    switch (groupType) {
        case ItemGroupPredefinedType.Assignee:
            return 'Assignee';
        case ItemGroupPredefinedType.Parent:
            return 'Parent';
        case ItemGroupPredefinedType.Status:
            return 'Status';
        case ItemGroupPredefinedType.People:
            return 'People';
        case ItemGroupPredefinedType.None:
            return 'None';
        case ItemGroupPredefinedType.StatusLifecycle:
            return 'Status Lifecycle';
        default:
            return groupType;
    }
}

function getDefaultGroupTypesOptions(): GroupOption[] {
    return DefaultGroupTypeArray.map((groupType) => ({
        id: groupType,
        label: getLabelForItemGroupType(groupType),
    }));
}

export function GroupToolbarButton(props: {
    group: GroupSettingsInput;
    onChange: (group: ItemGroup['type']) => void;
    timelineLoading: boolean;
    subgroupNames?: string[];
    onOrderingChange: (newOrder: string[] | undefined) => void;
    disabled: boolean;
    timelineId: string | undefined;
    v2?: boolean;
}): JSX.Element {
    const { logger } = useTelemetryContext();
    const { postErrorMessage } = useUserStateContext();
    const [buttonActive, setButtonActive] = useState(false);
    const [groupOptions, setGroupOptions] = useState<GroupOption[]>(getDefaultGroupTypesOptions());

    const { refs, floatingStyles, context } = useFloating({
        whileElementsMounted: autoUpdate,
        middleware: [
            autoPlacement({
                allowedPlacements: ['top-start', 'bottom-start'],
            }),
        ],
        open: buttonActive,
        onOpenChange: setButtonActive,
    });

    const dismiss = useDismiss(context);
    const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

    const subgroupKey = useMemo(() => props.subgroupNames?.join('') || 'none', [props.subgroupNames]);
    const selectedOption = useMemo(
        () => ({
            id: props.group.groupType,
            label: getLabelForItemGroupType(props.group.groupType),
        }),
        [props.group.groupType],
    );
    const { loading } = useQuery(GET_TIMELINE_PROPERTIES, {
        variables: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            timelineId: props.timelineId!,
        },
        skip: !props.timelineId,
        onError: (error) => {
            postErrorMessage({ title: 'Error', shortDesc: 'Failed to get timeline properties' });
            logger.error(error.message);
        },
        onCompleted: (data) => {
            // Get the default group options
            const newGroupOptions = getDefaultGroupTypesOptions();

            // Update the default group options with the received options
            data.timelineProperties.customPropertyNames.forEach((property) => {
                newGroupOptions.push({
                    id: property,
                    label: getLabelForItemGroupType(property),
                });
            });

            // Set the group options
            setGroupOptions(newGroupOptions);
        },
    });

    return (
        <Box ref={refs.setReference} {...getReferenceProps()}>
            <ToolbarButton
                active={buttonActive || props.group.groupType !== ItemGroupPredefinedType.None}
                icon={Folder}
                label={props.v2 ? 'Grouping' : 'Group By'}
                onClick={async () => {
                    setButtonActive(!buttonActive);
                }}
                disabled={props.timelineLoading || props.disabled || loading}
                v2={props.v2}
            />
            {buttonActive && (
                <div ref={refs.setFloating} style={{ ...floatingStyles }} {...getFloatingProps()}>
                    <Box
                        overflow={{ vertical: 'auto' }}
                        background={{ color: Colors.background_front }}
                        border={{ color: Colors.border_dark, size: '1px' }}
                        round="5px"
                        margin={{ vertical: 'xxsmall', bottom: 'xsmall' }}
                    >
                        <DropdownMenuChecklist
                            options={groupOptions}
                            selected={[selectedOption]}
                            singleSelectionOnly={true}
                            onSelectionChange={(selected) => props.onChange(selected[0].id)}
                            onCloseMenu={() => setButtonActive(false)}
                        />
                        <GroupSectionOrdering
                            subgroupNames={props.subgroupNames}
                            key={subgroupKey}
                            group={props.group}
                            onOrderingChange={(newOrder) => {
                                props.onOrderingChange(newOrder);
                                setButtonActive(false);
                            }}
                        />
                    </Box>
                </div>
            )}
        </Box>
    );
}
