// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box } from 'grommet';
import styled from 'styled-components';

export const ONBOARD_BANNER_HEADING_FONT_SIZE = '46px';
export const ONBOARD_BG_COLOR = '#FFD400';
const BORDER_RADIUS = '10px';

const BackgroundSvgWrapperRepeat = styled.div<{ bg: string; borderRadius: string }>`
    background-image: url(${(props) => props.bg});
    background-repeat: repeat;
    background-size: 18% auto;
    background-position: -1px -10px; /* Offset the pattern */
    display: flex;
    border-radius: ${(props) => props.borderRadius};
    overflow: hidden;
    height: 100%;
    width: 100%;
`;

export function OnboardBanner(props: { children: React.ReactNode }) {
    return (
        <Box
            background={{ color: ONBOARD_BG_COLOR }}
            height={{ min: 'max-content' }}
            round={BORDER_RADIUS}
            color={Colors.black}
        >
            <BackgroundSvgWrapperRepeat
                bg={`${import.meta.env.VITE_CDN_URL}/images/tw_onboard_bg_pattern.svg`}
                borderRadius={BORDER_RADIUS}
                style={{ alignContent: 'center', justifyContent: 'center', height: 'auto' }}
            >
                <Box pad={{ horizontal: 'large', vertical: 'small' }}>
                    <Box
                        pad={{ horizontal: 'medium', vertical: 'small' }}
                        align="center"
                        justify="center"
                        background={{ color: '#ffffff' }}
                        round={BORDER_RADIUS}
                        width={{ max: 'large' }}
                        gap="xsmall"
                    >
                        {props.children}
                    </Box>
                </Box>
            </BackgroundSvgWrapperRepeat>
        </Box>
    );
}
