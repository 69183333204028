// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box, BoxProps, Button, Spinner, Text, TextExtendedProps } from 'grommet';
import { Icon, IconProps } from 'grommet-icons';
import { useState } from 'react';
import { FontFamily } from '../../theme';

export function ToolbarButton(props: {
    onClick: () => Promise<void>;
    disabled?: boolean;
    icon?: Icon;
    iconSize?: IconProps['size'];
    label?: string;
    active: boolean;
    loading?: boolean;
    boxProps?: BoxProps;
    textProps?: TextExtendedProps;
    v2?: boolean;
}): JSX.Element {
    const [submitting, setSubmitting] = useState(false);

    return (
        <Button
            onClick={() => {
                if (props.disabled) {
                    return;
                }
                setSubmitting(true);
                props
                    .onClick()
                    .then(() => setSubmitting(false))
                    .catch(() => setSubmitting(false));
            }}
            plain
            disabled={props.disabled}
        >
            {({ hover }) => (
                <Box
                    direction="row"
                    align="center"
                    gap="xxsmall"
                    round={'4px'}
                    pad={{ vertical: '4px', horizontal: props.v2 ? '4px' : undefined }}
                    background={hover ? Colors.light_3 : props.v2 ? Colors.background_back : undefined}
                    {...props.boxProps}
                >
                    {!submitting && !props.loading && props.icon && (
                        <props.icon
                            size={props.iconSize ?? '18px'}
                            color={props.active ? Colors.accent_3 : Colors.dark_2}
                        />
                    )}
                    {(submitting || props.loading) && <Spinner size="1px" width="18px" height="18px" />}
                    {props.label && (
                        <Text
                            size="14px"
                            truncate="tip"
                            color={props.active ? Colors.accent_3 : Colors.dark_2}
                            weight="normal"
                            style={{ fontFamily: FontFamily.Heading }}
                            {...props.textProps}
                        >
                            {props.label}
                        </Text>
                    )}
                </Box>
            )}
        </Button>
    );
}
