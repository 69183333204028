// Copyright 2021
// ThatWorks.xyz Limited

import { autoPlacement, autoUpdate, useFloating } from '@floating-ui/react';
import { Colors } from '@thatworks/colors';
import { InlineInsightPillNodeDataBase } from '@thatworks/shared-frontend/prosemirror';
import {
    InlineInsightPillAttributes,
    InlineInsightPillNode,
    MAX_INLINE_INSIGHT_PILLS_TO_SHOW,
} from '@thatworks/shared-frontend/prosemirror-nodes';
import { Box, Image, Text } from 'grommet';
import { Down } from 'grommet-icons';
import { FunctionComponent, useMemo, useState } from 'react';
import { ConnectorIconSmall } from '../../../../../../components/ConnectorIcon';
import { SimpleBorderTextButton } from '../../../../../../components/FilterDropdown';
import {
    BaseReactNodeComponentProps,
    BaseReactNodeInjected,
} from '../../../../../../components/prosemirror/BaseReactNode';
import { FontFamily } from '../../../../../../theme';
import { TaskOverview, TaskPreviewInfo } from './TaskPreviewNode';

function addOpacityToHex(hex: string, opacityPc: number): string {
    const opacity = opacityPc / 100;
    const _opacity = Math.round(opacity * 255);
    return hex + _opacity.toString(16).toUpperCase();
}

const MAX_PILL_WIDTH = '200px';
const PILL_MARGIN_BOTTOM = 4;
const PILL_HEIGHT = 30;

function InlineInsightPill(props: { data: InlineInsightPillNodeDataBase<TaskPreviewInfo> }): JSX.Element {
    const [modal, setModal] = useState(false);

    const { refs, floatingStyles } = useFloating({
        whileElementsMounted: (ref, floating, update) => {
            return autoUpdate(ref, floating, update);
        },
        middleware: [
            autoPlacement({
                allowedPlacements: ['top-start', 'bottom-start'],
            }),
        ],
        onOpenChange: setModal,
        open: modal,
    });

    return (
        <Box
            ref={refs.setReference}
            onMouseEnter={() => setModal(true)}
            onMouseLeave={() => setModal(false)}
            style={{ cursor: 'default' }}
        >
            {props.data && (
                <Box
                    direction="row"
                    align="center"
                    gap="4px"
                    border={{ color: Colors.accent_3, size: '1px' }}
                    round="5px"
                    background={{ color: props.data.color ? addOpacityToHex(props.data.color, 20) : Colors.light_6 }}
                    width={{ max: MAX_PILL_WIDTH, min: 'max-content', width: 'max-content' }}
                    height={{ height: `${PILL_HEIGHT}px` }}
                    margin={{ bottom: `${PILL_MARGIN_BOTTOM}px` }}
                >
                    <Box pad={{ left: '6px', right: '4px' }} direction="row" align="center" gap="4px">
                        {props.data.iconUrl && (
                            <Image
                                src={props.data.iconUrl}
                                width={'16px'}
                                height={'16px'}
                                alt="icon"
                                style={{ alignSelf: 'flex-start', marginTop: '2px' }}
                                fallback={import.meta.env.VITE_ITEM_ICON_FALLBACK_URL}
                            />
                        )}
                        {!props.data.iconUrl && props.data.connector && (
                            <ConnectorIconSmall
                                name={props.data.connector}
                                sizePixels="14px"
                                style={{ alignSelf: 'flex-start', marginTop: '2px' }}
                            />
                        )}
                        <Text
                            size="14px"
                            truncate
                            style={{
                                maxWidth: `calc(${MAX_PILL_WIDTH} - 24px)`,
                            }}
                        >
                            {props.data.value}
                        </Text>
                    </Box>
                    <Box
                        border={{ side: 'left', color: Colors.accent_3, size: '1px' }}
                        justify="center"
                        style={{ width: '26px' }}
                        direction="row"
                        align="center"
                        fill="vertical"
                    >
                        <Down size="12px" />
                    </Box>
                </Box>
            )}

            {modal && props.data && props.data.items.length > 0 && (
                <div ref={refs.setFloating} style={{ ...floatingStyles }}>
                    <Box
                        overflow={{ vertical: 'auto' }}
                        pad="xxsmall"
                        background={{ color: Colors.background_front }}
                        border={{ color: Colors.border_light }}
                        elevation="medium"
                        round="4px"
                        width={{ width: '500px', max: '500px' }}
                        height={{ max: '50vh' }}
                        margin={{ bottom: 'xsmall' }}
                    >
                        <Box height={{ min: 'max-content' }} gap="xxsmall">
                            <Box pad="xxsmall">
                                <Text size="14px" style={{ fontFamily: FontFamily.Heading }} weight="bold">
                                    {props.data.value}
                                </Text>
                            </Box>
                            <Box gap="xxsmall">
                                {props.data.items.map((item, i) => (
                                    <TaskOverview
                                        key={`overview-${i}`}
                                        item={item}
                                        cardColor={{ color: Colors.background_back, opacity: 1 }}
                                        borderColor={Colors.border_light}
                                    />
                                ))}
                            </Box>
                        </Box>
                    </Box>
                </div>
            )}
        </Box>
    );
}

export function InlineInsightPillOverflow(props: {
    data: InlineInsightPillNodeDataBase<TaskPreviewInfo>[];
}): JSX.Element {
    const [showMore, setShowMore] = useState(false);

    const array = useMemo(() => {
        return showMore ? props.data : props.data.slice(0, MAX_INLINE_INSIGHT_PILLS_TO_SHOW);
    }, [props.data, showMore]);

    return (
        <Box>
            <Box direction="row" gap="xxsmall" wrap align="center">
                {array.map((d, di) => (
                    <InlineInsightPill data={d} key={`${d.value}-${di}-pill`} />
                ))}
                {props.data.length > MAX_INLINE_INSIGHT_PILLS_TO_SHOW && (
                    <SimpleBorderTextButton
                        onClick={() => setShowMore(!showMore)}
                        boxProps={{
                            border: { color: Colors.border_dark },
                            align: 'center',
                            round: '5px',
                            height: `${PILL_HEIGHT}px`,
                            margin: { bottom: `${PILL_MARGIN_BOTTOM}px` },
                            pad: { horizontal: '6px' },
                        }}
                        render={(hover) => (
                            <Text color={hover ? Colors.accent_3 : undefined} size="14px">
                                {showMore
                                    ? `Show less`
                                    : `Show ${props.data.length - MAX_INLINE_INSIGHT_PILLS_TO_SHOW} more`}
                            </Text>
                        )}
                    />
                )}
            </Box>
        </Box>
    );
}

export class InlineInsightPillNodeReact extends BaseReactNodeInjected<InlineInsightPillAttributes<TaskPreviewInfo>> {
    _injectedNode = new InlineInsightPillNode<TaskPreviewInfo>();

    ComponentToRender: FunctionComponent<BaseReactNodeComponentProps<InlineInsightPillAttributes<TaskPreviewInfo>>> = (
        props,
    ) => {
        if (props.currentAttributes.data === undefined) {
            return null;
        }

        return Array.isArray(props.currentAttributes.data) ? (
            <InlineInsightPillOverflow data={props.currentAttributes.data} />
        ) : (
            <InlineInsightPill data={props.currentAttributes.data} />
        );
    };
}
