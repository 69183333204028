// Copyright 2021
// ThatWorks.xyz Limited

import { useStatsigClient } from '@statsig/react-bindings';
import { DynamicConfigs } from '@thatworks/shared-frontend/metrics';
import { joinPagesPaths, Pages } from '@thatworks/shared-frontend/pages';
import { Anchor, Box, Button, Spinner, Text } from 'grommet';
import { StatusWarning } from 'grommet-icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ConnectorPermissionStatus, UserConnectorDataStatus } from '../../../../__generated__/graphql';
import { useAuth } from '../../../../components/AuthProvider';
import { BasicPage3, BasicPage3NavFunction, DefaultPagePaddingPixels } from '../../../../components/BasicPage3';
import { ConnectionButtonsGrid } from '../../../../components/ConnectionComponents';
import { ONBOARD_BANNER_HEADING_FONT_SIZE, OnboardBanner } from '../../../../components/OnboardBanner';
import { OnboardProgressIndicator } from '../../../../components/OnboardProgressIndicator';
import { SidebarNav } from '../../../../components/SidebarNav';
import { useUserStateContext } from '../../../../components/UserContext';
import { withAuth } from '../../../../components/withAuth';
import { useNavNoRerender } from '../../../../shared/UseNavNoRerender';
import { FontFamily } from '../../../../theme';

enum ConnectorProcessingState {
    AppsSyncing,
    AppSyncingDone,
    // Home page cache disabled until we come up with a more performant way to do it
    // HomePageProcessing,
    Done,
    ErrorNoApps,
    ErrorConnectionCantContinue,
}

function ConnectorProcessing(props: { onNav: BasicPage3NavFunction }): JSX.Element {
    const { getUserInfo } = useAuth();
    const { userState, patchUserMetadata, pollState } = useUserStateContext();
    const [processingState, setProcessingState] = useState<ConnectorProcessingState>(
        ConnectorProcessingState.AppsSyncing,
    );
    const navigate = useNavNoRerender();
    const { getDynamicConfig } = useStatsigClient();
    const [userInfo, setUserInfo] = useState<{ firstName: string; lastName: string; email: string } | undefined>();

    const onComplete = useCallback(async () => {
        setProcessingState(ConnectorProcessingState.Done);
        await patchUserMetadata({ signupNux: false });
        navigate(joinPagesPaths([Pages.app.root, Pages.app.subs.create]));
    }, [navigate, patchUserMetadata]);

    useEffect(() => {
        getUserInfo()
            .then((u) => {
                if (u.emailAddress && u.firstName && u.lastName) {
                    setUserInfo({ firstName: u.firstName, lastName: u.lastName, email: u.emailAddress });
                }
            })
            .catch(() => {
                // no op
            });
    }, [getUserInfo]);

    useEffect(() => {
        pollState(true);
        return () => {
            pollState(false);
        };
    }, [pollState]);

    useEffect(() => {
        if (!userState) {
            return;
        }
        // If no apps are connected, we can't continue
        if (userState.connectedConnectors.length === 0) {
            setProcessingState(ConnectorProcessingState.ErrorNoApps);
            return;
        }
        // If any apps are still syncing, we can't continue
        const processing = userState.connectedConnectors.filter(
            (c) =>
                (c.status.data === UserConnectorDataStatus.Processing ||
                    (c.firstPollProgress != null && c.firstPollProgress < 0.1)) &&
                c.isConnectedByUser,
        );
        if (processing.length > 0) {
            setProcessingState(ConnectorProcessingState.AppsSyncing);
            return;
        }
        // If all apps have an error, we can't continue
        const withError = userState.connectedConnectors.filter(
            (c) =>
                (c.status.data === UserConnectorDataStatus.Fail ||
                    c.status.permissions !== ConnectorPermissionStatus.Ok) &&
                c.isConnectedByUser,
        );
        if (withError.length === userState.connectedConnectors.length) {
            setProcessingState(ConnectorProcessingState.ErrorConnectionCantContinue);
            return;
        }
        // If all apps are done and ok, but some have errors, then we can continue
        const doneAndOk = userState.connectedConnectors.filter(
            (c) =>
                (c.status.data === UserConnectorDataStatus.Ok ||
                    (c.firstPollProgress != null && c.firstPollProgress > 0.1)) &&
                c.status.permissions === ConnectorPermissionStatus.Ok &&
                c.isConnectedByUser,
        );
        if (doneAndOk.length + withError.length === userState.connectedConnectors.length) {
            setProcessingState(ConnectorProcessingState.AppSyncingDone);
        }
    }, [userState]);

    useEffect(() => {
        if (processingState === ConnectorProcessingState.AppSyncingDone) {
            onComplete();
        }
    }, [onComplete, processingState]);

    const cardTitle = useMemo(() => {
        const iconSize = '28px';
        switch (processingState) {
            case ConnectorProcessingState.AppsSyncing:
            case ConnectorProcessingState.AppSyncingDone:
                return (
                    <Box direction="row" gap="xsmall" align="center">
                        <Spinner width={iconSize} height={iconSize} />
                        <Text style={{ fontFamily: FontFamily.Callout }} size={ONBOARD_BANNER_HEADING_FONT_SIZE}>
                            Connecting your apps
                        </Text>
                    </Box>
                );
            // case ConnectorProcessingState.HomePageProcessing:
            case ConnectorProcessingState.Done:
                return (
                    <Box direction="row" gap="xsmall" align="center">
                        <Spinner width={iconSize} height={iconSize} />
                        <Text style={{ fontFamily: FontFamily.Callout }} size={ONBOARD_BANNER_HEADING_FONT_SIZE}>
                            Processing your data
                        </Text>
                    </Box>
                );
            case ConnectorProcessingState.ErrorNoApps:
                return (
                    <Box direction="row" gap="xsmall" align="center">
                        <StatusWarning size={iconSize} />
                        <Text style={{ fontFamily: FontFamily.Callout }} size={ONBOARD_BANNER_HEADING_FONT_SIZE}>
                            No apps connected
                        </Text>
                    </Box>
                );
            case ConnectorProcessingState.ErrorConnectionCantContinue:
                return (
                    <Box direction="row" gap="xsmall" align="center">
                        <StatusWarning size={iconSize} />
                        <Text style={{ fontFamily: FontFamily.Callout }} size={ONBOARD_BANNER_HEADING_FONT_SIZE}>
                            Error
                        </Text>
                    </Box>
                );
        }
    }, [processingState]);

    const cardBody = useMemo(() => {
        switch (processingState) {
            case ConnectorProcessingState.AppsSyncing:
            case ConnectorProcessingState.AppSyncingDone:
            case ConnectorProcessingState.Done:
                return (
                    <Text textAlign="center">
                        We are setting up your account and crunching data. This usually takes a couple of minutes.
                    </Text>
                );
            case ConnectorProcessingState.ErrorNoApps:
                return <Text textAlign="center">You need to connect your apps to finish setting up your account.</Text>;
            case ConnectorProcessingState.ErrorConnectionCantContinue: {
                const supportCallUrl = getDynamicConfig(DynamicConfigs.FrontendUrls).get<string>('bookSupportCall');
                let supportCallUrlWithUserDetails = supportCallUrl;
                if (userInfo && supportCallUrl) {
                    supportCallUrlWithUserDetails += `?email=${userInfo.email}&firstName=${userInfo.firstName}&lastName=${userInfo.lastName}`;
                }
                return (
                    <Text textAlign="center">
                        Sorry, we have run into an error processing your data.{' '}
                        <Anchor href={import.meta.env.VITE_SUPPORT_HREF}>Get in touch</Anchor> with support{' '}
                        {supportCallUrlWithUserDetails ? (
                            <>
                                or{' '}
                                <Anchor target="_blank" href={supportCallUrlWithUserDetails}>
                                    book a call
                                </Anchor>{' '}
                            </>
                        ) : null}
                        and we will sort it out!
                    </Text>
                );
            }
        }
    }, [getDynamicConfig, processingState, userInfo]);

    return (
        <BasicPage3
            activeNavButton={SidebarNav.Connect}
            browserPageTitle={'Setting Up Your Account'}
            onNav={props.onNav}
            onboarding={true}
        >
            <Box overflow={{ vertical: 'auto' }} height="100%" width="100%">
                <Box height={{ min: 'max-content' }}>
                    <Box
                        pad={{
                            left: DefaultPagePaddingPixels,
                            right: DefaultPagePaddingPixels,
                            top: 'small',
                            bottom: 'small',
                        }}
                        gap="small"
                    >
                        <OnboardBanner>
                            <OnboardProgressIndicator step={'connect'} />
                            {cardTitle}
                            {cardBody}
                            {processingState === ConnectorProcessingState.ErrorNoApps && (
                                <Button label="Connect Now" onClick={() => navigate('../')} primary />
                            )}
                        </OnboardBanner>
                        {userState && (
                            <ConnectionButtonsGrid
                                hideAlreadyConnectedTitle
                                availableConnectors={[]}
                                connectedConnectors={userState.connectedConnectors}
                                connectedOrgWideConnectors={[]}
                                disableOptionsForConnected
                                // All no-ops since disableOptionsForConnected is true
                                onConnect={() => {
                                    // no op
                                }}
                                onDisconnect={async () => {
                                    return false;
                                }}
                                onReconnect={() => {
                                    return false;
                                }}
                                onError={() => {
                                    // no op
                                }}
                            />
                        )}
                    </Box>
                </Box>
            </Box>
        </BasicPage3>
    );
}

export default withAuth(ConnectorProcessing);
