// Copyright 2021
// ThatWorks.xyz Limited

export enum ParamKey {
    WorkspaceUuid = 'workspaceUuid',

    // These are directly references in PublicLinkOutlet.tsx
    // so remember to update that file if you change these!
    PublicPostUuid = 'publicPostUuid',
    SlackTokenId = 'slackTokenId',
}
