// Copyright 2021
// ThatWorks.xyz Limited

import { DOMSerializer, Fragment, Node } from '@remirror/pm/model';
import { Remirror, useRemirror } from '@remirror/react';
import { PmNodeNames } from '@thatworks/shared-frontend/prosemirror';
import { BasePmNodeAttributes } from '@thatworks/shared-frontend/prosemirror-nodes';
import { useCallback, useEffect } from 'react';
import {
    CommandFunction,
    CommandsExtension,
    EditorState,
    findChildren,
    InvalidContentHandler,
    Transaction,
} from 'remirror';
import { DocExtension, MarkdownExtension } from 'remirror/extensions';
import Turndown from 'turndown';
import { useTelemetryContext } from '../../../../../components/TelemetryContext';
import { ChartNodeReact } from '../../ws/components/pm-nodes/ChartNode';
import { GroupedInsightPillNodeReact } from '../../ws/components/pm-nodes/GroupedInsightNode';
import { InlineInsightPillNodeReact } from '../../ws/components/pm-nodes/InlineInsightPillNode';
import { InsightPillNodeReact } from '../../ws/components/pm-nodes/InsightNode';
import { MetricBoxNodeReact } from '../../ws/components/pm-nodes/MetricBoxNode';
import { TaskPreviewNode } from '../../ws/components/pm-nodes/TaskPreviewNode';
import { getDefaultProseMirrorExtensions, ProseWrapper } from '../../ws/ProseWrapper';
import { FrontendSummarizedActivityNodeParser } from '../filters/activity-node-parser';
import { ComposerStateManager } from './ComposerStateManagerContext';
import { QueryBlockNodeReact } from './PmQueryBlockNode';
import { TextBlockAttributes, TextBlockNodeReact } from './PmTextBlockNode';
import { BlockType } from './TemplateBlock';

export function ProsemirrorComposer(props: {
    stateManager: ComposerStateManager;
    setPreviewDocContent: (content: string) => void;
}): JSX.Element {
    const { logger } = useTelemetryContext();

    // Remirror setup
    const onError: InvalidContentHandler = useCallback(({ json, invalidContent, transformers }) => {
        // Automatically remove all invalid nodes and marks.
        return transformers.remove(json, invalidContent);
    }, []);

    const blocks = props.stateManager.blocks;

    const { manager, state, getContext } = useRemirror({
        extensions: () => [
            // Remove the trailing node extension because we dont have top level text blocks
            ...getDefaultProseMirrorExtensions(['trailingNode']),

            // Custom extensions
            new MetricBoxNodeReact({ disableExtraAttributes: true }),
            new ChartNodeReact({ disableExtraAttributes: true }),
            new InsightPillNodeReact({ disableExtraAttributes: true }),
            new GroupedInsightPillNodeReact({ disableExtraAttributes: true }),
            new InlineInsightPillNodeReact({ disableExtraAttributes: true }),
            new TaskPreviewNode({ disableExtraAttributes: true }),
            new CommandsExtension({}),
            new MarkdownExtension({}),
            new QueryBlockNodeReact({ disableExtraAttributes: true }),
            new TextBlockNodeReact({ disableExtraAttributes: true }),
            new DocExtension({
                content: `(${PmNodeNames.QueryBlock}|${PmNodeNames.TextBlock})*`,
            }),
        ],
        onError,
    });

    // Synchronize the ProseMirror document hierarchy with the React state
    const synchronizeDocHierarchyWithReactState = useCallback(() => {
        const remirrorContext = getContext();
        if (!remirrorContext) {
            // still loading
            return;
        }

        const commands = remirrorContext.commands;
        const cmd: CommandFunction = (cmdArgs) => {
            if (!cmdArgs.dispatch) {
                return false;
            }

            const doc = cmdArgs.state.doc;

            // Find all text and query blocks in the document
            const blocksInDoc = new Map<string, { node: Node; pos: number }>();
            const nodes = findChildren({
                node: doc,
                predicate: (v) =>
                    v.node.type.name === PmNodeNames.TextBlock || v.node.type.name === PmNodeNames.QueryBlock,
            });
            nodes.forEach((n) => {
                const attrs = n.node.attrs as BasePmNodeAttributes;
                // we check for valid uuids because we could have uninitialized blocks
                // while prosemirror is updating the document
                if (attrs.uuid && attrs.uuid.length > 0) {
                    blocksInDoc.set(attrs.uuid, { node: n.node, pos: n.pos });
                }
            });

            let tr = cmdArgs.tr;

            // Check if the number of blocks in the document matches the number of blocks in the React state
            // or if the block ids don't match
            const blockIdsMatch = blocks.every((block) => blocksInDoc.has(block.id));
            if (!blockIdsMatch || blocksInDoc.size !== blocks.length) {
                // number of blocks don't match, so a node has been added or removed

                // Find any deleted blocks and remove them
                const deletedBlockIds = Array.from(blocksInDoc.keys()).filter(
                    (blockId) => !blocks.find((block) => block.id === blockId),
                );
                deletedBlockIds.forEach((blockId) => {
                    const node = blocksInDoc.get(blockId);
                    if (node) {
                        const mappedPos = tr.mapping.map(node.pos);
                        tr = tr.delete(mappedPos, mappedPos + node.node.nodeSize);
                    }
                });

                // Find any new blocks and add them
                const newBlockIds = blocks.filter((block) => !blocksInDoc.has(block.id));
                newBlockIds.forEach((block) => {
                    const nodeAttrs: BasePmNodeAttributes = {
                        uuid: block.id,
                    };

                    let nodeToInsert: Node | undefined;
                    if (block.type === BlockType.Text) {
                        let textContentFragment = Fragment.empty;
                        textContentFragment = textContentFragment.addToEnd(
                            cmdArgs.state.schema.nodes.paragraph.createChecked(),
                        );

                        if (block.text && block.text.length > 0) {
                            // The text is stored as markdown, so convert it to pm nodes
                            const parser = new FrontendSummarizedActivityNodeParser();
                            textContentFragment = parser.toProseMirrorNodes(
                                undefined,
                                {
                                    type: 'markdown',
                                    text: block.text,
                                    // by default we parse as a document, but we want to parse as a slice
                                    // so that we can insert it as a child of the text node
                                    parseAsSlice: true,
                                },
                                cmdArgs.state.schema,
                                (nodeName, message) => {
                                    logger.error(
                                        `synchronizeDocHierarchyWithReactState error parsing markdown block for ${nodeName}: ${message}`,
                                    );
                                },
                            );
                        }

                        // Don't use createChecked so that unsupported content can be ignored
                        // (e.g. markdown can have empty top-level text nodes for spaces)
                        nodeToInsert = cmdArgs.state.schema.nodes[PmNodeNames.TextBlock].create(
                            nodeAttrs,
                            textContentFragment,
                        );
                    } else {
                        nodeToInsert = cmdArgs.state.schema.nodes[PmNodeNames.QueryBlock].createChecked(nodeAttrs);
                    }
                    if (!nodeToInsert) {
                        return;
                    }
                    tr = tr.insert(tr.mapping.map(doc.content.size), nodeToInsert);
                });
            } else {
                // number of blocks match, so we need to check if the order has changed
                const blockIdsInDoc = Array.from(blocksInDoc.keys());
                let orderChanged = false;
                for (let i = 0; i < blockIdsInDoc.length; i++) {
                    if (blockIdsInDoc[i] !== blocks[i].id) {
                        orderChanged = true;
                        break;
                    }
                }

                if (!orderChanged) {
                    return false;
                }

                // Reorder the blocks
                let lastPos = 0;
                for (let i = 0; i < blocks.length; i++) {
                    const blockId = blocks[i].id;
                    const node = blocksInDoc.get(blockId);
                    if (node) {
                        // Use `mapping` because it will have the correct positions after deletions/insertions
                        const mappedPos = tr.mapping.map(node.pos);
                        if (mappedPos !== lastPos) {
                            // Prosemirror doesn't support moving nodes, so we have to delete and reinsert
                            tr = tr.delete(mappedPos, mappedPos + node.node.nodeSize);
                            tr = tr.insert(lastPos, node.node);
                        }
                        lastPos += node.node.nodeSize;
                    }
                }
            }

            // Dispatch the transactions
            cmdArgs.dispatch(tr);
            return true;
        };

        commands.customDispatch(cmd);
    }, [getContext, blocks, logger]);

    // Synchronize the React state with the text nodes in the ProseMirror document
    const synchronizeTextNodesWithReactState = useCallback(
        (transactions: readonly Transaction[], newState: Readonly<EditorState>) => {
            if (!transactions.some((tr) => tr.docChanged)) {
                return;
            }

            // Find all text blocks in the document
            const nodesToCheck: { node: Node; pos: number }[] = [];
            newState.doc.descendants((node, pos) => {
                if (node.type.name === PmNodeNames.TextBlock && (node.attrs as TextBlockAttributes)?.uuid) {
                    nodesToCheck.push({ node, pos });
                }
            });

            // Check each node to see if it was affected by the transactions
            nodesToCheck.forEach(({ node, pos }) => {
                const nodeStart = pos + 1;
                const nodeEnd = pos + node.nodeSize - 1;

                let affected = false;
                for (const tr of transactions) {
                    tr.steps.forEach((step) => {
                        if (step.getMap) {
                            step.getMap().forEach((oldStart, oldEnd, newStart, newEnd) => {
                                if (
                                    (oldStart <= nodeEnd && oldEnd >= nodeStart) ||
                                    (newStart <= nodeEnd && newEnd >= nodeStart)
                                ) {
                                    affected = true;
                                }
                            });
                        }
                    });
                }

                // Update the React state with the new text content
                const attrs = node.attrs as TextBlockAttributes;
                if (affected && attrs.uuid) {
                    const found = blocks.find((b) => b.id === attrs.uuid);
                    if (!found || found.type !== BlockType.Text) {
                        return;
                    }

                    let text: undefined | string;
                    const content = newState.doc.slice(nodeStart, nodeEnd).content;
                    if (content) {
                        // Convert the ProseMirror content to markdown
                        const serializer = DOMSerializer.fromSchema(newState.schema);
                        const html = serializer.serializeFragment(content);
                        const turndown = new Turndown();
                        const markdown = turndown.turndown(html);
                        text = markdown;
                    }

                    props.stateManager.onUpdateBlock({
                        ...found,
                        text,
                    });
                }
            });
        },
        [blocks, props.stateManager],
    );

    useEffect(() => {
        // Synchronize the document hierarchy with the React state whenever the blocks change
        synchronizeDocHierarchyWithReactState();
    }, [blocks, synchronizeDocHierarchyWithReactState]);

    return (
        <ProseWrapper>
            <Remirror
                manager={manager}
                initialContent={state}
                onChange={(params) => {
                    // Synchronize the React state with the text nodes in the ProseMirror document
                    // whenever the document changes
                    synchronizeTextNodesWithReactState(params.transactions || [], params.state);

                    // Used for creating published posts
                    props.setPreviewDocContent(JSON.stringify(params.state.doc.toJSON()));
                }}
            />
        </ProseWrapper>
    );
}
