// Copyright 2021
// ThatWorks.xyz Limited

import { autoPlacement, autoUpdate, useDismiss, useFloating, useInteractions } from '@floating-ui/react';
import { Colors } from '@thatworks/colors';
import { Box } from 'grommet';
import { useCallback, useMemo, useState } from 'react';
import {
    ConnectedAppsOption,
    ConnectorScopeInput,
    ConnectorWideScopeInput,
} from '../../../../../__generated__/graphql';
import { CtaButtonSpinnerV2 } from '../../../../../components/CtaButtonSpinner';
import { ToolbarButton } from '../../../../../components/prosemirror/ToolbarButton';
import { GitFork } from '../../../../../icons/GitFork';
import { filterConnectorScopes, SearchItems, wideScopeLabel } from './SearchItems';

export function SearchItemsToolbarButton(props: {
    scopes: ConnectorScopeInput[];
    wideScopes: ConnectorWideScopeInput[];
    onScopesChange: (selectedScopes: ConnectorScopeInput[], selectedWideScopes: ConnectorWideScopeInput[]) => void;
    appsConnectedOptions: ConnectedAppsOption[];
    appsConnectedOptionsLoading: boolean;
    v2?: boolean;
}): JSX.Element {
    const [buttonActive, setButtonActive] = useState(false);

    const [selectedScopes, setSelectedScopes] = useState<Map<string, ConnectorScopeInput>>(() => {
        if (props.scopes) {
            const results: Map<string, ConnectorScopeInput> = new Map();
            props.scopes.forEach((scope) => {
                results.set(scope.id, scope);
            });
            return results;
        }
        return new Map();
    });
    const [selectedWideScopes, setSelectedWideScopes] = useState<Map<string, ConnectorWideScopeInput>>(() => {
        const groupedConnectors: Map<string, Set<string>> = new Map();
        if (props.wideScopes) {
            props.wideScopes.forEach((wideScope) => {
                // If the wide scope already exists in the selected wide scopes, add the object types to it
                const existingWideScope = groupedConnectors.get(wideScope.connector);
                if (existingWideScope && wideScope.objectTypes) {
                    wideScope.objectTypes.forEach((objectType) => existingWideScope.add(objectType));
                }
                // Otherwise, initialize the wide scope
                else {
                    groupedConnectors.set(wideScope.connector, new Set(wideScope.objectTypes));
                }
            });
        }

        // Convert the grouped connectors to wide scope inputs
        const results: Map<string, ConnectorWideScopeInput> = new Map();
        groupedConnectors.forEach((objectTypes, connector) => {
            results.set(connector, {
                connector,
                objectTypes: Array.from(objectTypes),
            });
        });
        return results;
    });

    const { refs, floatingStyles, context } = useFloating({
        whileElementsMounted: autoUpdate,
        middleware: [
            autoPlacement({
                allowedPlacements: ['top-start', 'bottom-start'],
            }),
        ],
        open: buttonActive,
        onOpenChange: (open) => {
            setButtonActive(open);
            if (!open) {
                props.onScopesChange(Array.from(selectedScopes.values()), Array.from(selectedWideScopes.values()));
            }
        },
    });

    const dismiss = useDismiss(context);
    const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

    const buttonLabel = useMemo(() => {
        // Initialize the button label
        let buttonLabel = 'Choose data sources';

        // Get the wide scope labels
        const wideScopeLabels = Array.from(selectedWideScopes.values()).flatMap((v) => {
            if (v.objectTypes == null || v.objectTypes.length === 0) {
                return [];
            }
            return v.objectTypes.map((objectType) => wideScopeLabel(v.connector, objectType));
        });

        // Get the selected values from the scopes filtered by the wide scopes
        const filteredScopes = filterConnectorScopes(Array.from(selectedScopes.values()), selectedWideScopes);

        // Update the button label
        if (wideScopeLabels.length > 0) {
            buttonLabel = wideScopeLabels.join(', ');
        }
        if (filteredScopes.length > 0) {
            const filteredScopesLabel = filteredScopes.map((v) => v.itemName).join(', ');
            if (wideScopeLabels.length > 0) {
                buttonLabel = `${buttonLabel}, ${filteredScopesLabel}`;
            } else {
                buttonLabel = filteredScopesLabel;
            }
        }

        // Return the button label
        return buttonLabel;
    }, [selectedScopes, selectedWideScopes]);

    const closeButton = useCallback(() => {
        setButtonActive(false);
        props.onScopesChange(Array.from(selectedScopes.values()), Array.from(selectedWideScopes.values()));
    }, [props, selectedScopes, selectedWideScopes]);

    const clearScopes = useCallback(async () => {
        setSelectedScopes(new Map());
        setSelectedWideScopes(new Map());
        props.onScopesChange([], []);
    }, [props]);

    return (
        <Box ref={refs.setReference} {...getReferenceProps()}>
            <ToolbarButton
                active={buttonActive || selectedScopes.size > 0 || selectedWideScopes.size > 0}
                icon={GitFork}
                onClick={async () => {
                    setButtonActive(!buttonActive);
                }}
                label={buttonLabel}
                v2={props.v2}
            />
            {buttonActive && (
                <div ref={refs.setFloating} style={{ ...floatingStyles }} {...getFloatingProps()}>
                    <Box
                        height={{ min: 'max-content', max: '70vh' }}
                        width={{ min: '600px', width: '600px', max: '50vw' }}
                        background={{ color: Colors.background_front }}
                        border={{ color: Colors.border_dark, size: '1px' }}
                        pad="20px"
                        round="25px"
                        elevation="xsmall"
                        justify="between"
                        gap="xsmall"
                    >
                        {/* Search Items */}
                        <SearchItems
                            onScopeSelected={(selected) => {
                                setSelectedScopes(new Map(selected));
                            }}
                            scopeSelected={selectedScopes}
                            onWideScopeSelected={(selected) => {
                                const filteredScopes = filterConnectorScopes(
                                    Array.from(selectedScopes.values()),
                                    selected,
                                );
                                setSelectedScopes(new Map(filteredScopes.map((v) => [v.id, v])));
                                setSelectedWideScopes(new Map(selected));
                            }}
                            wideScopesSelected={selectedWideScopes}
                            onClose={closeButton}
                            appsConnectedOptions={props.appsConnectedOptions}
                            appsConnectedOptionsLoading={props.appsConnectedOptionsLoading}
                        />

                        {/* Done and Clear All buttons */}
                        <Box height={{ min: 'max-content' }} direction="row" align="center" gap="xxsmall">
                            <CtaButtonSpinnerV2
                                label="Done"
                                style={{ width: '120px' }}
                                onClick={async () => closeButton()}
                            />
                            <CtaButtonSpinnerV2
                                label="Clear All"
                                style={{ width: '120px' }}
                                notPrimary
                                onClick={clearScopes}
                            />
                        </Box>
                    </Box>
                </div>
            )}
        </Box>
    );
}
