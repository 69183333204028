// Copyright 2021
// ThatWorks.xyz Limited

import { useQuery } from '@apollo/client';
import { autoPlacement, autoUpdate, useDismiss, useFloating, useInteractions } from '@floating-ui/react';
import { Colors } from '@thatworks/colors';
import * as emailValidator from 'email-validator';
import { Anchor, Box, Spinner, Tag, Text, TextInput } from 'grommet';
import { Add } from 'grommet-icons';
import { useCallback, useState } from 'react';
import { IconButtonV2 } from '../../../../../components/IconButton';
import { useTelemetryContext } from '../../../../../components/TelemetryContext';
import { useUserStateContext } from '../../../../../components/UserContext';
import { GET_THIS_USER } from '../../ws/WorkspaceCreate';

function AddEmail(props: {
    onNewEmail: (email: string) => void;
    showUserEmailButton: boolean;
    userEmail?: string;
}): JSX.Element {
    const { postErrorMessage } = useUserStateContext();
    const [state, setState] = useState<'idle' | 'adding'>('idle');
    const [email, setEmail] = useState<string>('');
    const onNewEmail = props.onNewEmail;

    const validateEmailAndNotify = useCallback(
        (email: string) => {
            if (!emailValidator.validate(email)) {
                postErrorMessage({ title: 'Invalid Email', shortDesc: 'Please enter a valid email address' });
                return;
            }

            onNewEmail(email);
            setEmail('');
            setState('idle');
        },
        [onNewEmail, postErrorMessage],
    );

    const setStateToIdleAndMaybeNotify = useCallback(
        (email: string) => {
            if (emailValidator.validate(email)) {
                onNewEmail(email);
            }
            setEmail('');
            setState('idle');
        },
        [onNewEmail],
    );

    const { refs, floatingStyles, context } = useFloating({
        whileElementsMounted: autoUpdate,
        middleware: [
            autoPlacement({
                allowedPlacements: ['right'],
            }),
        ],
        open: state === 'adding',
        onOpenChange: (isOpen) => {
            if (!isOpen) {
                setStateToIdleAndMaybeNotify(email);
            }
        },
    });

    const dismiss = useDismiss(context);
    const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

    return (
        <Box direction="row" gap="xxsmall" align="center">
            <IconButtonV2
                icon={(hover) => (
                    <Add
                        ref={refs.setReference}
                        {...getReferenceProps()}
                        size="12px"
                        color={hover ? Colors.accent_3 : undefined}
                    />
                )}
                label={(hover) => (
                    <Text size="14px" color={hover ? Colors.accent_3 : undefined}>
                        Add Email Address
                    </Text>
                )}
                reverse
                onClick={() => setState('adding')}
            />
            {state === 'adding' && (
                <div ref={refs.setFloating} style={{ ...floatingStyles, zIndex: 1000 }} {...getFloatingProps()}>
                    <Box
                        direction="row"
                        align="center"
                        width={{ min: 'max-content' }}
                        background={{ color: Colors.background_back }}
                        pad={{ horizontal: 'xsmall', vertical: 'xxsmall' }}
                        round="8px"
                    >
                        <TextInput
                            // eslint-disable-next-line jsx-a11y/no-autofocus
                            autoFocus
                            placeholder="Type an email address and press enter"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    validateEmailAndNotify(email);
                                }
                            }}
                            style={{
                                borderRadius: '0px',
                                backgroundColor: 'unset',
                                borderWidth: '0px',
                                padding: '0px',
                                width: '295px',
                            }}
                        />
                        {props.showUserEmailButton && props.userEmail && (
                            <Box width={{ min: 'max-content' }}>
                                <Anchor
                                    onClick={() => {
                                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                        validateEmailAndNotify(props.userEmail!);
                                    }}
                                >
                                    or add yours
                                </Anchor>
                            </Box>
                        )}
                    </Box>
                </div>
            )}
        </Box>
    );
}

export function EmailList(props: { emails: string[]; onEmailsUpdate: (emails: string[]) => void }): JSX.Element {
    const { logger } = useTelemetryContext();

    const { loading, data } = useQuery(GET_THIS_USER, {
        onError: (error) => {
            logger.error(error.message);
        },
    });

    if (loading) {
        return <Spinner />;
    }

    return (
        <Box gap="xxsmall">
            {props.emails.length > 0 && (
                <Box direction="row" gap="xxsmall" align="center" wrap margin={{ bottom: 'xxsmall' }}>
                    {props.emails.map((email, ei) => (
                        <Tag
                            key={`email-${ei}`}
                            value={email}
                            size="xsmall"
                            border={{ color: Colors.black }}
                            background={Colors.background_front}
                            round="8px"
                            align="center"
                            onRemove={() => {
                                const newEmails = props.emails.filter((e) => e !== email);
                                props.onEmailsUpdate(newEmails);
                            }}
                        />
                    ))}
                </Box>
            )}
            <Box direction="row" gap="xsmall" align="center">
                <AddEmail
                    onNewEmail={(e) => {
                        // dedupe and update
                        const newEmails = Array.from(new Set([...props.emails, e]));
                        props.onEmailsUpdate(newEmails);
                    }}
                    showUserEmailButton={
                        data != null && data.user.email != null && !props.emails.includes(data.user.email)
                    }
                    userEmail={data?.user.email}
                />
            </Box>
        </Box>
    );
}
