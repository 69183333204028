// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box, Text } from 'grommet';
import { FontFamily } from '../theme';
import Divider from './Divider';

function OnboardProgressIndicatorButton(props: { index: number; label: string; active: boolean }): JSX.Element {
    return (
        <Box
            pad={{ horizontal: '7px', vertical: '6px' }}
            background={{ color: props.active ? Colors.black : Colors.border_light }}
            round="5px"
            direction="row"
            align="center"
            gap="9px"
        >
            <Box
                background={{ color: Colors.background_back }}
                style={{ borderRadius: '50%' }}
                width="22px"
                height="22px"
                align="center"
                justify="center"
                alignContent="center"
            >
                <Text style={{ fontFamily: FontFamily.Heading }} textAlign="center" size="14px">
                    {props.index}
                </Text>
            </Box>
            <Text
                style={{ fontFamily: FontFamily.Heading, textTransform: 'uppercase' }}
                weight={props.active ? 'bold' : 'normal'}
                size="14px"
            >
                {props.label}
            </Text>
        </Box>
    );
}

export function OnboardProgressIndicator(props: { step: 'connect' | 'create' }): JSX.Element {
    return (
        <Box direction="row" align="center">
            <OnboardProgressIndicatorButton active={props.step === 'connect'} index={1} label="Connect" />
            <Divider size="32px" color={Colors.status_disabled} />
            <OnboardProgressIndicatorButton active={props.step === 'create'} index={2} label="Create" />
        </Box>
    );
}
