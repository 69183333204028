// Copyright 2021
// ThatWorks.xyz Limited

import {
    closestCenter,
    DndContext,
    DragEndEvent,
    DragOverlay,
    DragStartEvent,
    KeyboardSensor,
    PointerSensor,
    UniqueIdentifier,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Colors } from '@thatworks/colors';
import { Box, Grid, ResponsiveContext, Text } from 'grommet';
import { AddCircle } from 'grommet-icons';
import { useContext, useMemo, useState } from 'react';
import { IconButtonV2 } from '../../../../../components/IconButton';
import { HoverPlainTextInput } from '../../../../../components/PlainTextInput';
import { FontFamily } from '../../../../../theme';
import { DatePreset, TimelineDateSelection } from '../filters/timeline-date-selection';
import { TimeSelectionToolbarButton } from '../filters/TimeSelectionToolbarButton';
import { ComposerStateManager, ComposerStateManagerContext } from './ComposerStateManagerContext';
import { FiltersOutput } from './FiltersOutput';
import { SUMMARY_BLOCK_HEADER_BG_COLOR, TemplateBlockHeader } from './PmTemplateNodeBaseComponents';
import { ProsemirrorComposer } from './ProsemirrorComposer';
import {
    BlockType,
    QueryTemplateBlockState,
    SHOW_ALL_INSIGHTS_SPECIAL_CASE_ID,
    TemplateBlockState,
} from './TemplateBlock';

export function MagicComposerBuilderV2(props: {
    templateName: string;
    onUpdateTemplateName: (templateName: string) => void;
    activeBlockId: string | undefined;
    blocks: TemplateBlockState[];
    onAddBlock: (type: BlockType) => void;
    onUpdateBlock: (block: TemplateBlockState) => void;
    onDeleteBlock: (blockId: string) => void;
    updateActiveBlockId: (blockId: string, active: boolean) => void;
    onChangeBlockIndex: (activeId: string, overId: string | undefined) => void;
    onLoading: (loading: boolean) => void;
    setPreviewDocContent: (content: string) => void;
    sessionCacheId: string;
    header?: React.ReactNode;
    footer?: React.ReactNode;
}): JSX.Element {
    // Top toolbar state
    const [dateSelection, setDateSelection] = useState<TimelineDateSelection>({
        preset: DatePreset.OneWeek,
        customDaysStr: '',
    });

    // State manager: prosemirror nodes use this to update the composer state
    const stateManager = useMemo(() => {
        const st: ComposerStateManager = {
            sessionCacheId: props.sessionCacheId,
            dateSelection,
            blocks: props.blocks,
            activeBlockId: props.activeBlockId,
            onAddBlock: props.onAddBlock,
            onUpdateBlock: props.onUpdateBlock,
            onDeleteBlock: props.onDeleteBlock,
            updateActiveBlockId: props.updateActiveBlockId,
            onChangeBlockIndex: props.onChangeBlockIndex,
            onLoading: props.onLoading,
        };
        return st;
    }, [
        dateSelection,
        props.activeBlockId,
        props.blocks,
        props.onAddBlock,
        props.onChangeBlockIndex,
        props.onDeleteBlock,
        props.onLoading,
        props.onUpdateBlock,
        props.sessionCacheId,
        props.updateActiveBlockId,
    ]);

    const responsiveSize = useContext(ResponsiveContext);

    // Drag and drop state
    // Prosemirror supports drag and drop for blocks but it doesn't look
    // as good as dnd-kit
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );
    const [dragId, setDragId] = useState<UniqueIdentifier | null>(null);
    const handleDragStart = (event: DragStartEvent) => {
        setDragId(event.active.id);
    };
    const handleDragEnd = ({ active, over }: DragEndEvent) => {
        setDragId(null);
        if (active.id !== over?.id) {
            props.onChangeBlockIndex(active.id.toString(), over?.id.toString());
        }
    };
    const overlayItem = useMemo(() => {
        const block = props.blocks.find((b) => b.id === dragId);
        if (block) {
            return <TemplateBlockHeader type={block.type} />;
        }
    }, [dragId, props.blocks]);

    // Render
    return (
        <ComposerStateManagerContext.Provider value={stateManager}>
            <Grid
                fill
                columns={['flex', responsiveSize === 'large' ? '420px' : '320px']}
                rows={['flex', '55px']}
                areas={[
                    { name: 'main', start: [0, 0], end: [0, 0] },
                    { name: 'inspector', start: [1, 0], end: [1, 0] },
                    { name: 'footer', start: [0, 1], end: [1, 1] },
                ]}
            >
                {/* Composer toolbar + editor */}
                <Box
                    gap="xsmall"
                    margin={{ top: 'small' }}
                    id="composer-editor"
                    gridArea="main"
                    width={{ width: '920px', min: '840px', max: '920px' }}
                    style={{ justifySelf: 'center' }}
                >
                    {/* Template name: only show when it is already set via the modal window */}
                    {props.templateName && (
                        <Box>
                            <HoverPlainTextInput
                                hoverBgColor={Colors.border_light}
                                inputProps={{ fontSize: '1.5em' }}
                                inputAttr={{
                                    placeholder: 'Optional Section Title',
                                    onChange: (e) => {
                                        props.onUpdateTemplateName(e.target.value);
                                    },
                                    maxLength: 100,
                                }}
                                style={{ width: '100%' }}
                                inputStyle={{
                                    // match css style for heading
                                    fontFamily: FontFamily.Standard,
                                    fontSize: '1.5em',
                                    color: '#444444',
                                    background: 'unset',
                                    padding: '0px',
                                    overflow: 'hidden',
                                    resize: 'none',
                                    width: '100%',
                                    fontWeight: 'bold',
                                }}
                                value={props.templateName}
                            />
                        </Box>
                    )}
                    {/* Toolbar */}
                    <Box direction="row" align="center" justify="between" height={{ min: 'max-content' }}>
                        <Box direction="row" align="center" gap="small">
                            <TimeSelectionToolbarButton onDateSelection={setDateSelection} selection={dateSelection} />
                        </Box>
                        {props.header && (
                            <Box direction="row" align="center" gap="small">
                                {props.header}
                            </Box>
                        )}
                    </Box>
                    {/* Editor */}
                    <Box
                        border={{ color: Colors.border_light, size: '1px' }}
                        round={{ size: '20px', corner: 'top' }}
                        background={{ color: Colors.background_back }}
                        pad={{ left: 'xxsmall', right: 'small', top: 'small' }}
                        overflow={{ vertical: 'auto' }}
                        fill="vertical"
                    >
                        <DndContext
                            sensors={sensors}
                            collisionDetection={closestCenter}
                            onDragEnd={handleDragEnd}
                            onDragStart={handleDragStart}
                        >
                            <SortableContext items={props.blocks} strategy={verticalListSortingStrategy}>
                                <ProsemirrorComposer
                                    stateManager={stateManager}
                                    setPreviewDocContent={props.setPreviewDocContent}
                                />
                                <DragOverlay>{dragId ? overlayItem : null}</DragOverlay>
                            </SortableContext>
                        </DndContext>
                        <Box
                            direction="row"
                            gap="xsmall"
                            justify="center"
                            height={{ min: 'max-content' }}
                            pad={{ bottom: 'small' }}
                        >
                            <Box
                                pad="xxsmall"
                                background={{ color: SUMMARY_BLOCK_HEADER_BG_COLOR }}
                                round={{ size: '15px' }}
                                align="center"
                                border={{ color: Colors.border_light, size: '1px' }}
                            >
                                <IconButtonV2
                                    icon={(hover) => (
                                        <AddCircle size="16px" color={hover ? Colors.accent_3 : Colors.dark_2} />
                                    )}
                                    label={(hover) => (
                                        <Text
                                            size="14px"
                                            style={{ fontFamily: FontFamily.Standard }}
                                            color={hover ? Colors.accent_3 : Colors.dark_2}
                                        >
                                            Add new summary section
                                        </Text>
                                    )}
                                    reverse
                                    onClick={() => {
                                        props.onAddBlock(BlockType.Query);
                                    }}
                                />
                            </Box>
                            {/* Add custom text button */}
                            <Box
                                pad="xxsmall"
                                background={{ color: Colors.background_front }}
                                round={{ size: '15px' }}
                                align="center"
                                border={{ color: Colors.border_light, size: '1px' }}
                            >
                                <IconButtonV2
                                    icon={(hover) => (
                                        <AddCircle size="16px" color={hover ? Colors.accent_3 : Colors.dark_2} />
                                    )}
                                    label={(hover) => (
                                        <Text
                                            size="14px"
                                            style={{ fontFamily: FontFamily.Standard }}
                                            color={hover ? Colors.accent_3 : Colors.dark_2}
                                        >
                                            Add new custom text section
                                        </Text>
                                    )}
                                    reverse
                                    onClick={() => {
                                        props.onAddBlock(BlockType.Text);
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                {/* Inspector */}
                <Box
                    id="composer-inspector"
                    background={{ color: Colors.background_front }}
                    border={{ side: 'left', size: '1px', color: Colors.border_dark }}
                    gridArea="inspector"
                >
                    {props.activeBlockId && (
                        <FiltersOutput
                            activeBlock={
                                props.blocks.find(
                                    (block) => block.id === props.activeBlockId && block.type === BlockType.Query,
                                ) as QueryTemplateBlockState | undefined
                            }
                            onIndicatorToggled={(indicator) => {
                                const activeBlock = props.blocks.find((block) => block.id === props.activeBlockId);
                                if (activeBlock != null) {
                                    const newState: TemplateBlockState = {
                                        ...activeBlock,
                                    };

                                    if (newState.type === BlockType.Query && activeBlock.type === BlockType.Query) {
                                        const selectedInsights = activeBlock.selectedInsightIds;
                                        const set = new Set(selectedInsights);
                                        set.delete(SHOW_ALL_INSIGHTS_SPECIAL_CASE_ID);
                                        if (set.has(indicator)) {
                                            set.delete(indicator);
                                        } else {
                                            set.add(indicator);
                                        }
                                        newState.selectedInsightIds = Array.from(set);
                                    }

                                    props.onUpdateBlock(newState);
                                }
                            }}
                            summaryLoading={false}
                            timelineLoading={false}
                            key={`index-${props.activeBlockId}`}
                            v2
                        />
                    )}
                </Box>
                {/* Footer */}
                <Box
                    gridArea="footer"
                    background={{ color: Colors.background_front }}
                    border={{ color: Colors.border_dark, size: '1px', side: 'top' }}
                >
                    {props.footer}
                </Box>
            </Grid>
        </ComposerStateManagerContext.Provider>
    );
}
