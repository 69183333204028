// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box, Text, TextExtendedProps } from 'grommet';
import { useMemo, useState } from 'react';
import { AutomationScheduleEvery } from '../../../../../__generated__/graphql';
import { DropdownMenuComponent } from '../../../../../components/DropdownMenu';
import { getLabelForSchedule } from '../../../../../components/schedule-label';
import { FontFamily } from '../../../../../theme';
import {
    DaysOfWeek,
    getDaysOfWeekFromIsoDay,
    getIsoWeekday,
    HalfHourTimeInterval,
    scheduleToTime,
    TimeInSchedule,
    to24Hour,
} from '../helpers/automation-types';

const TEXT_TITLE_PROPS: TextExtendedProps = {
    weight: 'bold',
    size: '14px',
};

function DropmenuWrapper(props: Parameters<typeof DropdownMenuComponent>[0] & { width?: string }): JSX.Element {
    return (
        <DropdownMenuComponent
            hideSearch
            singleSelectionOnly
            getTextProps={() => ({
                color: Colors.dark_3,
                size: '14px',
                style: { fontWeight: 'bold', fontFamily: FontFamily.Callout },
            })}
            boxProps={{
                border: { color: Colors.black, size: '1px' },
                width: props.width || '130px',
                justify: 'between',
                round: '8px',
                pad: '5px 10px',
                margin: '0',
                background: { color: Colors.background_front },
            }}
            closeMenuOnSelection
            {...props}
        />
    );
}

function ScheduleTimeSelection(props: {
    time: TimeInSchedule;
    onTimeChange: (v: TimeInSchedule) => void;
}): JSX.Element {
    return (
        <Box direction="row" gap="xsmall" align="baseline">
            <Text {...TEXT_TITLE_PROPS}>at</Text>
            <DropmenuWrapper
                options={Object.values(HalfHourTimeInterval).map((v) => ({
                    id: v,
                    label: v,
                }))}
                label={scheduleToTime(props.time)}
                onSelectionChange={(sel) => {
                    if (sel.length === 0) {
                        return;
                    }
                    const option = sel[0].id as HalfHourTimeInterval;
                    const { hour, minute } = to24Hour(option);
                    props.onTimeChange({
                        hour,
                        minute,
                        ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    });
                }}
                selected={[{ id: scheduleToTime(props.time), label: scheduleToTime(props.time) }]}
                hideSearch={false}
            />
        </Box>
    );
}

function ScheduleDayOfWeekSelection(props: {
    time: TimeInSchedule;
    onTimeChange: (v: TimeInSchedule) => void;
    isoDayOfWeek: number;
    onDayChange: (v: number) => void;
}): JSX.Element {
    return (
        <Box gap="xsmall" direction="row" align="baseline">
            <Box direction="row" gap="xsmall" align="baseline">
                <Text {...TEXT_TITLE_PROPS}>on</Text>
                <DropmenuWrapper
                    options={Object.values(DaysOfWeek).map((v) => ({ id: v, label: v }))}
                    label={getDaysOfWeekFromIsoDay(props.isoDayOfWeek)}
                    onSelectionChange={(sel) => {
                        if (sel.length === 0) {
                            return;
                        }
                        const option = sel[0].id as DaysOfWeek;
                        props.onDayChange(getIsoWeekday(option));
                    }}
                    selected={[
                        {
                            id: getDaysOfWeekFromIsoDay(props.isoDayOfWeek),
                            label: getDaysOfWeekFromIsoDay(props.isoDayOfWeek),
                        },
                    ]}
                />
            </Box>
            <ScheduleTimeSelection time={props.time} onTimeChange={props.onTimeChange} />
        </Box>
    );
}

const MONTHLY_SCHEDULES = [
    AutomationScheduleEvery.MonthFirstDayOfWeek,
    AutomationScheduleEvery.MonthLastDayOfWeek,
    AutomationScheduleEvery.MonthFirstDay,
    AutomationScheduleEvery.MonthLastDay,
    AutomationScheduleEvery.MonthDay,
];

function ScheduleMonthlySelection(props: {
    every: AutomationScheduleEvery;
    onEveryChange: (v: AutomationScheduleEvery) => void;
    isoDayOfWeek: number;
    onDayChange: (v: number) => void;
    dayOfMonth: number;
    onDayOfMonthChange: (v: number) => void;
}): JSX.Element {
    return (
        <Box gap="xsmall" direction="row" align="baseline">
            <Box direction="row" gap="xsmall" align="baseline">
                <Text {...TEXT_TITLE_PROPS}>on</Text>

                <DropmenuWrapper
                    options={MONTHLY_SCHEDULES.map((v) => ({
                        id: v,
                        label: getLabelForSchedule(v),
                    }))}
                    label={getLabelForSchedule(props.every)}
                    onSelectionChange={(sel) => {
                        if (sel.length === 0) {
                            return;
                        }
                        const option = sel[0].id as AutomationScheduleEvery;
                        props.onEveryChange(option);
                    }}
                    selected={[
                        {
                            id: props.every,
                            label: getLabelForSchedule(props.every),
                        },
                    ]}
                    width={
                        props.every === AutomationScheduleEvery.MonthFirstDay ||
                        props.every === AutomationScheduleEvery.MonthLastDay
                            ? '230px'
                            : '105px'
                    }
                />
            </Box>
            {(props.every === AutomationScheduleEvery.MonthFirstDayOfWeek ||
                props.every === AutomationScheduleEvery.MonthLastDayOfWeek) && (
                <Box direction="row" gap="xsmall" align="baseline">
                    <DropmenuWrapper
                        options={Object.values(DaysOfWeek).map((v) => ({ id: v, label: v }))}
                        label={getDaysOfWeekFromIsoDay(props.isoDayOfWeek)}
                        selected={[
                            {
                                id: getDaysOfWeekFromIsoDay(props.isoDayOfWeek),
                                label: getDaysOfWeekFromIsoDay(props.isoDayOfWeek),
                            },
                        ]}
                        onSelectionChange={(sel) => {
                            if (sel.length === 0) {
                                return;
                            }
                            const option = sel[0].id as DaysOfWeek;
                            props.onDayChange(getIsoWeekday(option));
                        }}
                        width={'140px'}
                    />
                </Box>
            )}
            {props.every === AutomationScheduleEvery.MonthDay && (
                <Box direction="row" gap="xsmall" align="baseline">
                    <DropmenuWrapper
                        options={Array.from({ length: 31 }, (_, i) => i + 1).map((v) => ({
                            id: v.toString(),
                            label: v.toString(),
                        }))}
                        label={props.dayOfMonth.toString()}
                        selected={[
                            {
                                id: props.dayOfMonth.toString(),
                                label: props.dayOfMonth.toString(),
                            },
                        ]}
                        onSelectionChange={(sel) => {
                            if (sel.length === 0) {
                                return;
                            }
                            const option = parseInt(sel[0].id);
                            props.onDayOfMonthChange(option);
                        }}
                        width={'105px'}
                    />
                </Box>
            )}
        </Box>
    );
}

enum AutomationScheduleUi {
    Weekday = 'Weekday',
    Week = 'Week',
    Month = 'Month',
    Day = 'Day',
}

function getLabelForScheduleUi(schedule: AutomationScheduleUi): string {
    switch (schedule) {
        case AutomationScheduleUi.Day:
            return 'Day';
        case AutomationScheduleUi.Week:
            return 'Week';
        case AutomationScheduleUi.Weekday:
            return 'Weekday';
        case AutomationScheduleUi.Month:
            return 'Month';
    }
}

function getScheduleUiFromSchedule(schedule: AutomationScheduleEvery): AutomationScheduleUi {
    switch (schedule) {
        case AutomationScheduleEvery.Day:
            return AutomationScheduleUi.Day;
        case AutomationScheduleEvery.Week:
            return AutomationScheduleUi.Week;
        case AutomationScheduleEvery.WeekDay:
            return AutomationScheduleUi.Weekday;
        case AutomationScheduleEvery.MonthFirstDay:
        case AutomationScheduleEvery.MonthLastDay:
        case AutomationScheduleEvery.MonthFirstDayOfWeek:
        case AutomationScheduleEvery.MonthLastDayOfWeek:
        case AutomationScheduleEvery.MonthDay:
            return AutomationScheduleUi.Month;
    }
}

function getScheduleFromUi(schedule: AutomationScheduleUi): AutomationScheduleEvery {
    switch (schedule) {
        case AutomationScheduleUi.Day:
            return AutomationScheduleEvery.Day;
        case AutomationScheduleUi.Week:
            return AutomationScheduleEvery.Week;
        case AutomationScheduleUi.Weekday:
            return AutomationScheduleEvery.WeekDay;
        case AutomationScheduleUi.Month:
            throw new Error('Not supported, use the specific month component');
    }
}

export function ScheduleRow(props: {
    every: AutomationScheduleEvery;
    onEveryChange: (e: AutomationScheduleEvery) => void;
    time: TimeInSchedule;
    onTimeChange: (v: TimeInSchedule) => void;
    isoDayOfWeek: number;
    onDayChange: (v: number) => void;
    dayOfMonth: number;
    onDayOfMonthChange: (v: number) => void;
}): JSX.Element {
    const [scheduleInUi, setScheduleInUi] = useState<AutomationScheduleUi>(getScheduleUiFromSchedule(props.every));

    const textDescription = useMemo(() => {
        const res = `Delivers a summary`;
        switch (props.every) {
            case AutomationScheduleEvery.Day:
                return `${res} everyday with data from the previous day.`;
            case AutomationScheduleEvery.Week:
                return `${res} once a week with data from the previous week.`;
            case AutomationScheduleEvery.WeekDay:
                return `${res} every weekday with data from the previous weekday.`;
            case AutomationScheduleEvery.MonthFirstDay:
                return `${res} on the first day of the month with data from the previous month.`;
            case AutomationScheduleEvery.MonthLastDay:
                return `${res} on the last day of the month with data covering a month.`;
            case AutomationScheduleEvery.MonthFirstDayOfWeek:
                return `${res} on the first ${getDaysOfWeekFromIsoDay(
                    props.isoDayOfWeek,
                )} of the month with data from the previous month.`;
            case AutomationScheduleEvery.MonthLastDayOfWeek:
                return `${res} on the last ${getDaysOfWeekFromIsoDay(
                    props.isoDayOfWeek,
                )} of the month with data from the previous month.`;
            case AutomationScheduleEvery.MonthDay:
                return `${res} on the ${
                    props.dayOfMonth === 31 ? `31 (or last day)` : props.dayOfMonth
                } of the month with data from the previous month.`;
        }
    }, [props.dayOfMonth, props.every, props.isoDayOfWeek]);

    return (
        <Box gap="xxsmall">
            <Box direction="row" align="baseline" gap="xsmall">
                <Text {...TEXT_TITLE_PROPS}>Every</Text>
                <DropmenuWrapper
                    label={getLabelForScheduleUi(scheduleInUi)}
                    onSelectionChange={(sel) => {
                        if (sel.length === 0) {
                            return;
                        }
                        const selection = sel[0].id as AutomationScheduleUi;
                        setScheduleInUi(selection);
                        props.onEveryChange(
                            selection === AutomationScheduleUi.Month
                                ? AutomationScheduleEvery.MonthFirstDayOfWeek
                                : getScheduleFromUi(selection),
                        );
                    }}
                    selected={[{ id: scheduleInUi, label: getLabelForScheduleUi(scheduleInUi) }]}
                    options={Object.values(AutomationScheduleUi).map((v) => ({
                        id: v,
                        label: getLabelForScheduleUi(v),
                    }))}
                />
            </Box>
            {scheduleInUi === AutomationScheduleUi.Month && (
                <ScheduleMonthlySelection
                    every={props.every}
                    onEveryChange={props.onEveryChange}
                    isoDayOfWeek={props.isoDayOfWeek}
                    onDayChange={props.onDayChange}
                    dayOfMonth={props.dayOfMonth}
                    onDayOfMonthChange={props.onDayOfMonthChange}
                />
            )}
            {props.every !== AutomationScheduleEvery.Week && (
                <ScheduleTimeSelection onTimeChange={props.onTimeChange} time={props.time} />
            )}
            {props.every === AutomationScheduleEvery.Week && (
                <ScheduleDayOfWeekSelection
                    onTimeChange={props.onTimeChange}
                    time={props.time}
                    isoDayOfWeek={props.isoDayOfWeek}
                    onDayChange={props.onDayChange}
                />
            )}
            <Text style={{ marginTop: '8px' }} size="small">
                {textDescription}
            </Text>
        </Box>
    );
}
