// Copyright 2021
// ThatWorks.xyz Limited

import { autoPlacement, autoUpdate, useDismiss, useFloating, useInteractions } from '@floating-ui/react';
import { useActive, useAttrs, useChainedCommands, useCurrentSelection, useExtensionEvent } from '@remirror/react';
import { Colors } from '@thatworks/colors';
import { Box } from 'grommet';
import { FormCheckmark, FormTrash, Link } from 'grommet-icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { LinkExtension, ShortcutHandlerProps } from 'remirror/extensions';
import { IconButtonV2 } from '../IconButton';
import { PlainTextInput } from '../PlainTextInput';
import { ToolbarButton } from './ToolbarButton';

// A modfication of https://remirror.io/docs/extensions/link-extension/#examples to get it to work with grommet

function useLinkShortcut() {
    const [linkShortcut, setLinkShortcut] = useState<ShortcutHandlerProps | undefined>();
    useExtensionEvent(
        LinkExtension,
        'onShortcut',
        useCallback((props) => {
            return setLinkShortcut(props);
        }, []),
    );

    return { linkShortcut };
}

function useFloatingLinkState() {
    const chain = useChainedCommands();
    const { linkShortcut } = useLinkShortcut();
    const { to } = useCurrentSelection();

    const url = (useAttrs().link()?.href as string) ?? '';
    const [href, setHref] = useState<string>(url);

    const onRemove = useCallback(() => chain.removeLink().focus().run(), [chain]);

    useEffect(() => {
        setHref(url);
    }, [url]);

    const submitHref = useCallback(() => {
        const range = linkShortcut ?? undefined;
        if (href === '') {
            chain.removeLink();
        } else {
            chain.updateLink({ href, auto: false }, range);
        }

        chain.focus(range?.to ?? to).run();
    }, [linkShortcut, chain, href, to]);

    return useMemo(
        () => ({
            href,
            setHref,
            linkShortcut,
            onRemove,
            submitHref,
        }),
        [href, linkShortcut, onRemove, submitHref],
    );
}

function UrlMenu(props: { onCloseMenu: () => void }): JSX.Element {
    const { onRemove, submitHref, href, setHref } = useFloatingLinkState();

    return (
        <Box
            border={{ color: Colors.dark_3 }}
            pad="xxsmall"
            round="5px"
            background={{ color: Colors.background_back }}
            direction="row"
            align="center"
            gap="xxsmall"
        >
            <PlainTextInput
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                fontSize="14px"
                placeholder="Insert link"
                style={{ minWidth: '230px' }}
                value={href}
                onChange={(event) => setHref(event.target.value)}
                onKeyDown={(event) => {
                    const { code } = event;
                    if (code === 'Enter') {
                        submitHref();
                        props.onCloseMenu();
                    }
                }}
            />
            <IconButtonV2
                icon={(hover) => <FormCheckmark color={hover ? Colors.brand : undefined} />}
                onClick={() => {
                    submitHref();
                    props.onCloseMenu();
                }}
            />
            {href.length > 0 && (
                <IconButtonV2
                    icon={(hover) => <FormTrash color={hover ? Colors.brand : undefined} />}
                    onClick={() => {
                        onRemove();
                        props.onCloseMenu();
                    }}
                />
            )}
        </Box>
    );
}

export const LinkToolbarButton = () => {
    const { link } = useActive();
    const { empty } = useCurrentSelection();
    const [show, setShow] = useState(false);

    const { refs, floatingStyles, context } = useFloating({
        whileElementsMounted: autoUpdate,
        middleware: [
            autoPlacement({
                allowedPlacements: ['top-start', 'bottom-start'],
            }),
        ],
        open: show,
        onOpenChange: setShow,
    });

    const dismiss = useDismiss(context);
    const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

    return (
        <>
            <Box ref={refs.setReference} {...getReferenceProps()} direction="row" align="center">
                <ToolbarButton
                    onClick={async () => {
                        setShow(true);
                    }}
                    active={link()}
                    disabled={empty}
                    icon={Link}
                    iconSize="14px"
                    boxProps={{ pad: '4px', border: { color: link() ? Colors.accent_3 : Colors.border_dark } }}
                />
            </Box>

            {show && (
                <div ref={refs.setFloating} style={{ ...floatingStyles }} {...getFloatingProps()}>
                    <UrlMenu onCloseMenu={() => setShow(false)} />
                </div>
            )}
        </>
    );
};
