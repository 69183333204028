// Copyright 2021
// ThatWorks.xyz Limited

import { FetchResult, useLazyQuery, useMutation } from '@apollo/client';
import { arrayMove } from '@dnd-kit/sortable';
import { useStatsigClient } from '@statsig/react-bindings';
import { Colors } from '@thatworks/colors';
import { Gates } from '@thatworks/shared-frontend/metrics';
import { joinPagesPaths, Pages, QueryParams } from '@thatworks/shared-frontend/pages';
import { Box, Button, Grid, Text } from 'grommet';
import { Add, Icon, MailOption, Slack } from 'grommet-icons';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { gql } from '../../../../../__generated__';
import {
    CreateTemplateMutation,
    SlackChannelMention,
    WorkspacePostTemplateQuery,
    WorkspacePostTemplateUpdateMutation,
} from '../../../../../__generated__/graphql';
import { CtaButtonSpinnerV2 } from '../../../../../components/CtaButtonSpinner';
import { FloatingLayer } from '../../../../../components/FloatingLayer';
import { useTelemetryContext } from '../../../../../components/TelemetryContext';
import { TwDropButton } from '../../../../../components/TwDropButton';
import { useUserStateContext } from '../../../../../components/UserContext';
import { Inbox } from '../../../../../icons/Inbox';
import { ThreeDotsVertical } from '../../../../../icons/ThreeDotsVertical';
import { Thunder } from '../../../../../icons/Thunder';
import { FontFamily } from '../../../../../theme';
import { CREATE_TEMPLATE, DELETE_TEMPLATE_DRAFT, UPDATE_TEMPLATE } from '../../templates/components/template-queries';
import {
    createOrEditTemplate,
    getAutomation,
    getBlocks,
    getValidBlocks,
    TemplateQl,
} from '../helpers/automation-helpers';
import { AutoSaveMode, ComposerAutoSave } from './ComposerAutoSave';
import { AutomationState, MagicComposerAutomate } from './MagicComposerAutomate';
import { CreationMode, CreationModeLabels, MagicComposerBuilder, UpdateModeLabels } from './MagicComposerBuilder';
import { MagicComposerBuilderV2 } from './MagicComposerBuilderV2';
import { MagicComposerPreview } from './MagicComposerPreview';
import { MagicComposerQuery } from './MagicComposerQuery';
import { MagicComposerShareTo } from './MagicComposerShareTo';
import {
    BlockType,
    getDefaultQueryTemplateBlockProps,
    getDefaultTextTemplateBlockProps,
    TemplateBlockState,
} from './TemplateBlock';

export const GENERATE_POST_TITLE = gql(/* GraphQL */ `
    query GeneratePostTitle($data: [String!]!) {
        generatePostTitleFromDataSources(data: $data)
    }
`);

const ComposerOptions: { icon: Icon; title: string; label: string; mode: CreationMode }[] = [
    {
        icon: Thunder,
        title: 'New Automation',
        label: 'Automate routines like catchups, standups, and meetings',
        mode: CreationMode.Automation,
    },
    { icon: Add, title: 'New Post', label: 'Share ad-hoc information with others', mode: CreationMode.Post },
    {
        icon: Add,
        title: 'New Saved Query',
        label: 'A query that can be reused later to generate a summary',
        mode: CreationMode.SavedQueries,
    },
];

function isTemplateResponseValid(
    res: FetchResult<CreateTemplateMutation> | FetchResult<WorkspacePostTemplateUpdateMutation>,
): boolean {
    return !!(
        res &&
        res.data &&
        !res.errors &&
        ('workspacePostTemplateCreate' in res.data || 'workspacePostTemplateUpdate' in res.data)
    );
}

export const MAGIC_COMPOSER_CTA_BUTTON_HEIGHT = '30px';

function MagicComposerOption(props: { icon: Icon; title: string; label: string; mode: CreationMode }): JSX.Element {
    const navigate = useNavigate();
    return (
        <Button
            plain
            onClick={() =>
                navigate(`${joinPagesPaths([Pages.app.root, Pages.app.subs.create])}?${QueryParams.Mode}=${props.mode}`)
            }
        >
            {({ hover }) => (
                <Box
                    direction="column"
                    background={Colors.background_back}
                    align="center"
                    width="280px"
                    height="200px"
                    round="10px"
                    justify="center"
                    elevation="small"
                    border={{ size: '1px', color: hover ? Colors.accent_3 : Colors.border_light }}
                    pad="small"
                >
                    <Box direction="row" align="center" gap="xxsmall">
                        {props.icon && <props.icon size="16px" color={Colors.dark_2} />}
                        <Text
                            size="20px"
                            weight="bold"
                            color={Colors.dark_2}
                            style={{ fontFamily: FontFamily.Standard }}
                        >
                            {`${props.title}`}
                        </Text>
                    </Box>
                    <Text
                        size="13px"
                        color={Colors.dark_2}
                        style={{ fontFamily: FontFamily.Standard }}
                        textAlign="center"
                    >
                        {`${props.label}`}
                    </Text>
                </Box>
            )}
        </Button>
    );
}

function MagicComposerOptions(props: { pageTitle: string }): JSX.Element {
    return (
        <Box fill pad={{ horizontal: 'xsmall', vertical: '10px' }}>
            <Text
                size="28px"
                style={{ fontFamily: FontFamily.Callout, position: 'absolute' }}
                color={Colors.dark_2}
                weight="bold"
            >
                {props.pageTitle}
            </Text>
            <Box gap="xsmall" direction="row" justify="center" fill>
                {ComposerOptions.map((option) => (
                    <Box key={option.title} align="center" justify="center">
                        <MagicComposerOption {...option} />
                    </Box>
                ))}
            </Box>
        </Box>
    );
}

export function MagicComposer(props: {
    mode: CreationMode | null;
    edit: boolean;
    data?: WorkspacePostTemplateQuery['workspacePostTemplate'];
    initialBlocks?: TemplateBlockState[];
    onTemplateRestored: () => void;
    useV2?: boolean;
}): JSX.Element {
    // Initialize
    const navigate = useNavigate();
    const { logger } = useTelemetryContext();
    const { postErrorMessage } = useUserStateContext();
    const sessionCacheId = useRef(Math.random().toString(36).substring(7));
    const [templateName, setTemplateName] = useState(props.data?.title ?? '');
    const [blocks, setBlocks] = useState<TemplateBlockState[]>(getBlocks(props.data));
    const [automationState, setAutomationState] = useState<AutomationState>(getAutomation(props.data));
    const [slackNotifications, setSlackNotifications] = useState(props.data?.slackNotifications ?? []);
    const [slackChannelMention, setSlackChannelMention] = useState<SlackChannelMention>(
        props.data?.slackChannelMention ?? SlackChannelMention.None,
    );
    const [emailNotifications, setEmailNotifications] = useState(props.data?.emailNotifications ?? []);
    const [summaryCustomFormattingPrompt, setSummaryCustomFormattingPrompt] = useState<string | undefined>(
        props.data?.summaryCustomFormattingPrompt || undefined,
    );
    const [showShareToModal, setShowShareToModal] = useState<boolean>(false);
    const [showAutomateModal, setShowAutomateModal] = useState<boolean>(false);
    const [showQueryModal, setShowQueryModal] = useState<boolean>(false);
    const [loadingPreview, setLoadingPreview] = useState<boolean>(false);
    const [activeBlockId, setActiveBlockId] = useState<string | undefined>(
        blocks.length > 0 ? blocks[0].id : undefined,
    );
    const [previewDocContent, setPreviewDocContent] = useState<string>('');

    const { checkGate } = useStatsigClient();

    useEffect(() => {
        if (props.initialBlocks != null && props.initialBlocks.length > 0) {
            setBlocks(props.initialBlocks);
            setActiveBlockId(props.initialBlocks[0].id);
        }
    }, [props.initialBlocks]);

    // GraphQL
    const [createTemplateQl] = useMutation(CREATE_TEMPLATE, {
        onError: (error) => {
            postErrorMessage({ title: 'Error', shortDesc: 'Failed to create' });
            logger.error(error.message);
        },
    });
    const [updateTemplateQl] = useMutation(UPDATE_TEMPLATE, {
        onError: (error) => {
            postErrorMessage({ title: 'Error', shortDesc: 'Failed to update' });
            logger.error(error.message);
        },
    });
    const templateQl: TemplateQl = useMemo(
        () => ({
            createTemplateQl,
            updateTemplateQl,
        }),
        [createTemplateQl, updateTemplateQl],
    );
    const [deleteDraft] = useMutation(DELETE_TEMPLATE_DRAFT, {
        onError: (e) => {
            console.error(e);
        },
    });

    const goToAutomations = useCallback(() => {
        navigate(joinPagesPaths([Pages.app.root, Pages.app.subs.automations]));
    }, [navigate]);

    const goToSavedQueries = useCallback(() => {
        navigate(joinPagesPaths([Pages.app.root, Pages.app.subs.savedQueries]));
    }, [navigate]);

    // Valid query blocks
    const validQueryBlocks = useMemo(() => getValidBlocks(blocks), [blocks]);

    // Handle on save automation
    const onSaveAutomation = useCallback(
        async (title: string) => {
            // Create or edit template
            const res = await createOrEditTemplate(
                title,
                validQueryBlocks,
                slackNotifications,
                slackChannelMention,
                emailNotifications,
                props.data,
                templateQl,
                props.edit,
                automationState,
                summaryCustomFormattingPrompt,
            );

            if (isTemplateResponseValid(res)) {
                await deleteDraft();
                // Redirect to automations page ONLY on success to not lose the data
                goToAutomations();
            }
        },
        [
            automationState,
            emailNotifications,
            goToAutomations,
            props.data,
            props.edit,
            slackNotifications,
            slackChannelMention,
            templateQl,
            validQueryBlocks,
            summaryCustomFormattingPrompt,
            deleteDraft,
        ],
    );

    // Handle on save saved queries
    const onSaveSavedQueries = useCallback(
        async (title: string) => {
            // Create or edit template
            const res = await createOrEditTemplate(
                title,
                validQueryBlocks,
                slackNotifications,
                slackChannelMention,
                emailNotifications,
                props.data,
                templateQl,
                props.edit,
                undefined,
                summaryCustomFormattingPrompt,
            );

            if (isTemplateResponseValid(res)) {
                await deleteDraft();
                // Redirect to saved queries page, ONLY on success to not lose the data
                goToSavedQueries();
            }
        },
        [
            emailNotifications,
            goToSavedQueries,
            props.data,
            props.edit,
            slackNotifications,
            slackChannelMention,
            templateQl,
            validQueryBlocks,
            summaryCustomFormattingPrompt,
            deleteDraft,
        ],
    );

    // Flag to know if everything is ready to be saved
    const canSave = useMemo(
        () => validQueryBlocks.queryBlocks.length > 0 || validQueryBlocks.textBlocks.length > 0,
        [validQueryBlocks.queryBlocks, validQueryBlocks.textBlocks],
    );

    // Add empty block to the template
    const addBlock = (type: BlockType) => {
        setBlocks((previousBlocks) => {
            if (type === BlockType.Query) {
                const previous = previousBlocks[previousBlocks.length - 1] || undefined;
                const newBlock = getDefaultQueryTemplateBlockProps(
                    previous != null && previous.type === BlockType.Query ? previous : undefined,
                );
                setActiveBlockId(newBlock.id);
                return [...previousBlocks, newBlock];
            } else {
                const newBlock = getDefaultTextTemplateBlockProps();
                setActiveBlockId(newBlock.id);
                return [...previousBlocks, newBlock];
            }
        });
    };

    // Update the blocks with the received block
    const updateBlock = (block: TemplateBlockState) => {
        setBlocks((previousBlocks) => {
            const newBlocks = [...previousBlocks];

            // Check if the block is still present in the blocks list. If so, update its value
            const blockIndex = newBlocks.findIndex((b) => b.id === block.id);
            if (blockIndex >= 0) {
                newBlocks[blockIndex] = block;
            }
            // Return the new state
            return newBlocks;
        });
    };

    // Delete the block received
    const deleteBlock = (blockId: string) => {
        const blockIndex = blocks.findIndex((block) => block.id === blockId);
        setBlocks((pBlocks) => pBlocks.slice(0, blockIndex).concat(pBlocks.slice(blockIndex + 1)));
    };

    const dataSourcesDescription = useMemo(() => {
        // If there are no query blocks, return the first text block
        if (validQueryBlocks.queryBlocks.length === 0 && validQueryBlocks.textBlocks.length > 0) {
            return validQueryBlocks.textBlocks[0].markdownText;
        }

        return validQueryBlocks.queryBlocks.map((block) => {
            const dataSources = block.scopes.map((s) => s.itemName);
            if (block.filterName) {
                return `${block.filterName} for ${dataSources.join(', ')}`;
            }
            return dataSources.join(', ');
        });
    }, [validQueryBlocks]);

    const [generateTitle, { loading: loadingTitle }] = useLazyQuery(GENERATE_POST_TITLE, {
        onCompleted: (data) => {
            if (data.generatePostTitleFromDataSources) {
                setTemplateName(data.generatePostTitleFromDataSources);
            }
        },
    });

    // Get mode action
    const modeAction = useCallback((m: CreationMode | undefined | null) => {
        switch (m) {
            case CreationMode.Post:
                setShowShareToModal(true);
                break;
            case CreationMode.SavedQueries:
                setShowQueryModal(true);
                break;
            case CreationMode.Automation:
                setShowAutomateModal(true);
                break;
            default:
                break;
        }
    }, []);

    return (
        <>
            {!props.mode && !props.useV2 && <MagicComposerOptions pageTitle="Create" />}
            {props.mode && !props.useV2 && (
                <Grid columns={['58%', '42%']} rows={['flex', 'min-content']} width="100%" height="100%" responsive>
                    {/* Magic composer builder */}
                    <MagicComposerBuilder
                        creationMode={props.mode}
                        gridArea="1/1/3/2"
                        templateName={templateName}
                        onUpdateTemplateName={(templateName) => setTemplateName(templateName)}
                        blocks={blocks}
                        activeBlockId={activeBlockId}
                        onAddBlock={addBlock}
                        onUpdateBlock={updateBlock}
                        onDeleteBlock={deleteBlock}
                        updateActiveBlockId={(blockId, active) =>
                            active ? setActiveBlockId(blockId) : setActiveBlockId(undefined)
                        }
                        onChangeBlockIndex={(activeId, overId) => {
                            setBlocks((blocks) => {
                                const oldIndex = blocks.findIndex((block) => block.id === activeId);
                                const newIndex = blocks.findIndex((block) => block.id === overId);
                                return arrayMove(blocks, oldIndex, newIndex);
                            });
                        }}
                        sessionCacheId={sessionCacheId.current}
                        onLoading={(loading) => setLoadingPreview(loading)}
                    />

                    {/* Magic composer preview */}
                    <MagicComposerPreview
                        gridArea="1/2/3/3"
                        templateName={templateName}
                        blocks={blocks}
                        loadingPreview={loadingPreview}
                        summaryCustomFormattingPrompt={summaryCustomFormattingPrompt}
                        onSummaryCustomFormattingPromptUpdate={setSummaryCustomFormattingPrompt}
                        onPreviewDocChange={setPreviewDocContent}
                    />

                    {/* Action button */}
                    <Box
                        gridArea="2/2/3/3"
                        style={{ zIndex: '1' }}
                        direction="row"
                        background={{ color: Colors.background_back, opacity: 'strong' }}
                        align="center"
                        gap="xxsmall"
                        pad="xsmall"
                        justify="end"
                    >
                        {checkGate(Gates.AutoSave) && (
                            <ComposerAutoSave
                                mode={
                                    props.edit && props.data
                                        ? {
                                              type: AutoSaveMode.Edit,
                                              state: {
                                                  initial: props.data,
                                              },
                                          }
                                        : {
                                              type: AutoSaveMode.Create,
                                              state: {
                                                  initial: {
                                                      blocks,
                                                      summaryCustomFormattingPrompt,
                                                  },
                                              },
                                          }
                                }
                                common={{
                                    currentState: {
                                        automation: automationState,
                                        blocks,
                                        emailNotifications,
                                        slackChannelMention,
                                        slackNotifications,
                                        summaryCustomFormattingPrompt,
                                        templateName,
                                    },
                                    updateQl: templateQl.updateTemplateQl,
                                }}
                                onTemplateRestored={props.onTemplateRestored}
                                onLoadDraft={(draft) => {
                                    // Only loading blocks and summary custom formatting prompt.
                                    // Automation and other fields are not loaded from the draft
                                    // becuase they have to be defined by the user in the modal.
                                    const b = getBlocks(draft);
                                    setBlocks(b);
                                    setActiveBlockId(b.length > 0 ? b[0].id : undefined);
                                    setSummaryCustomFormattingPrompt(draft.summaryCustomFormattingPrompt || undefined);
                                }}
                            />
                        )}
                        <CtaButtonSpinnerV2
                            label={
                                props.edit && checkGate(Gates.AutoSave)
                                    ? UpdateModeLabels[props.mode]
                                    : CreationModeLabels[props.mode]
                            }
                            style={{ padding: '1px 14px', height: MAGIC_COMPOSER_CTA_BUTTON_HEIGHT }}
                            onClick={async () => {
                                if (props.data == null) {
                                    generateTitle({ variables: { data: dataSourcesDescription } });
                                }
                                modeAction(props.mode);
                            }}
                            disabled={!canSave}
                        />
                    </Box>
                </Grid>
            )}
            {props.useV2 && (
                <MagicComposerBuilderV2
                    templateName={templateName}
                    onUpdateTemplateName={(templateName) => setTemplateName(templateName)}
                    activeBlockId={activeBlockId}
                    setPreviewDocContent={setPreviewDocContent}
                    blocks={blocks}
                    onAddBlock={addBlock}
                    onUpdateBlock={updateBlock}
                    onDeleteBlock={deleteBlock}
                    updateActiveBlockId={(blockId, active) =>
                        active ? setActiveBlockId(blockId) : setActiveBlockId(undefined)
                    }
                    onChangeBlockIndex={(activeId, overId) => {
                        setBlocks((blocks) => {
                            const oldIndex = blocks.findIndex((block) => block.id === activeId);
                            const newIndex = blocks.findIndex((block) => block.id === overId);
                            return arrayMove(blocks, oldIndex, newIndex);
                        });
                    }}
                    sessionCacheId={sessionCacheId.current}
                    onLoading={(loading) => setLoadingPreview(loading)}
                    header={
                        // Auto-save component
                        checkGate(Gates.AutoSave) ? (
                            <ComposerAutoSave
                                v2
                                mode={
                                    props.edit && props.data
                                        ? {
                                              type: AutoSaveMode.Edit,
                                              state: {
                                                  initial: props.data,
                                              },
                                          }
                                        : {
                                              type: AutoSaveMode.Create,
                                              state: {
                                                  initial: {
                                                      blocks,
                                                      summaryCustomFormattingPrompt,
                                                  },
                                              },
                                          }
                                }
                                common={{
                                    currentState: {
                                        automation: automationState,
                                        blocks,
                                        emailNotifications,
                                        slackChannelMention,
                                        slackNotifications,
                                        summaryCustomFormattingPrompt,
                                        templateName,
                                    },
                                    updateQl: templateQl.updateTemplateQl,
                                }}
                                onTemplateRestored={props.onTemplateRestored}
                                onLoadDraft={(draft) => {
                                    // Only loading blocks and summary custom formatting prompt.
                                    // Automation and other fields are not loaded from the draft
                                    // becuase they have to be defined by the user in the modal.
                                    const b = getBlocks(draft);
                                    setBlocks(b);
                                    setActiveBlockId(b.length > 0 ? b[0].id : undefined);
                                    setSummaryCustomFormattingPrompt(draft.summaryCustomFormattingPrompt || undefined);
                                }}
                            />
                        ) : undefined
                    }
                    footer={
                        <Box direction="row" align="center" gap="xxsmall" pad="xsmall" justify="end">
                            {/* Edit mode cta */}
                            {props.edit && props.mode && (
                                <CtaButtonSpinnerV2
                                    label={UpdateModeLabels[props.mode]}
                                    style={{ padding: '1px 14px', height: MAGIC_COMPOSER_CTA_BUTTON_HEIGHT }}
                                    onClick={async () => {
                                        if (props.data == null) {
                                            generateTitle({ variables: { data: dataSourcesDescription } });
                                        }
                                        modeAction(props.mode);
                                    }}
                                    disabled={!canSave}
                                />
                            )}
                            {/* Saved queries */}
                            {!props.edit && (
                                <TwDropButton
                                    button={{
                                        icon: (hover) => (
                                            <ThreeDotsVertical
                                                size="22px"
                                                color={hover ? Colors.accent_3 : undefined}
                                            />
                                        ),
                                        disabled: !canSave,
                                    }}
                                    menuButtons={[
                                        {
                                            reverse: true,
                                            icon: (hover) => (
                                                <Inbox color={hover ? Colors.accent_3 : undefined} size="14px" />
                                            ),
                                            label: (hover) => (
                                                <Text color={hover ? Colors.accent_3 : undefined} size="14px">
                                                    Save For Later
                                                </Text>
                                            ),
                                            background: { color: Colors.background_back },
                                            pad: 'xxsmall',
                                            innerBoxProps: { round: '5px' },
                                            onClick: () => {
                                                if (props.data == null) {
                                                    generateTitle({ variables: { data: dataSourcesDescription } });
                                                }
                                                modeAction(CreationMode.SavedQueries);
                                            },
                                        },
                                    ]}
                                    placements={['left']}
                                />
                            )}
                            {/* Create mode: Share/Create Post */}
                            {!props.edit && (
                                <Button
                                    label="Share"
                                    icon={
                                        <Box direction="row" align="center" gap="xxsmall">
                                            <Slack color="black" size="14px" />
                                            <MailOption color="black" size="14px" />
                                        </Box>
                                    }
                                    style={{ padding: '1px 14px', height: MAGIC_COMPOSER_CTA_BUTTON_HEIGHT }}
                                    onClick={async () => {
                                        if (props.data == null) {
                                            generateTitle({ variables: { data: dataSourcesDescription } });
                                        }
                                        modeAction(CreationMode.Post);
                                    }}
                                    disabled={!canSave}
                                />
                            )}
                            {/* Create mode: Automate */}
                            {!props.edit && (
                                <CtaButtonSpinnerV2
                                    label="Automate"
                                    icon={<Thunder color="white" size="14px" />}
                                    style={{ padding: '1px 14px', height: MAGIC_COMPOSER_CTA_BUTTON_HEIGHT }}
                                    onClick={async () => {
                                        if (props.data == null) {
                                            generateTitle({ variables: { data: dataSourcesDescription } });
                                        }
                                        modeAction(CreationMode.Automation);
                                    }}
                                    disabled={!canSave}
                                />
                            )}
                        </Box>
                    }
                />
            )}

            {/* Share to modal */}
            {showShareToModal && (
                <FloatingLayer onOpenChange={(open) => setShowShareToModal(open)} open={showShareToModal}>
                    <MagicComposerShareTo
                        title={templateName}
                        onCloseModal={() => setShowShareToModal(false)}
                        loadingSummary={loadingPreview}
                        loadingTitle={loadingTitle}
                        summaryContent={previewDocContent}
                    />
                </FloatingLayer>
            )}

            {/* Automate modal */}
            {showAutomateModal && (
                <FloatingLayer onOpenChange={(open) => setShowAutomateModal(open)} open={showAutomateModal}>
                    <MagicComposerAutomate
                        title={templateName}
                        label={props.edit ? 'Save changes' : 'Create automation'}
                        automationState={automationState}
                        slackNotifications={slackNotifications}
                        onUpdateAutomation={(automation) => setAutomationState(automation)}
                        onUpdateSlackNotifications={(slackNotifications) => setSlackNotifications(slackNotifications)}
                        onCloseModal={() => setShowAutomateModal(false)}
                        onAutomate={onSaveAutomation}
                        channelMention={slackChannelMention}
                        onChannelMentionChange={setSlackChannelMention}
                        loadingSummary={loadingPreview}
                        loadingTitle={loadingTitle}
                        emailNotications={emailNotifications}
                        onUpdateEmailNotifications={(emailList) => setEmailNotifications(emailList)}
                    />
                </FloatingLayer>
            )}

            {/* Query modal */}
            {showQueryModal && (
                <FloatingLayer onOpenChange={(open) => setShowQueryModal(open)} open={showQueryModal}>
                    <MagicComposerQuery
                        title={templateName}
                        label={props.edit ? 'Save changes' : 'Create saved query'}
                        onCloseModal={() => setShowQueryModal(false)}
                        onSavedQueries={onSaveSavedQueries}
                        loadingSummary={loadingPreview}
                        loadingTitle={loadingTitle}
                    />
                </FloatingLayer>
            )}
        </>
    );
}
