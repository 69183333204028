// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box, Button, Text } from 'grommet';
import styled from 'styled-components';
import { FontFamily } from '../theme';
import { ONBOARD_BG_COLOR } from './OnboardBanner';

const BackgroundSvgWrapper = styled.div<{ bg: string }>`
    background-image: url(${(props) => props.bg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    border-radius: 50%;
    overflow: hidden;
    height: inherit;
    width: inherit;
    align-items: center;
    justify-content: center;
`;

export function OnboardCircleCta(props: { onClick: () => void; emoji: string; text: string; buttonLabel: string }) {
    return (
        <Box align="center" gap="xxsmall">
            <Box
                style={{ borderRadius: '50%' }}
                background={{ color: ONBOARD_BG_COLOR }}
                align="center"
                justify="center"
                width="130px"
                height="130px"
            >
                <BackgroundSvgWrapper bg={`${import.meta.env.VITE_CDN_URL}/images/tw_onboard_bg_pattern.svg`}>
                    <Text size="46px">{props.emoji}</Text>
                </BackgroundSvgWrapper>
            </Box>
            <Text style={{ fontFamily: FontFamily.Callout }} size={'36px'} color={Colors.black}>
                {props.text}
            </Text>
            <Button primary label={props.buttonLabel} onClick={async () => props.onClick()} />
        </Box>
    );
}
