// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box, Spinner, Text } from 'grommet';
import { Close } from 'grommet-icons';
import { useEffect, useMemo, useState } from 'react';
import { AutomationScheduleEvery, SlackChannelMention, SlackTeamChannels } from '../../../../../__generated__/graphql';
import { CtaButtonSpinnerV2 } from '../../../../../components/CtaButtonSpinner';
import { IconButtonV2 } from '../../../../../components/IconButton';
import { FontFamily } from '../../../../../theme';
import { TimeInSchedule } from '../helpers/automation-types';
import { MAGIC_COMPOSER_CTA_BUTTON_HEIGHT } from './MagicComposer';
import { ScheduleRow } from './ScheduleRow';
import {
    SHARE_SECTION_BOX_PROPS,
    SHARE_SECTION_TITLE_PROPS,
    ShareEmail,
    ShareThatWorks,
    ShareTitleSection,
} from './ShareComponents';
import { SlackChannelsCached } from './SlackChannelCached';

export interface AutomationState {
    every: AutomationScheduleEvery;
    time: TimeInSchedule;
    isoDayOfWeek: number;
    dayOfMonth: number;
    workspace: { id: string; label: string } | undefined;
}

export function MagicComposerAutomate(props: {
    automationState: AutomationState;
    slackNotifications: SlackTeamChannels[];
    emailNotications: string[];
    onUpdateAutomation: (automation: AutomationState) => void;
    onUpdateSlackNotifications: (slackNotifications: SlackTeamChannels[]) => void;
    onUpdateEmailNotifications: (emailList: string[]) => void;
    onCloseModal: () => void;
    onAutomate: (title: string) => Promise<void>;
    onChannelMentionChange: (mention: SlackChannelMention) => void;
    channelMention: SlackChannelMention;
    label: string;
    title: string;
    loadingSummary: boolean;
    loadingTitle: boolean;
}): JSX.Element {
    const [title, setTitle] = useState(props.title);
    useEffect(() => {
        setTitle(props.title);
    }, [props.title]);
    const loading = useMemo(
        () => props.loadingSummary || props.loadingTitle,
        [props.loadingTitle, props.loadingSummary],
    );

    return (
        <Box background={Colors.light_2} round="15px" width="600px">
            {loading && <Spinner alignSelf="center" />}
            {!loading && (
                <Box>
                    {/* Title and close button */}
                    <Box direction="row" justify="between" align="center" pad={{ horizontal: 'small', top: 'xsmall' }}>
                        <Text style={{ fontFamily: FontFamily.Callout }} size="23px" weight={'bold'}>
                            Automate
                        </Text>
                        <IconButtonV2
                            icon={(hover) => <Close size="18px" color={hover ? Colors.brand : undefined} />}
                            reverse
                            onClick={props.onCloseModal}
                        />
                    </Box>

                    <Box overflow={{ vertical: 'auto' }}>
                        <Box height={{ min: 'max-content' }}>
                            {/* Post title */}
                            <ShareTitleSection title={title} onChange={setTitle} />

                            {/* Schedule automation */}
                            <Box {...SHARE_SECTION_BOX_PROPS} gap="xxsmall">
                                <Text {...SHARE_SECTION_TITLE_PROPS}>Schedule</Text>
                                <ScheduleRow
                                    every={props.automationState.every}
                                    onEveryChange={(e) =>
                                        props.onUpdateAutomation({ ...props.automationState, every: e })
                                    }
                                    time={props.automationState.time}
                                    onTimeChange={(t) =>
                                        props.onUpdateAutomation({ ...props.automationState, time: t })
                                    }
                                    isoDayOfWeek={props.automationState.isoDayOfWeek}
                                    onDayChange={(d) =>
                                        props.onUpdateAutomation({ ...props.automationState, isoDayOfWeek: d })
                                    }
                                    dayOfMonth={props.automationState.dayOfMonth}
                                    onDayOfMonthChange={(d) =>
                                        props.onUpdateAutomation({ ...props.automationState, dayOfMonth: d })
                                    }
                                />
                            </Box>

                            {/* Publish */}
                            <Box {...SHARE_SECTION_BOX_PROPS}>
                                <Text {...SHARE_SECTION_TITLE_PROPS}>Where to publish</Text>
                                {/* Workspace/Topic */}
                                <ShareThatWorks
                                    workspace={props.automationState.workspace}
                                    onSelected={(s) =>
                                        props.onUpdateAutomation({ ...props.automationState, workspace: s })
                                    }
                                />
                                {/* Share to aka Slack notifications */}
                                <SlackChannelsCached
                                    onSelectionChange={props.onUpdateSlackNotifications}
                                    existingNotifications={props.slackNotifications}
                                    channelMention={props.channelMention}
                                    onChannelMentionChange={props.onChannelMentionChange}
                                />
                                {/* Share email */}
                                <ShareEmail
                                    emails={props.emailNotications}
                                    onEmailsUpdate={props.onUpdateEmailNotifications}
                                />
                            </Box>

                            {/* Save button */}
                            <Box direction="row" pad={{ top: 'xsmall', bottom: 'small', left: 'small' }}>
                                <CtaButtonSpinnerV2
                                    label={props.label}
                                    style={{ padding: '1px 14px', height: MAGIC_COMPOSER_CTA_BUTTON_HEIGHT }}
                                    onClick={() => props.onAutomate(title)}
                                    disabled={props.automationState.workspace == null || title === ''}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
}
