// Copyright 2021
// ThatWorks.xyz Limited

import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { StatsigProvider, StatsigUser, useClientAsyncInit, useStatsigClient } from '@statsig/react-bindings';
import { StatsigAutoCapturePlugin } from '@statsig/web-analytics';
import { Gates, makeStatsigUser } from '@thatworks/shared-frontend/metrics';
import { joinPagesPaths, Pages } from '@thatworks/shared-frontend/pages';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../components/AuthProvider';
import { BasicPage3NavFunction, BasicPage3NavFunctionProps } from '../../components/BasicPage3';
import Loading from '../../components/Loading';
import { SidebarNav } from '../../components/SidebarNav';
import { UserStateProvider, useUserStateContext } from '../../components/UserContext';
import { withAuth } from '../../components/withAuth';
import Api, { AUTH_HEADER } from '../../shared/Api';
import { Environment, getEnvironment } from '../../shared/Environment';
import { ParamKey } from '../../shared/param-keys';
import { FrontendStorageKeys } from '../../storage-keys';
import { NotFound } from '../404';
import ConnectorProcessing from './pages/connect/ConnectorProcessing';
import ConnectV2 from './pages/connect/ConnectV2';
import Data from './pages/data';
import Home from './pages/home';
import InternalSettingsV2 from './pages/internal-settings/InternalSettingsV2';
import MagicComposer from './pages/magic-composer';
import SavedQueries from './pages/saved-queries';
import Templates from './pages/templates';
import { OnboardWelcome } from './pages/welcome/OnboardWelcome';
import Ws from './pages/ws';
import WorkspaceOutlet from './pages/ws/WorkspaceOutlet';

function getLandingPage(signupNux: boolean | undefined, homePageEnabled: boolean): string {
    if (signupNux) {
        return Pages.app.subs.welcome;
    } else if (homePageEnabled) {
        return Pages.app.subs.home;
    } else {
        // If we have a topic Id in the local storage, redirect the user to it
        const topicId = localStorage.getItem(FrontendStorageKeys.LastVisitedTopic);
        if (topicId) {
            return joinPagesPaths([Pages.app.root, Pages.app.subs.topics.root, topicId]);
        }

        // Redirect to the magic composer
        return Pages.app.subs.create;
    }
}

function RedirectToTopic(): JSX.Element {
    const navigate = useNavigate();
    const params = useParams();
    useEffect(() => {
        const workspaceId = params[ParamKey.WorkspaceUuid];
        let path = joinPagesPaths([Pages.app.root, Pages.app.subs.topics.root]);
        path = workspaceId != null ? `${path}/${workspaceId}` : path;
        navigate(path);
    });
    return <></>;
}

function AppRoutes(props: { signupNux: boolean | undefined }): JSX.Element {
    const navigate = useNavigate();
    const { logout } = useAuth();
    const { checkGate } = useStatsigClient();

    const handleNav: BasicPage3NavFunction = useCallback(
        (to: BasicPage3NavFunctionProps) => {
            switch (to.nav) {
                case SidebarNav.Connect:
                    navigate(joinPagesPaths([Pages.app.root, Pages.app.subs.connect.root]));
                    break;
                case SidebarNav.Data:
                    navigate(joinPagesPaths([Pages.app.root, Pages.app.subs.data]));
                    break;
                case SidebarNav.Automations:
                    navigate(joinPagesPaths([Pages.app.root, Pages.app.subs.automations]));
                    break;
                case SidebarNav.InternalSettings:
                    navigate(joinPagesPaths([Pages.app.root, Pages.app.subs.internalSettings]));
                    break;
                case SidebarNav.Logout:
                    logout();
                    break;
                case SidebarNav.Create:
                    navigate(joinPagesPaths([Pages.app.root, Pages.app.subs.create]));
                    break;
                case SidebarNav.SavedQueries:
                    navigate(joinPagesPaths([Pages.app.root, Pages.app.subs.savedQueries]));
                    break;
                case SidebarNav.Home:
                    navigate(joinPagesPaths([Pages.app.root, Pages.app.subs.home]));
                    break;
            }
        },
        [logout, navigate],
    );

    return (
        <Routes>
            <Route
                path="/"
                element={<Navigate to={getLandingPage(props.signupNux, checkGate(Gates.HomePage))} replace />}
            />

            <Route path={Pages.app.subs.connect.root}>
                <Route path={Pages.app.subs.connect.subs.process} element={<ConnectorProcessing onNav={handleNav} />} />
                <Route index element={<ConnectV2 onboarding={props.signupNux || false} onNav={handleNav} />} />
            </Route>
            <Route path={Pages.app.subs.data} element={<Data onNav={handleNav} />} />
            <Route path={Pages.app.subs.automations} element={<Templates onNav={handleNav} />} />
            <Route path={Pages.app.subs.internalSettings} element={<InternalSettingsV2 onNav={handleNav} />} />
            <Route path={Pages.app.subs.welcome} element={<OnboardWelcome onNav={handleNav} />} />
            <Route path={Pages.app.subs.workspaces}>
                <Route path={`:${ParamKey.WorkspaceUuid}/*`} element={<RedirectToTopic />} />
                <Route index element={<RedirectToTopic />} />
            </Route>
            <Route path={Pages.app.subs.topics.root} element={<Ws onNav={handleNav} />}>
                <Route path={`:${ParamKey.WorkspaceUuid}/*`} element={<WorkspaceOutlet />} />
                <Route index element={<WorkspaceOutlet />} />
            </Route>
            <Route path={Pages.app.subs.create} element={<MagicComposer onNav={handleNav} />} />
            <Route path={Pages.app.subs.savedQueries} element={<SavedQueries onNav={handleNav} />} />
            <Route path={Pages.app.subs.home} element={<Home onNav={handleNav} />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}

function UserMetadataWrapper(): JSX.Element {
    const { userMetadata, stateContextInitialized } = useUserStateContext();
    const { isLoading } = useAuth();

    if (isLoading() || !stateContextInitialized) {
        return <Loading />;
    }

    return <AppRoutes signupNux={userMetadata.signupNux} />;
}

const errorLink = onError(({ graphQLErrors }) => {
    const unauth = graphQLErrors?.find((e) => e.extensions && e.extensions.code === 'UNAUTHENTICATED');
    if (unauth) {
        // redirect to login page
        window.location.href = joinPagesPaths([Pages.auth.root, Pages.auth.subs.login]);
    }
});

function StatsigWrapper(props: { user: StatsigUser }): JSX.Element {
    const { getAccessTokenSilently } = useAuth();
    const { client, isLoading } = useClientAsyncInit(import.meta.env.VITE_STATSIG_KEY || '', props.user, {
        networkConfig: {
            api: Api.getStatsigProxyEndpoint(),
        },
        environment: { tier: getEnvironment() as string },
        plugins: [new StatsigAutoCapturePlugin()],
    });

    const apolloClient = useMemo(() => {
        return new ApolloClient({
            cache: new InMemoryCache(),
            connectToDevTools: getEnvironment() === Environment.Development,
        });
    }, []);

    // the link is setup separately to avoid re-rendering the apollo client
    // every time the access token changes
    useEffect(() => {
        const link = setContext(async (_, { headers }) => {
            const tokens = await getAccessTokenSilently();
            const newHeaders: Record<string, string> = {
                ...headers,
            };
            newHeaders[AUTH_HEADER] = `Bearer ${tokens}`;
            return {
                headers: newHeaders,
            };
        })
            .concat(errorLink)
            .concat(
                createHttpLink({
                    uri: Api.getGraphQlEndpoint(),
                }),
            );
        apolloClient.setLink(link);
    }, [apolloClient, getAccessTokenSilently]);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <StatsigProvider client={client}>
            <ApolloProvider client={apolloClient}>
                <UserStateProvider>
                    <UserMetadataWrapper />
                </UserStateProvider>
            </ApolloProvider>
        </StatsigProvider>
    );
}

function ThatWorksApp(): JSX.Element {
    const { getUserInfo, getUserId, isLoading } = useAuth();

    const [userAuthDetails, setUserAuthDetails] = useState<{ emailDomain: string } | undefined>();
    const [organizationId, setOrganizationId] = useState<string | undefined>();
    const [userId, setUserId] = useState<string | undefined>();

    useEffect(() => {
        if (isLoading()) {
            return;
        }

        (async () => {
            const [info, _userId] = await Promise.all([getUserInfo(), getUserId()]);

            setUserAuthDetails({
                emailDomain: info.emailDomain,
            });
            setOrganizationId(info.orgId);
            setUserId(_userId);
        })();
    }, [getUserId, getUserInfo, isLoading]);

    if (isLoading() || !userId || !userAuthDetails) {
        return <Loading />;
    }

    return (
        <StatsigWrapper
            user={makeStatsigUser({
                userId,
                emailDomain: userAuthDetails.emailDomain,
                userAgent: window.navigator.userAgent,
                organizationId: organizationId || '',
            })}
        />
    );
}

export default withAuth(ThatWorksApp);
