// Copyright 2021
// ThatWorks.xyz Limited

import { autoPlacement, autoUpdate, Placement, useDismiss, useFloating, useInteractions } from '@floating-ui/react';
import { Colors } from '@thatworks/colors';
import {
    Box,
    BoxExtendedProps,
    Button,
    CheckBox,
    RadioButton,
    RadioButtonExtendedProps,
    Spinner,
    Text,
    TextExtendedProps,
} from 'grommet';
import { FormDown, IconProps, Search } from 'grommet-icons';
import React, { useEffect, useState } from 'react';
import { FontFamily } from '../theme';
import { SimpleBorderTextButton } from './FilterDropdown';
import { PlainTextInput } from './PlainTextInput';

function RadioButtonOrCheckBox(props: { type: 'radio' | 'checkbox' } & RadioButtonExtendedProps): JSX.Element {
    if (props.type === 'radio') {
        return <RadioButton {...props} />;
    }
    return <CheckBox {...props} />;
}

export function DropdownMenuChecklist(props: {
    label?: React.ReactNode | null;
    options: { label: string; id: string; icon?: JSX.Element }[];
    selected: { label: string; id: string }[];
    searchText?: string;
    singleSelectionOnly?: boolean;
    hideSearch?: boolean;
    afterChecklistComponent?: React.ReactNode;
    beforeChecklistComponent?: React.ReactNode;
    showButtonsInsteadOfCheckboxes?: boolean;
    closeMenuOnSelection?: boolean;
    onSelectionChange: (sel: { label: string; id: string }[]) => void;
    onCloseMenu: () => void;
}): JSX.Element | null {
    const [listOptions, setListOptions] = useState(props.options);
    const [searchText, setSearchText] = useState<string>();
    const [selected, setSelected] = useState<{ label: string; id: string }[]>(props.selected);

    useEffect(() => {
        if (!searchText) {
            setListOptions(props.options);
            return;
        }

        // The line below escapes regular expression special characters:
        // [ \ ^ $ . | ? * + ( )
        const escapedText = searchText.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
        // Create the regular expression with modified value which
        // handles escaping special characters. Without escaping special
        // characters, errors will appear in the console
        const searchExp = new RegExp(escapedText, 'i');
        if (escapedText.length > 1) {
            const filtered = props.options.filter((v) => searchExp.test(v.label));
            setListOptions(filtered);
        } else {
            setListOptions(props.options);
        }
    }, [props.options, searchText]);

    return (
        <Box>
            {!props.hideSearch && (
                <Box
                    pad={{ horizontal: 'small', vertical: '10px' }}
                    direction="row"
                    align="left"
                    border={{ color: Colors.border_dark, size: '1px', side: 'bottom' }}
                    key="omni-search-text-input"
                    height={{ min: 'unset' }}
                >
                    <Box pad={{ vertical: '6px', right: '6px' }}>
                        <Search size="16px" />
                    </Box>
                    <PlainTextInput
                        style={{ width: '240px', background: Colors.background_front }}
                        fontSize="16px"
                        onChange={(e) => setSearchText(e.target.value)}
                        placeholder="Search or select from below"
                    />
                </Box>
            )}
            <Box overflow={{ vertical: 'auto' }}>
                <Box gap="xsmall" height={{ min: 'min-content' }}>
                    {props.label}
                    <Box
                        gap="xsmall"
                        pad={{ horizontal: 'small', top: props.label ? undefined : 'xsmall', bottom: 'small' }}
                        height={{ min: 'min-content' }}
                    >
                        {props.beforeChecklistComponent}
                        {!props.showButtonsInsteadOfCheckboxes &&
                            listOptions.map((v, vi) => (
                                <RadioButtonOrCheckBox
                                    type={props.singleSelectionOnly ? 'radio' : 'checkbox'}
                                    key={`check-${vi}`}
                                    name={v.label}
                                    style={{ textTransform: 'capitalize' }}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            if (props.singleSelectionOnly) {
                                                setSelected([v]);
                                                props.onSelectionChange([v]);
                                            } else {
                                                selected.push(v);
                                                const copy = [...selected];
                                                setSelected(copy);
                                                props.onSelectionChange(copy);
                                            }
                                        } else {
                                            if (props.singleSelectionOnly) {
                                                setSelected([]);
                                                props.onSelectionChange([]);
                                            } else {
                                                const filtered = selected.filter((s) => s.id !== v.id);
                                                setSelected([...filtered]);
                                                props.onSelectionChange(filtered);
                                            }
                                        }
                                        if (props.closeMenuOnSelection) {
                                            props.onCloseMenu();
                                        }
                                    }}
                                    checked={selected.find((s) => s.id === v.id) !== undefined}
                                    label={
                                        v.icon ? (
                                            <Box direction="row" align="center" gap="xxsmall">
                                                {v.icon}
                                                <Text size="xxsmall">{v.label}</Text>
                                            </Box>
                                        ) : (
                                            <Text size="xxsmall">{v.label}</Text>
                                        )
                                    }
                                />
                            ))}
                        {props.showButtonsInsteadOfCheckboxes &&
                            listOptions.map((v, vi) => (
                                <SimpleBorderTextButton
                                    key={`check-${vi}`}
                                    border={{ color: 'unset' }}
                                    margin={{}}
                                    pad={{}}
                                    onClick={() => {
                                        props.onSelectionChange([v]);
                                        props.onCloseMenu();
                                    }}
                                    render={(hover) =>
                                        v.icon ? (
                                            <Box direction="row" align="center">
                                                {v.icon}
                                                <Text size="xxsmall" color={hover ? Colors.brand : undefined}>
                                                    {v.label}
                                                </Text>{' '}
                                            </Box>
                                        ) : (
                                            <Text size="xxsmall" color={hover ? Colors.brand : undefined}>
                                                {v.label}
                                            </Text>
                                        )
                                    }
                                    boxProps={{
                                        background: Colors.light_2,
                                        pad: { horizontal: '6px', vertical: '4px' },
                                    }}
                                />
                            ))}
                        {props.afterChecklistComponent}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export function DropdownMenuComponent(props: {
    label: string;
    dropMenuLabel?: React.ReactNode | null;
    options: { label: string; id: string; icon?: JSX.Element }[];
    selected: { label: string; id: string }[];
    singleSelectionOnly?: boolean;
    onSelectionChange: (sel: { label: string; id: string }[]) => void;
    boxProps?: BoxExtendedProps;
    hideSearch?: boolean;
    afterChecklistComponent?: React.ReactNode;
    beforeChecklistComponent?: React.ReactNode;
    getTextProps?: (hover: boolean) => TextExtendedProps;
    getIconProps?: (hover: boolean) => IconProps;
    showButtonsInsteadOfCheckboxes?: boolean;
    iconSize?: string;
    outerBoxProps?: BoxExtendedProps;
    buttonCss?: React.CSSProperties;
    disabled?: boolean;
    showLoadingSpinner?: boolean;
    dropPlacement?: Placement[];
    closeMenuOnSelection?: boolean;
}): JSX.Element {
    const [showDrop, setShowDrop] = useState(false);
    const [selectedCount, setSelectedCount] = useState(0);

    const { refs, floatingStyles, context } = useFloating({
        whileElementsMounted: autoUpdate,
        middleware: [
            autoPlacement({
                allowedPlacements: props.dropPlacement || ['bottom-start', 'top-start'],
            }),
        ],
        open: showDrop,
        onOpenChange: setShowDrop,
    });

    const dismiss = useDismiss(context);
    const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

    return (
        <Box {...props.outerBoxProps}>
            <Button
                onClick={() => {
                    setShowDrop(true);
                }}
                disabled={props.disabled || props.showLoadingSpinner}
                plain
                style={props.buttonCss}
            >
                {({ hover, disabled }) => (
                    <Box
                        direction="row"
                        gap="4px"
                        align="center"
                        justify="center"
                        pad={{ horizontal: 'xxsmall', vertical: 'xxsmall' }}
                        border={{
                            color:
                                (!disabled && hover) || showDrop ? 'brand' : selectedCount > 0 ? 'neutral-1' : 'dark-4',
                            size: (!disabled && hover) || showDrop ? '3px' : '2px',
                        }}
                        margin={(!disabled && hover) || showDrop ? '1px' : '2px'}
                        round="8px"
                        ref={refs.setReference}
                        {...getReferenceProps()}
                        {...props.boxProps}
                    >
                        <Text
                            style={{ fontFamily: FontFamily.Mono }}
                            size={'small'}
                            truncate
                            {...(props.getTextProps && props.getTextProps(hover))}
                        >
                            {props.label}
                        </Text>
                        {!props.showLoadingSpinner && (
                            <FormDown
                                size={props.iconSize || '16px'}
                                {...(props.getIconProps && props.getIconProps(hover))}
                            />
                        )}
                        {props.showLoadingSpinner && <Spinner size="1px" width="14px" height="14px" />}
                    </Box>
                )}
            </Button>
            {showDrop && (
                <div ref={refs.setFloating} style={{ ...floatingStyles, zIndex: 1000 }} {...getFloatingProps()}>
                    <Box
                        height={{ max: '50vh' }}
                        background="background-front"
                        border={{ color: Colors.border_dark, size: '1px' }}
                        round="5px"
                        width={{ min: '200px' }}
                        margin={{ vertical: 'xxsmall', bottom: 'xsmall' }}
                    >
                        <DropdownMenuChecklist
                            label={props.dropMenuLabel}
                            options={props.options}
                            onSelectionChange={(sel) => {
                                if (!props.showButtonsInsteadOfCheckboxes) {
                                    setSelectedCount(sel.length);
                                }
                                props.onSelectionChange(sel);
                            }}
                            selected={props.selected}
                            singleSelectionOnly={props.singleSelectionOnly}
                            hideSearch={props.hideSearch}
                            afterChecklistComponent={props.afterChecklistComponent}
                            beforeChecklistComponent={props.beforeChecklistComponent}
                            showButtonsInsteadOfCheckboxes={props.showButtonsInsteadOfCheckboxes}
                            onCloseMenu={() => setShowDrop(false)}
                            closeMenuOnSelection={props.closeMenuOnSelection}
                        />
                    </Box>
                </div>
            )}
        </Box>
    );
}

export function DropdownMenuButtonComponent(props: {
    label: string;
    options: React.ReactNode[];
    labelWeight?: 'bold' | 'normal';
    labelColor?: string;
    underlineLabel?: boolean;
}): JSX.Element {
    const [showDrop, setShowDrop] = useState(false);

    const { refs, floatingStyles, context } = useFloating({
        whileElementsMounted: autoUpdate,
        middleware: [
            autoPlacement({
                allowedPlacements: ['bottom-start'],
            }),
        ],
        open: showDrop,
        onOpenChange: setShowDrop,
    });

    const dismiss = useDismiss(context);
    const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

    return (
        <Box>
            <Button
                onClick={() => {
                    setShowDrop(true);
                }}
                plain
                alignSelf="center"
            >
                {({ hover }) => (
                    <Box
                        direction="row"
                        gap="4px"
                        align="center"
                        justify="center"
                        ref={refs.setReference}
                        {...getReferenceProps()}
                    >
                        <Box
                            border={props.underlineLabel ? { color: 'brand', side: 'bottom' } : undefined}
                            margin={props.underlineLabel ? undefined : { bottom: '1px' }}
                        >
                            <Text
                                style={{ fontFamily: FontFamily.Standard }}
                                size="16px"
                                color={hover ? 'brand' : props.labelColor}
                                weight={props.labelWeight}
                            >
                                {props.label}
                            </Text>
                        </Box>
                        <FormDown size="16px" />
                    </Box>
                )}
            </Button>
            {showDrop && (
                <div ref={refs.setFloating} style={{ ...floatingStyles, zIndex: 1000 }} {...getFloatingProps()}>
                    <Box
                        overflow="auto"
                        background="background-front"
                        border={{ color: 'brand', size: '2px' }}
                        round="5px"
                        width={{ min: 'medium' }}
                        margin={{ vertical: 'xxsmall', bottom: 'xsmall' }}
                    >
                        {props.options.map((v) => v)}
                    </Box>
                </div>
            )}
        </Box>
    );
}
