// Copyright 2021
// ThatWorks.xyz Limited

import { useAuth0 } from '@auth0/auth0-react';
import { useClerk, useOrganization, useSession } from '@clerk/clerk-react';
import { ActiveSessionResource } from '@clerk/types';
import {
    CustomTokenClaimKeys,
    parseCustomTokenClaim,
    TokenClaimUserMetadata,
} from '@thatworks/shared-frontend/token-claims';
import auth0 from 'auth0-js';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { AUTH0_SCOPES } from '../shared/auth0-props';

export enum AuthProvider {
    Auth0 = 'auth0',
    Clerk = 'clerk',
}

const AUTH_PROVIDER_KEY = 'auth_provider';

interface UserInfo {
    emailDomain: string;
    metadata: { signupNux: boolean };
    orgName: string;
    orgId: string;
    firstName: string | undefined;
    lastName: string | undefined;
    emailAddress: string | undefined;
}

interface AuthProviderState {
    isAuthenticated: () => boolean;
    hasError: () => Error | undefined;
    getUserId: () => Promise<string>;
    getAccessTokenSilently: (auth0ApiAccess?: boolean) => Promise<string>;
    getAccessTokenWithPopup: () => Promise<string | undefined>;
    logout: () => Promise<void>;
    getUserInfo: () => Promise<UserInfo>;
    isLoading: () => boolean;
    updateUserMetadata: (newMetadata: TokenClaimUserMetadata) => Promise<TokenClaimUserMetadata>;
    getProvider(): AuthProvider;
}

// Session storage is used to determine which auth provider to use
// The value is set from the login page of the respective provider
export function setAuthProviderInSessionStorage(provider: AuthProvider): void {
    sessionStorage.setItem(AUTH_PROVIDER_KEY, provider);
}

export function getAuthProviderFromSessionStorage(): AuthProvider {
    return (sessionStorage.getItem(AUTH_PROVIDER_KEY) as AuthProvider) || AuthProvider.Clerk;
}

const defaultProviderState: AuthProviderState = {
    isAuthenticated: () => false,
    hasError: () => undefined,
    getUserId: async () => {
        throw new Error('Not implemented');
    },
    getAccessTokenSilently: async () => {
        throw new Error('Not implemented');
    },
    getAccessTokenWithPopup: async () => {
        throw new Error('Not implemented');
    },
    logout: async () => {
        throw new Error('Not implemented');
    },
    getUserInfo: async () => {
        throw new Error('Not implemented');
    },
    isLoading: () => false,
    updateUserMetadata: async () => {
        throw new Error('Not implemented');
    },
    getProvider: () => {
        throw new Error('Not implemented');
    },
};

export const AuthProviderContext = createContext<AuthProviderState>(defaultProviderState);
export const useAuth = () => useContext(AuthProviderContext);

function getClerkSessionOrThrow(session: ActiveSessionResource | null | undefined): ActiveSessionResource {
    if (!session) {
        throw new Error('User is not signed in');
    }
    return session;
}

export function AuthStateProvider(props: {
    children: React.ReactNode;
    auth0: { domain: string; audience: string };
}): JSX.Element {
    // -- Auth0
    const {
        isAuthenticated: auth0IsAuthenticated,
        getIdTokenClaims: auth0GetIdTokenClaims,
        getAccessTokenSilently: auth0GetAccessTokenSilently,
        getAccessTokenWithPopup: auth0GetAccessTokenWithPopup,
        logout: auth0Logout,
        isLoading: auth0IsLoading,
        user: auth0User,
        error: auth0Error,
    } = useAuth0();

    // -- Clerk
    const clerk = useClerk();
    const { isLoaded: clerkIsLoaded, isSignedIn: clerkIsSignedIn, session: clerkSession } = useSession();
    const { organization: clerkOrg } = useOrganization();

    const [authProvider] = useState<AuthProvider>(getAuthProviderFromSessionStorage());

    // -- Implementation
    const isAuthenticated = useCallback((): boolean => {
        if (authProvider === AuthProvider.Clerk) {
            return clerkIsSignedIn || false;
        }
        return auth0IsAuthenticated;
    }, [authProvider, auth0IsAuthenticated, clerkIsSignedIn]);

    const getUserInfo = useCallback(async (): Promise<UserInfo> => {
        if (authProvider === AuthProvider.Clerk) {
            const user = getClerkSessionOrThrow(clerkSession).user;
            const emailAddress = user.primaryEmailAddress;
            if (!emailAddress) {
                throw new Error('Clerk: No email address found');
            }
            const signupNux = user.unsafeMetadata.signupNux as boolean | undefined;
            if (signupNux == null) {
                throw new Error('Clerk: No signupNux metadata found');
            }
            if (!clerkOrg) {
                throw new Error('Clerk: No organization found');
            }
            const orgExternalId = clerkOrg.publicMetadata.externalId as string | undefined;
            return {
                emailDomain: emailAddress.emailAddress.split('@')[1],
                metadata: {
                    signupNux,
                },
                orgName: clerkOrg.name,
                orgId: orgExternalId || clerkOrg.id,
                firstName: user.firstName || undefined,
                lastName: user.lastName || undefined,
                emailAddress: emailAddress.emailAddress,
            };
        }
        const tokenClaims = await auth0GetIdTokenClaims();
        if (!tokenClaims) {
            throw new Error('Auth0: No token claims found');
        }
        const emailDomain = parseCustomTokenClaim<string>(tokenClaims, CustomTokenClaimKeys.EmailDomain);
        if (!emailDomain) {
            throw new Error('Auth0: No email domain found');
        }
        const metadata = parseCustomTokenClaim<TokenClaimUserMetadata>(tokenClaims, CustomTokenClaimKeys.UserMetadata);
        if (!metadata || metadata.signupNux == null) {
            throw new Error('Auth0: No metadata found');
        }
        const orgName = parseCustomTokenClaim<string>(tokenClaims, CustomTokenClaimKeys.OrganizationDisplayName);
        if (!orgName) {
            throw new Error('Auth0: No organization name found');
        }
        const orgId = parseCustomTokenClaim<string>(tokenClaims, CustomTokenClaimKeys.OrganizationId);
        if (!orgId) {
            throw new Error('Auth0: No organization id found');
        }

        return {
            emailDomain,
            metadata: {
                signupNux: metadata.signupNux,
            },
            orgName,
            orgId,
            firstName: undefined,
            lastName: undefined,
            emailAddress: undefined,
        };
    }, [authProvider, auth0GetIdTokenClaims, clerkSession, clerkOrg]);

    const getAccessTokenSilently = useCallback(
        async (auth0ApiAccess?: boolean): Promise<string> => {
            if (authProvider === AuthProvider.Clerk) {
                const token = await getClerkSessionOrThrow(clerkSession).getToken();
                if (!token) {
                    throw new Error('No session token found');
                }
                return token;
            }
            return auth0GetAccessTokenSilently(
                auth0ApiAccess
                    ? {
                          cacheMode: 'off',
                          authorizationParams: {
                              audience: 'https://api.thatworks.ai',
                              scope: 'read:all write:all',
                          },
                      }
                    : undefined,
            );
        },
        [authProvider, auth0GetAccessTokenSilently, clerkSession],
    );

    const getAccessTokenWithPopup = useCallback(async (): Promise<string | undefined> => {
        if (authProvider === AuthProvider.Clerk) {
            const token = await getClerkSessionOrThrow(clerkSession).getToken();
            if (!token) {
                throw new Error('No session token found');
            }
            return token;
        }
        return auth0GetAccessTokenWithPopup({
            cacheMode: 'off',
            authorizationParams: {
                audience: 'https://api.thatworks.ai',
                scope: 'read:all write:all',
            },
        });
    }, [authProvider, auth0GetAccessTokenWithPopup, clerkSession]);

    const logout = useCallback(async (): Promise<void> => {
        if (authProvider === AuthProvider.Clerk) {
            await clerk.signOut();
        } else {
            await auth0Logout();
        }
        sessionStorage.removeItem(AUTH_PROVIDER_KEY);
    }, [authProvider, clerk, auth0Logout]);

    const isLoading = useCallback(() => {
        if (authProvider === AuthProvider.Clerk) {
            if (!clerkSession) {
                return true;
            }
            return !clerkIsLoaded;
        }
        return auth0IsLoading;
    }, [auth0IsLoading, authProvider, clerkIsLoaded, clerkSession]);

    const getUserId = useCallback(async (): Promise<string> => {
        if (authProvider === AuthProvider.Clerk) {
            const session = getClerkSessionOrThrow(clerkSession);
            // external id for backward compatibility with auth0 user ids
            return session.user.externalId || session.user.id;
        }

        if (!auth0IsAuthenticated) {
            throw new Error('Auth0: User is not authenticated');
        }

        if (!auth0User) {
            throw new Error('Auth0: User object is invalid');
        }

        if (!auth0User.sub) {
            throw new Error('Auth0: User sub is invalid');
        }

        return auth0User.sub;
    }, [authProvider, auth0IsAuthenticated, auth0User, clerkSession]);

    const getProvider = useCallback(() => {
        return authProvider;
    }, [authProvider]);

    const updateUserMetadata = useCallback(
        async (newMetadata: TokenClaimUserMetadata): Promise<TokenClaimUserMetadata> => {
            const provider = getProvider();
            if (provider === AuthProvider.Clerk) {
                await getClerkSessionOrThrow(clerkSession).user.update({
                    unsafeMetadata: newMetadata as { [k: string]: unknown },
                });
            } else {
                const [token, userId] = await Promise.all([getAccessTokenSilently(), getUserId()]);
                await new Promise<auth0.Auth0UserProfile>((resolve, reject) => {
                    const mgmt = new auth0.Management({
                        domain: props.auth0.domain,
                        audience: props.auth0.audience,
                        token,
                        scope: AUTH0_SCOPES,
                    });
                    mgmt.patchUserMetadata(userId, newMetadata, (error, res) => {
                        if (error) {
                            return reject(error);
                        }
                        return resolve(res);
                    });
                });
            }
            return newMetadata;
        },
        [clerkSession, getAccessTokenSilently, getProvider, getUserId, props.auth0.audience, props.auth0.domain],
    );

    const hasError = useCallback(() => {
        return auth0Error;
    }, [auth0Error]);

    const contextValue = useMemo<AuthProviderState>(() => {
        return {
            isAuthenticated,
            hasError,
            getUserId,
            getAccessTokenSilently,
            getAccessTokenWithPopup,
            logout,
            isLoading,
            getUserInfo,
            getProvider,
            updateUserMetadata,
        };
    }, [
        isAuthenticated,
        hasError,
        getUserId,
        getAccessTokenSilently,
        getAccessTokenWithPopup,
        logout,
        isLoading,
        getUserInfo,
        getProvider,
        updateUserMetadata,
    ]);

    return <AuthProviderContext.Provider value={contextValue}>{props.children}</AuthProviderContext.Provider>;
}
