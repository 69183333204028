// Copyright 2021
// ThatWorks.xyz Limited

import { Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';
import { ClerkProvider } from '@clerk/clerk-react';
import { Colors } from '@thatworks/colors';
import { joinPagesPaths, Pages, WebsitePages } from '@thatworks/shared-frontend/pages';
import { Anchor, Box, Grommet, Text } from 'grommet';
import { StatusWarning } from 'grommet-icons';
import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { AuthProvider, AuthStateProvider, useAuth } from './components/AuthProvider';
import Loading from './components/Loading';
import TwIcon from './icons/tw_icon.svg?react';
import { NotFound } from './pages/404';
import ThatWorksApp from './pages/app';
import Auth from './pages/auth';
import { ConnectPopupOAuthComplete, ConnectPopupWithAuth } from './pages/connector-oauth/ConnectorOauth';
import Public from './pages/public';
import { Tester } from './pages/tester';
import { getMainWebsitePageUrl } from './shared/Api';
import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN, AUTH0_SCOPES } from './shared/auth0-props';
import { FontFamily, getGrommetTheme, getThemeColors } from './theme';

const colors = getThemeColors();

function AppRoutes() {
    const { isLoading, logout, hasError, getProvider } = useAuth();

    // Only for auth0: intermediate code required to check login status
    if (isLoading() && getProvider() === AuthProvider.Auth0) {
        return (
            <Box fill align="center" justify="center" pad="medium">
                {isLoading() && <Loading />}
                {hasError() && (
                    <Box gap="xsmall" align="center" justify="center">
                        <Box
                            border={{ color: 'border', size: '2px' }}
                            style={{ borderRadius: '50%' }}
                            width="50px"
                            height="50px"
                        >
                            <TwIcon style={{ alignSelf: 'center' }} width="30px" fill={Colors.brand} />
                        </Box>
                        <Box align="center" justify="center" pad="small" gap="xsmall">
                            <Box
                                pad="xxsmall"
                                background={{ color: 'status-warning', opacity: 'weak' }}
                                round="10px"
                                gap="xxsmall"
                                justify="center"
                                align="center"
                            >
                                <Box direction="row" gap="4px" align="center">
                                    <StatusWarning size="20px" />{' '}
                                    <Text weight={'bold'} size="20px">
                                        Failed
                                    </Text>
                                </Box>
                                <Text textAlign="center">{hasError()?.message}</Text>
                            </Box>
                            <Text textAlign="center">
                                <Anchor onClick={() => logout()}>Reset and return</Anchor> to the login page.
                            </Text>
                        </Box>
                    </Box>
                )}
            </Box>
        );
    }

    return (
        <Routes>
            <Route path={`${Pages.auth.root}/*`} element={<Auth />} />
            <Route path={`${Pages.app.root}/*`} element={<ThatWorksApp />} />
            <Route path={`${Pages.tester}/*`} element={<Tester />} />
            <Route path={`${Pages.connector_oauth.root}`}>
                <Route path={Pages.connector_oauth.subs.oauthComplete} element={<ConnectPopupOAuthComplete />} />
                <Route index element={<ConnectPopupWithAuth />} />
            </Route>
            <Route path={`${Pages.public.root}/*`} element={<Public />} />
            <Route path="/" element={<Navigate to={Pages.app.root} replace />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}

function Auth0ProviderWithRedirectCallback(props: {
    children: React.ReactNode;
    auth0: Auth0ProviderOptions;
}): JSX.Element {
    const navigate = useNavigate();
    return (
        <Auth0Provider
            onRedirectCallback={(appState) => {
                if (appState && appState.returnTo) {
                    navigate(appState.returnTo);
                }
            }}
            {...props.auth0}
        >
            {props.children}
        </Auth0Provider>
    );
}

function App(): JSX.Element {
    return (
        <Grommet theme={getGrommetTheme()} full>
            <BrowserRouter>
                <Auth0ProviderWithRedirectCallback
                    auth0={{
                        clientId: AUTH0_CLIENT_ID,
                        domain: AUTH0_DOMAIN,
                        authorizationParams: {
                            redirect_uri: `${window.location.origin}`,
                            scope: AUTH0_SCOPES,
                            audience: AUTH0_AUDIENCE,
                            connection: 'email',
                        },
                        useRefreshTokens: true,
                        useRefreshTokensFallback: true,
                    }}
                >
                    <ClerkProvider
                        publishableKey={import.meta.env.VITE_CLERK_PUBLISHABLE_KEY}
                        afterSignOutUrl={`${joinPagesPaths([Pages.auth.root, Pages.auth.subs.login])}?v=2`}
                        signInUrl={`${joinPagesPaths([Pages.auth.root, Pages.auth.subs.login])}?v=2`}
                        signUpUrl={`${joinPagesPaths([Pages.auth.root, Pages.auth.subs.signup])}`}
                        telemetry={false}
                    >
                        <AuthStateProvider
                            auth0={{
                                domain: AUTH0_DOMAIN,
                                audience: AUTH0_AUDIENCE,
                            }}
                        >
                            <CookieConsent
                                style={{
                                    transform: 'translate(-50%, -20%)',
                                    width: '470px',
                                    maxWidth: '85vw',
                                    left: '50%',
                                    borderRadius: '5px',
                                    background: '#290C2F',
                                    filter: `drop-shadow(1px 1px 2px ${colors['dark-1']?.toString()})`,
                                    paddingTop: `10px`,
                                    paddingBottom: '10px',
                                    paddingRight: '15px',
                                    paddingLeft: '15px',
                                }}
                                contentStyle={{ flex: undefined, margin: undefined }}
                                buttonText={
                                    <Text size="12px" style={{ fontFamily: FontFamily.Mono }} color="black">
                                        Accept
                                    </Text>
                                }
                                buttonStyle={{
                                    borderRadius: '5px',
                                    margin: '5px 0px 5px 0px',
                                    background: colors['light-3']?.toString(),
                                }}
                            >
                                <Text size="12px">
                                    We use cookies only for essential functionality. See our{' '}
                                    <a
                                        href={getMainWebsitePageUrl(WebsitePages.cookiePolicy)}
                                        style={{ color: 'white' }}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Cookie Policy
                                    </a>
                                    .
                                </Text>
                            </CookieConsent>
                            <AppRoutes />
                        </AuthStateProvider>
                    </ClerkProvider>
                </Auth0ProviderWithRedirectCallback>
            </BrowserRouter>
        </Grommet>
    );
}

export default App;
