// Copyright 2021
// ThatWorks.xyz Limited

import { useLazyQuery, useMutation } from '@apollo/client';
import { Colors } from '@thatworks/colors';
import { ConnectorName, getFormattedConnectorName } from '@thatworks/connector-api';
import {
    AsanaResourceType,
    BitbucketObjectTypes,
    ClickupObjectTypes,
    FigmaObjectTypes,
    GdriveObjectTypes,
    GithubObjectTypes,
    GitlabObjectTypes,
    HubspotObjectType,
    JiraObjectTypes,
    LinearObjectTypes,
    MiroObjectTypes,
    MondayObjectType,
    SlackObjectTypes,
    TrelloObjectType,
} from '@thatworks/connector-changes/connector-object-types';
import { FilterHierarchyType } from '@thatworks/shared-frontend/collections';
import { Box, CheckBox, Select, Spinner, Text } from 'grommet';
import { CaretDownFill, Close, FormClose, Info, Search } from 'grommet-icons';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { gql } from '../../../../../__generated__';
import {
    ConnectedAppsFilter,
    ConnectedAppsOption,
    ConnectorScope,
    ConnectorScopeInput,
    ConnectorWideScopeInput,
    ItemTypesOption,
} from '../../../../../__generated__/graphql';
import { ConnectorIconSmall } from '../../../../../components/ConnectorIcon';
import { IconButtonV2, RoundIconButton } from '../../../../../components/IconButton';
import { PlainTextInput } from '../../../../../components/PlainTextInput';
import { InspectorCardTag } from '../../../../../components/SummaryComponents';
import { useTelemetryContext } from '../../../../../components/TelemetryContext';
import { TwHeading } from '../../../../../components/TwHeading';
import { useUserStateContext } from '../../../../../components/UserContext';
import { FontFamily } from '../../../../../theme';

const GET_CONNECTORS_SCOPE_FROM_URL = gql(/* GraphQL */ `
    mutation ConnectorsScopeFromUrl($url: String!) {
        connectorsScopeFromUrl(url: $url) {
            id
            connector
            itemName
            itemType
            itemUuid
            hierarchyType
            parentName
        }
    }
`);

export const FILTER_DATA_SOURCES_INPUT_ID = 'filter_data_sources_input';

const ITEM_SEARCH = gql(/* GraphQL */ `
    query ConnectorsScopeSearch($query: String!, $connectedAppsFilter: ConnectedAppsFilter!) {
        connectorsScopeSearch(query: $query, connectedAppsFilter: $connectedAppsFilter) {
            id
            connector
            itemName
            itemType
            itemUuid
            hierarchyType
            parentName
        }
    }
`);

const WideScopePermissions: { [key: string]: string[] } = {
    [ConnectorName.ASANA]: [AsanaResourceType.Workspace, AsanaResourceType.Project],
    [ConnectorName.ATLASSIAN_CONFLUENCE]: [],
    [ConnectorName.ATLASSIAN_JIRA]: [JiraObjectTypes.Board, JiraObjectTypes.Project],
    [ConnectorName.BITBUCKET]: [BitbucketObjectTypes.REPO],
    [ConnectorName.CLICKUP]: [ClickupObjectTypes.Space],
    [ConnectorName.FIGMA]: [FigmaObjectTypes.Project, FigmaObjectTypes.Team],
    [ConnectorName.FIREFLIES]: [FilterHierarchyType.EverythingInConnector],
    [ConnectorName.GITHUB]: [GithubObjectTypes.REPO],
    [ConnectorName.GITLAB]: [GitlabObjectTypes.Project],
    [ConnectorName.GOOGLE_ANALYTICS]: [],
    [ConnectorName.GOOGLE_DRIVE]: [GdriveObjectTypes.Drive],
    [ConnectorName.HUBSPOT]: [HubspotObjectType.Deal, HubspotObjectType.Pipeline],
    [ConnectorName.LINEAR]: [LinearObjectTypes.Project, LinearObjectTypes.Team],
    [ConnectorName.MIRO]: [FilterHierarchyType.EverythingInConnector, MiroObjectTypes.Team],
    [ConnectorName.MONDAY]: [MondayObjectType.Workspace, MondayObjectType.Folder, MondayObjectType.Board],
    [ConnectorName.NOTION]: [],
    [ConnectorName.SLACK]: [SlackObjectTypes.CHANNEL],
    [ConnectorName.TOGGL]: [],
    [ConnectorName.TRELLO]: [TrelloObjectType.Organization, TrelloObjectType.Board],
};

const DefaultAllOption = {
    label: 'All',
    value: FilterHierarchyType.EverythingInConnector,
};

function cleanFilters(filters: ConnectedAppsFilter): ConnectedAppsFilter {
    let app = filters.app;
    let itemType = filters.itemType;

    // Validate the default option
    if (filters.app === DefaultAllOption.value) {
        app = undefined;
    }
    if (filters.itemType === DefaultAllOption.value) {
        itemType = undefined;
    }

    // Return
    return { app, itemType };
}

export function SearchTips(): JSX.Element {
    return (
        <Box pad="xsmall" border={{ color: Colors.border_dark, size: '2px' }} round="10px" gap="xxsmall">
            <Box direction="row" align="center" gap="xxsmall">
                <Info size="12px" color={Colors.brand} />
                <Text size="small" weight="bold">
                    Search Tips
                </Text>
            </Box>
            <Text size="xsmall">
                Use the menus and search to filter by app and data type. Selecting an item includes everything within it
                (like tasks in a project) and you can add multiple items across apps.
            </Text>
        </Box>
    );
}

export function DataSourceOption(props: {
    onSelected: (selected: Map<string, ConnectorScopeInput>) => void;
    selected: Map<string, ConnectorScopeInput>;
    showSearchWrapper: (toggle: boolean) => void;
    connectorScope: ConnectorScope;
}): JSX.Element {
    return (
        <CheckBox
            key={`check-${props.connectorScope.id}`}
            checked={props.selected.has(props.connectorScope.id)}
            onChange={(e) => {
                if (e.target.checked) {
                    props.selected.set(props.connectorScope.id, {
                        connector: props.connectorScope.connector,
                        itemName: props.connectorScope.itemName,
                        hierarchyType: props.connectorScope.hierarchyType,
                        id: props.connectorScope.id,
                        itemUuid: props.connectorScope.itemUuid,
                    });
                } else {
                    props.selected.delete(props.connectorScope.id);
                }
                props.onSelected(props.selected);
            }}
            onKeyDown={(e) => {
                if (e.key === 'Escape') {
                    e.preventDefault();
                    props.showSearchWrapper(false);
                }
            }}
            label={
                <Box gap="4px" direction="row" align="center">
                    <ConnectorIconSmall sizePixels="14px" name={props.connectorScope.connector as ConnectorName} />
                    {props.connectorScope.parentName && (
                        <InspectorCardTag
                            hideBorder
                            color="accent-3"
                            name=""
                            value={props.connectorScope.parentName}
                            hideTooltip
                        />
                    )}
                    {props.connectorScope.itemType && (
                        <InspectorCardTag
                            dontClipText
                            hideBorder
                            color="accent-3"
                            name=""
                            value={props.connectorScope.itemType}
                            hideTooltip
                        />
                    )}

                    <Text
                        size="14px"
                        style={{
                            fontFamily: FontFamily.Mono,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '330px',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {props.connectorScope.itemName}
                    </Text>
                </Box>
            }
        />
    );
}

export function wideScopeLabel(connector: string, itemType: string): string {
    const connectorLabel = getFormattedConnectorName(connector);
    if (itemType === DefaultAllOption.value) {
        return `Everything in ${connectorLabel}`;
    } else {
        return `Every ${itemType} from ${connectorLabel}`;
    }
}

export function WideScopeOption(props: {
    onWideScopeSelected: (selected: Map<string, ConnectorWideScopeInput>) => void;
    wideScopeSelected: Map<string, ConnectorWideScopeInput>;
    connectorWideScope: { itemType: string; connector: string };
}): JSX.Element {
    const wideScopeId = useMemo(
        () => `${props.connectorWideScope.connector}-${props.connectorWideScope.itemType}`,
        [props.connectorWideScope],
    );

    const optionLabel = useMemo(
        () => wideScopeLabel(props.connectorWideScope.connector, props.connectorWideScope.itemType),
        [props.connectorWideScope.connector, props.connectorWideScope.itemType],
    );

    const isCurrentOptionSelected = useMemo(() => {
        // Get the selected wide scope for the given connector
        const currentWideScope = props.wideScopeSelected.get(props.connectorWideScope.connector);

        // Check if the current option is selected
        return (
            currentWideScope != null &&
            currentWideScope.objectTypes != null &&
            currentWideScope.objectTypes.find((h) => h === props.connectorWideScope.itemType) != null
        );
    }, [props.wideScopeSelected, props.connectorWideScope]);

    const onSelectedScope = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            // Get the current wide scope
            const currentWideScope = props.wideScopeSelected.get(props.connectorWideScope.connector);

            // Selected
            if (e.target.checked) {
                if (currentWideScope) {
                    if (currentWideScope.objectTypes != null) {
                        // Check if the "All" option is selected
                        if (props.connectorWideScope.itemType === DefaultAllOption.value) {
                            // Clear the object types to only have the "All" option
                            currentWideScope.objectTypes = [props.connectorWideScope.itemType];
                        } else {
                            // Add the object type
                            currentWideScope.objectTypes.push(props.connectorWideScope.itemType);
                        }
                    } else {
                        // Initialize the object types with the current item type
                        currentWideScope.objectTypes = [props.connectorWideScope.itemType];
                    }
                } else {
                    // Initialize the wide scope
                    props.wideScopeSelected.set(props.connectorWideScope.connector, {
                        connector: props.connectorWideScope.connector,
                        objectTypes: [props.connectorWideScope.itemType],
                    });
                }
            }

            // Unselected
            else {
                // Remove the object type
                if (currentWideScope?.objectTypes != null) {
                    currentWideScope.objectTypes = currentWideScope.objectTypes.filter(
                        (h) => h !== props.connectorWideScope.itemType,
                    );
                }

                // If the object types are empty, remove the wide scope
                if (currentWideScope?.objectTypes == null || currentWideScope.objectTypes.length === 0) {
                    props.wideScopeSelected.delete(props.connectorWideScope.connector);
                }
            }

            // Update the selected wide scopes
            props.onWideScopeSelected(props.wideScopeSelected);
        },
        [props],
    );

    return (
        <CheckBox
            key={`check-${wideScopeId}`}
            checked={isCurrentOptionSelected}
            onChange={onSelectedScope}
            label={
                <Box gap="4px" direction="row" align="center">
                    <ConnectorIconSmall sizePixels="14px" name={props.connectorWideScope.connector as ConnectorName} />
                    {props.connectorWideScope.itemType && (
                        <InspectorCardTag
                            dontClipText
                            hideBorder
                            color="accent-3"
                            name=""
                            value={
                                props.connectorWideScope.itemType === FilterHierarchyType.EverythingInConnector
                                    ? 'All'
                                    : props.connectorWideScope.itemType
                            }
                            hideTooltip
                        />
                    )}

                    <Text
                        size="14px"
                        style={{
                            fontFamily: FontFamily.Mono,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '330px',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {optionLabel}
                    </Text>
                </Box>
            }
        />
    );
}

export function AppsConnectedFilters(props: {
    onChange: (selected: ConnectedAppsFilter) => void;
    selected: ConnectedAppsFilter;
    appsConnectedOptions: ConnectedAppsOption[];
    appsConnectedOptionsLoading: boolean;
}): JSX.Element {
    const itemTypes = useMemo((): ItemTypesOption[] => {
        // If an app is selected, only show that app items
        if (props.selected.app) {
            return props.appsConnectedOptions.flatMap((option) =>
                option.value === props.selected.app ? option.options : [],
            );

            // Since not app is selected, show all item types available
        } else {
            const types: { [key: string]: ItemTypesOption } = {};
            props.appsConnectedOptions.forEach((appsConnectedOption) => {
                appsConnectedOption.options.forEach((option) => {
                    types[option.value] = option;
                });
            });
            return Object.values(types);
        }
    }, [props.appsConnectedOptions, props.selected.app]);
    return (
        <Box direction="row" gap="xxsmall">
            {/* App filter */}
            <Box
                border={{ color: Colors.border_dark, size: '1px' }}
                background={{ color: Colors.background_back }}
                round="8px"
                height="28px"
                direction="row"
                align="center"
                justify="center"
                width="180px"
                pad={{ left: 'xxsmall' }}
            >
                <TwHeading level={6} style={{ whiteSpace: 'nowrap' }}>
                    App:
                </TwHeading>
                <Select
                    plain
                    focusIndicator
                    icon={<CaretDownFill size="14px" />}
                    placeholder="App"
                    options={[DefaultAllOption, ...props.appsConnectedOptions]}
                    value={props.selected.app || undefined}
                    valueKey="value"
                    labelKey="label"
                    size="small"
                    dropHeight="medium"
                    dropAlign={{
                        right: 'right',
                        top: 'bottom',
                    }}
                    dropProps={{
                        margin: { top: 'xxsmall' },
                        round: '8px',
                    }}
                    onChange={({ option }) => {
                        const newSelection = { ...props.selected };
                        newSelection.app = option.value;
                        newSelection.itemType = DefaultAllOption.value;
                        props.onChange(newSelection);
                    }}
                    disabled={props.appsConnectedOptionsLoading}
                    style={{ padding: '0 4px' }}
                />
            </Box>

            {/* Item type filter */}
            {props.selected.app != null && props.selected.app !== DefaultAllOption.value && (
                <Box
                    border={{ color: Colors.border_dark, size: '1px' }}
                    background={{ color: Colors.background_back }}
                    round="8px"
                    height="28px"
                    direction="row"
                    align="center"
                    justify="center"
                    width="180px"
                    pad={{ left: 'xxsmall' }}
                >
                    <TwHeading level={6} style={{ whiteSpace: 'nowrap' }}>
                        Type:
                    </TwHeading>
                    <Select
                        plain
                        focusIndicator
                        icon={<CaretDownFill size="14px" />}
                        placeholder="Item type"
                        options={[DefaultAllOption, ...itemTypes]}
                        value={props.selected.itemType || undefined}
                        valueKey="value"
                        labelKey="label"
                        size="small"
                        dropHeight="medium"
                        dropAlign={{
                            right: 'right',
                            top: 'bottom',
                        }}
                        dropProps={{
                            margin: { top: 'xxsmall' },
                            round: '8px',
                        }}
                        onChange={({ option }) => {
                            const newSelection = { ...props.selected };
                            newSelection.itemType = option.value;
                            props.onChange(newSelection);
                        }}
                        disabled={props.appsConnectedOptionsLoading}
                        style={{ padding: '0 4px' }}
                    />
                </Box>
            )}
        </Box>
    );
}

export function filterConnectorScopes<T extends { connector: string; itemType?: string; objectType?: string }>(
    connectorScopes: T[],
    wideScopesSelected: Map<string, ConnectorWideScopeInput>,
): T[] {
    return connectorScopes.filter((connectorScope) => {
        // Get the wide scope for the current connector
        const currentWideScope = wideScopesSelected.get(connectorScope.connector);

        // If no object type is selected, return the search result
        if (currentWideScope?.objectTypes == null) {
            return true;
        }

        // If the "All" option is selected, filter all options since the wide scope will include them all
        if (currentWideScope.objectTypes.includes(DefaultAllOption.value)) {
            return false;
        }

        // Validate the object types. If the connector scope object type is in the wide scope object types, filter it out
        if (
            connectorScope.objectType != null &&
            currentWideScope.objectTypes.includes(connectorScope.objectType.toLocaleLowerCase())
        ) {
            return false;
        }

        // Validate the object type with the item type. If the item type is in the object types, filter it out
        if (
            connectorScope.itemType != null &&
            currentWideScope.objectTypes.includes(connectorScope.itemType.toLocaleLowerCase())
        ) {
            return false;
        }

        // No more validations to do, return the search result
        return true;
    });
}

export function SearchItems(props: {
    onScopeSelected: (selected: Map<string, ConnectorScopeInput>) => void;
    scopeSelected: Map<string, ConnectorScopeInput>;
    onWideScopeSelected: (selected: Map<string, ConnectorWideScopeInput>) => void;
    wideScopesSelected: Map<string, ConnectorWideScopeInput>;
    onClose: () => void;
    appsConnectedOptions: ConnectedAppsOption[];
    appsConnectedOptionsLoading: boolean;
}): JSX.Element {
    const [searchText, setSearchText] = useState('');
    const { logger } = useTelemetryContext();
    const { postErrorMessage, connectedConnectorsWithoutStatus } = useUserStateContext();
    const [searchResults, setSearchResults] = useState<ConnectorScope[]>([]);
    const [searchResultEmpty, setSearchResultEmpty] = useState(false);
    const [parsingUrl, setParsingUrl] = useState(false);
    const [connectedAppsFilter, setConnectedAppsFilter] = useState<ConnectedAppsFilter>({
        app: DefaultAllOption.value,
        itemType: DefaultAllOption.value,
    });

    const [getConnectorScopeFromUrl] = useMutation(GET_CONNECTORS_SCOPE_FROM_URL, {
        onError: (error) => {
            postErrorMessage({ title: 'Error', shortDesc: 'Failed to get data from URL' });
            logger.error(error.message);
        },
    });

    const [itemSearch, { loading }] = useLazyQuery(ITEM_SEARCH);

    const debouncedResults = useMemo(() => {
        return debounce((query: string, filters: ConnectedAppsFilter) => {
            // Clean the filters
            const connectedAppsFilter = cleanFilters(filters);

            // Make the request
            itemSearch({ variables: { query, connectedAppsFilter } }).then((r) => {
                setSearchResults(r.data ? r.data.connectorsScopeSearch : []);
                setSearchResultEmpty(!r.data || r.data.connectorsScopeSearch.length === 0);
            });
        }, 300);
    }, [itemSearch]);

    useEffect(() => {
        return () => debouncedResults.cancel();
    }, [debouncedResults]);

    const showSearchWrapper = useCallback((toggle: boolean) => {
        if (!toggle) {
            setSearchResults([]);
            setSearchText('');
        }
    }, []);

    const exampleSearchQueries = useMemo(() => {
        // Filter by connected connectors that have example queries
        const queries = connectedConnectorsWithoutStatus.filter((c) => c.exampleConnectorScopes.length > 0);

        // Flatten the queries, map them and return
        return queries.flatMap((c) => c.exampleConnectorScopes);
    }, [connectedConnectorsWithoutStatus]);

    const wideScope = useMemo(() => {
        // No wide scopes
        if (connectedAppsFilter.app == null || connectedAppsFilter.app === DefaultAllOption.value) {
            return undefined;
        }

        // Use the default item type if not set
        let itemType = connectedAppsFilter.itemType;
        if (itemType == null) {
            itemType = DefaultAllOption.value;
        }

        // Check if the wide scope is available for this connector
        if (
            WideScopePermissions[connectedAppsFilter.app] == null ||
            !WideScopePermissions[connectedAppsFilter.app].includes(itemType)
        ) {
            return undefined;
        }

        // If the "All" option is selected we need to change the option to show the all option
        const currentWideScope = props.wideScopesSelected.get(connectedAppsFilter.app);
        if (currentWideScope?.objectTypes != null && currentWideScope.objectTypes.includes(DefaultAllOption.value)) {
            itemType = DefaultAllOption.value;
        }

        // Specific object type for wide scope (e.g. repository, branch, all, etc.)
        return { itemType, connector: connectedAppsFilter.app };
    }, [connectedAppsFilter.app, connectedAppsFilter.itemType, props.wideScopesSelected]);

    const filteredSearchResults = useMemo(
        () => filterConnectorScopes(searchResults, props.wideScopesSelected),
        [props.wideScopesSelected, searchResults],
    );

    const filteredExampleSearchQueries = useMemo(
        () => filterConnectorScopes(exampleSearchQueries, props.wideScopesSelected),
        [props.wideScopesSelected, exampleSearchQueries],
    );

    return (
        <Box gap={'xsmall'}>
            <Box gap={'xsmall'} height={{ min: 'max-content' }}>
                {/* Filters */}
                <AppsConnectedFilters
                    appsConnectedOptions={props.appsConnectedOptions}
                    appsConnectedOptionsLoading={props.appsConnectedOptionsLoading}
                    onChange={(selected: ConnectedAppsFilter) => {
                        setConnectedAppsFilter(selected);
                        debouncedResults(searchText, selected);
                    }}
                    selected={connectedAppsFilter}
                />

                {/* Input */}
                <Box direction="row" gap="xsmall" pad={{ bottom: '3px' }}>
                    <Box
                        direction="row"
                        gap="10px"
                        align="center"
                        flex
                        border={{ side: 'bottom', color: Colors.brand }}
                    >
                        {!loading && !parsingUrl && <Search size="14px" color={Colors.brand} />}
                        {(loading || parsingUrl) && <Spinner width="14px" height={'14px'} size="1px" />}
                        <PlainTextInput
                            id={FILTER_DATA_SOURCES_INPUT_ID}
                            value={searchText}
                            // eslint-disable-next-line jsx-a11y/no-autofocus
                            autoFocus
                            autoComplete="off"
                            placeholder={
                                loading || parsingUrl
                                    ? 'Loading..'
                                    : 'Search for projects, boards, documents, tasks to track'
                            }
                            fontSize="14px"
                            style={{
                                fontFamily: FontFamily.Mono,
                                background: 'unset',
                                width: '100%',
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Escape') {
                                    e.preventDefault();
                                    showSearchWrapper(false);
                                }
                            }}
                            onChange={async (e) => {
                                let value = e.target.value;
                                setSearchText(value);

                                function isValidHttpUrl(v: string) {
                                    let url;
                                    try {
                                        url = new URL(v);
                                    } catch (_) {
                                        return false;
                                    }

                                    return url.protocol === 'http:' || url.protocol === 'https:';
                                }

                                if (isValidHttpUrl(value)) {
                                    setParsingUrl(true);
                                    const ds = await getConnectorScopeFromUrl({ variables: { url: value } });
                                    if (
                                        ds.data &&
                                        ds.data.connectorsScopeFromUrl &&
                                        ds.data.connectorsScopeFromUrl.length > 0
                                    ) {
                                        if (ds.data.connectorsScopeFromUrl.length === 1) {
                                            props.scopeSelected.set(
                                                ds.data.connectorsScopeFromUrl[0].id,
                                                ds.data.connectorsScopeFromUrl[0],
                                            );
                                        }
                                        setSearchResults(
                                            ds.data.connectorsScopeFromUrl.length > 0
                                                ? [ds.data.connectorsScopeFromUrl[0]]
                                                : [],
                                        );
                                        value = ds.data.connectorsScopeFromUrl[0].itemName;
                                    } else if (!ds.errors && !ds.data?.connectorsScopeFromUrl) {
                                        postErrorMessage({ title: 'Error', shortDesc: 'Failed to parse URL' });
                                        logger.error(`Failed to parse URL: ${value}`);
                                    }
                                    setSearchText(value);
                                    setParsingUrl(false);
                                } else {
                                    setSearchText(value);
                                    debouncedResults(value, connectedAppsFilter);
                                }
                            }}
                        />
                    </Box>
                    <IconButtonV2
                        icon={(hover) => <Close size="18px" color={hover ? Colors.brand : undefined} />}
                        reverse
                        onClick={props.onClose}
                        alignSelf="end"
                    />
                </Box>

                {/* Example queries */}
                {filteredExampleSearchQueries.length > 0 &&
                    searchResults.length === 0 &&
                    !searchText &&
                    !parsingUrl && (
                        <Box height="145px" overflow={{ vertical: 'auto' }} pad="xxsmall">
                            <Box height={{ min: 'max-content' }}>
                                <Box gap="xxsmall">
                                    {filteredExampleSearchQueries.map((connectorScope) => (
                                        <DataSourceOption
                                            key={connectorScope.itemUuid}
                                            onSelected={props.onScopeSelected}
                                            selected={props.scopeSelected}
                                            showSearchWrapper={showSearchWrapper}
                                            connectorScope={connectorScope}
                                        />
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    )}

                {/* Search results and wide scopes */}
                {(filteredSearchResults.length > 0 || wideScope) && !loading && (
                    <Box height="145px" overflow={{ vertical: 'auto' }} pad="xxsmall">
                        <Box height={{ min: 'max-content' }}>
                            <Box gap="xxsmall">
                                {/* Wide scopes */}
                                {wideScope && (
                                    <WideScopeOption
                                        connectorWideScope={wideScope}
                                        wideScopeSelected={props.wideScopesSelected}
                                        onWideScopeSelected={props.onWideScopeSelected}
                                    />
                                )}

                                {/* Search results */}
                                {filteredSearchResults.length > 0 &&
                                    filteredSearchResults.map((connectorScope) => (
                                        <DataSourceOption
                                            key={connectorScope.itemUuid}
                                            onSelected={props.onScopeSelected}
                                            selected={props.scopeSelected}
                                            showSearchWrapper={showSearchWrapper}
                                            connectorScope={connectorScope}
                                        />
                                    ))}
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
            <Box overflow={{ vertical: 'auto' }}>
                <Box gap="xsmall" direction="row" wrap height={{ min: 'max-content', height: 'max-content' }}>
                    {props.scopeSelected.size === 0 && (
                        <Box gap="xsmall">
                            {searchResultEmpty && searchText && !parsingUrl && (
                                <Text size="14px" style={{ fontFamily: FontFamily.Mono }} color={Colors.dark_4}>
                                    No results found
                                </Text>
                            )}
                            {parsingUrl && (
                                <Text size="14px" style={{ fontFamily: FontFamily.Mono }} color={Colors.dark_4}>
                                    Parsing URL...
                                </Text>
                            )}
                            {searchResults.length === 0 && connectedConnectorsWithoutStatus.length > 0 && (
                                <SearchTips />
                            )}
                        </Box>
                    )}
                    {Array.from(props.wideScopesSelected.values()).map(
                        (v) =>
                            v.objectTypes &&
                            v.objectTypes.map((objectType, hi) => (
                                <Box
                                    direction="row"
                                    key={`selected-${v.connector}-${hi}`}
                                    gap="xxsmall"
                                    background={Colors.background_back}
                                    pad={{ horizontal: 'xsmall', vertical: 'xxsmall' }}
                                    round="5px"
                                    align="center"
                                    margin={{ bottom: 'xxsmall' }}
                                >
                                    <ConnectorIconSmall name={v.connector as ConnectorName} sizePixels="14px" />
                                    <Box direction="row" gap="xsmall">
                                        <Text size="14px" style={{ fontFamily: FontFamily.Mono }}>
                                            {wideScopeLabel(v.connector, objectType)}
                                        </Text>
                                    </Box>
                                    <RoundIconButton
                                        icon={FormClose}
                                        color={{ hover: Colors.brand, color: Colors.brand }}
                                        sizePixels={12}
                                        onClick={() => {
                                            const objectTypes = v.objectTypes?.filter((ot) => ot !== objectType) || [];
                                            if (objectTypes.length === 0) {
                                                props.wideScopesSelected.delete(v.connector);
                                            } else {
                                                props.wideScopesSelected.set(v.connector, {
                                                    connector: v.connector,
                                                    objectTypes,
                                                });
                                            }
                                            props.onWideScopeSelected(props.wideScopesSelected);
                                        }}
                                    />
                                </Box>
                            )),
                    )}
                    {Array.from(props.scopeSelected.values()).map((v, vi) => (
                        <Box
                            direction="row"
                            key={`selected-${vi}`}
                            gap="xxsmall"
                            background={Colors.background_back}
                            pad={{ horizontal: 'xsmall', vertical: 'xxsmall' }}
                            round="5px"
                            align="center"
                            margin={{ bottom: 'xxsmall' }}
                        >
                            <ConnectorIconSmall name={v.connector as ConnectorName} sizePixels="14px" />
                            <Box direction="row" gap="xsmall">
                                <Text size="14px" style={{ fontFamily: FontFamily.Mono }}>
                                    {v.itemName}
                                </Text>
                            </Box>
                            <RoundIconButton
                                icon={FormClose}
                                color={{ hover: Colors.brand, color: Colors.brand }}
                                sizePixels={12}
                                onClick={() => {
                                    props.scopeSelected.delete(v.id);
                                    props.onScopeSelected(props.scopeSelected);
                                }}
                            />
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
}
