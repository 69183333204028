// Copyright 2021
// ThatWorks.xyz Limited

import { ConnectorName, getFormattedConnectorName } from '@thatworks/connector-api';
import { getIconPath } from '@thatworks/shared-frontend/connectors';
import React from 'react';
import { useTelemetryContext } from './TelemetryContext';

export interface IconProps {
    name: ConnectorName;
    extension?: 'svg' | 'png';
    sizePixels?: string;
    alignSelf?: string;
    opacity?: string;
    style?: React.CSSProperties;
}

function getIcon(p: IconProps, onError: () => void): JSX.Element {
    try {
        const path = getIconPath(p.name, p.extension);
        return (
            <img
                src={path}
                width={p.sizePixels}
                height={p.sizePixels}
                style={{ alignSelf: p.alignSelf, pointerEvents: 'none', opacity: p.opacity, ...p.style }}
                alt={`${getFormattedConnectorName(p.name)} icon`}
            />
        );
    } catch (error) {
        onError();
        return <></>;
    }
}

export function ConnectorIconSmall(props: IconProps): JSX.Element {
    const { logger } = useTelemetryContext();
    return getIcon(props, () => logger.error(`No icon specified for ${props.name}`));
}
