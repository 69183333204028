// Copyright 2021
// ThatWorks.xyz Limited

import { useStatsigClient } from '@statsig/react-bindings';
import { Colors } from '@thatworks/colors';
import { Gates } from '@thatworks/shared-frontend/metrics';
import { Box, Button, DropButton, Text } from 'grommet';
import { Icon, Logout, SettingsOption } from 'grommet-icons';
import { useEffect, useState } from 'react';
import { FontFamily } from '../theme';
import { useAuth } from './AuthProvider';
import DropdownButton from './DropdownButton';
import { RandomAvatar } from './RandomAvatar';
import { SidebarNav } from './SidebarNav';

const NAV_BUTTON_WIDTH = '160px';
const ICON_SIZE = '20px';

export function NavButton(props: {
    icon: Icon;
    onClick?: () => void;
    active?: boolean;
    buttonId?: string;
    label?: string;
    width?: string;
}) {
    return (
        <Button
            onClick={() => {
                if (props.onClick) {
                    props.onClick();
                }
            }}
            plain
            id={props.buttonId}
        >
            {({ hover }) => (
                <Box
                    direction="row"
                    gap="xsmall"
                    align="center"
                    background={hover || props.active ? Colors.accent_2 : undefined}
                    pad="10px"
                    justify="start"
                    round="xxsmall"
                    width={props.width || NAV_BUTTON_WIDTH}
                >
                    <props.icon
                        size={ICON_SIZE}
                        style={{ alignSelf: 'center' }}
                        color={props.active ? Colors.dark_2 : hover ? Colors.dark_2 : Colors.neutral_4}
                    />
                    {props.label && (
                        <Text
                            size="14px"
                            weight={props.active ? 'bold' : undefined}
                            color={props.active ? Colors.dark_2 : hover ? Colors.dark_2 : Colors.neutral_4}
                        >
                            {props.label}
                        </Text>
                    )}
                </Box>
            )}
        </Button>
    );
}

export function AccountNavDropButton(props: {
    closed: boolean;
    activeNavButton?: SidebarNav;
    onNav?: (n: SidebarNav.InternalSettings | SidebarNav.Logout) => void;
    width?: string;
}): JSX.Element {
    const { checkGate } = useStatsigClient();
    const [hover, setHover] = useState(false);
    const [userEmail, setUserEmail] = useState<string | undefined>();
    const { getUserInfo } = useAuth();

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const userInfo = await getUserInfo();
                setUserEmail(userInfo?.emailAddress);
            } catch (error) {
                // do nothing
            }
        };
        fetchUserInfo();
    }, [getUserInfo]);

    return (
        <DropButton
            style={{ padding: '0px' }}
            dropContent={
                <Box background="transparent" direction="row">
                    <Box align="begin" justify="end" pad={{ bottom: '10px' }}>
                        <svg width="10" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 10L10 -4.40634e-07L10 20L0 10Z" fill="#490956" />
                        </svg>
                    </Box>
                    <Box background="brand" round="5px" pad={{ top: '10px', bottom: '10px' }}>
                        {userEmail && (
                            <Box
                                pad={{ horizontal: 'small', bottom: 'xxsmall' }}
                                border={{ side: 'bottom', color: Colors.neutral_2 }}
                                margin={{ bottom: 'xxsmall' }}
                            >
                                <Text size="14px" style={{ fontFamily: FontFamily.Heading }} weight="bold">
                                    {userEmail}
                                </Text>
                            </Box>
                        )}
                        {checkGate(Gates.CreateUserForm) && (
                            <DropdownButton
                                label="Internal Settings"
                                icon={SettingsOption}
                                onClick={async () => {
                                    if (props.onNav) {
                                        props.onNav(SidebarNav.InternalSettings);
                                    }
                                }}
                            />
                        )}
                        <DropdownButton
                            label="Logout"
                            icon={Logout}
                            onClick={async () => {
                                if (props.onNav) {
                                    props.onNav(SidebarNav.Logout);
                                }
                            }}
                        />
                    </Box>
                </Box>
            }
            dropAlign={{ left: 'right', bottom: 'bottom' }}
            dropProps={{
                background: 'transparent',
                elevation: 'none',
            }}
        >
            <Box
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                direction="row"
                gap="xsmall"
                align="center"
                background={hover ? Colors.accent_2 : undefined}
                pad="10px"
                justify="start"
                round="xxsmall"
                width={props.width || NAV_BUTTON_WIDTH}
            >
                {RandomAvatar({ size: ICON_SIZE, withoutCaret: true })}
                {!props.closed && <Text size="14px">Account</Text>}
            </Box>
        </DropButton>
    );
}
