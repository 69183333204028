// Copyright 2021
// ThatWorks.xyz Limited

import { ConnectorName } from '@thatworks/connector-api';

export interface ConnectorToken {
    connector: ConnectorName;
    accessToken: string;
    connectorUserId: string;
}

export enum ConnectorPermissionStatus {
    Ok = 'Ok',
    PermissionError = 'permission_error',
    CredentialsError = 'credentials_error',
}

export interface PatTestRequest {
    pat: string;
    username: string;
    url: string;
}

export interface PatTestResponse {
    message?: string;
    error?: string;
}

export function getSupportedConnectors(): ConnectorName[] {
    return [
        ConnectorName.GOOGLE,
        ConnectorName.ATLASSIAN,
        ConnectorName.CLICKUP,
        ConnectorName.NOTION,
        ConnectorName.MIRO,
        ConnectorName.FIGMA,
        ConnectorName.FIREFLIES,
        ConnectorName.ASANA,
        ConnectorName.TOGGL,
        ConnectorName.LINEAR,
        ConnectorName.SLACK,
        ConnectorName.GITHUB,
        ConnectorName.GITLAB,
        ConnectorName.BITBUCKET,
        ConnectorName.HUBSPOT,
        ConnectorName.MONDAY,
        ConnectorName.TRELLO,
    ];
}

function getIconFileName(connector: ConnectorName): string {
    switch (connector) {
        case ConnectorName.NOTION:
            return 'notion';
        case ConnectorName.ATLASSIAN:
            return 'atlassian';
        case ConnectorName.ATLASSIAN_JIRA:
            return 'jira';
        case ConnectorName.ATLASSIAN_CONFLUENCE:
            return 'confluence';
        case ConnectorName.CLICKUP:
            return 'clickup';
        case ConnectorName.GOOGLE:
            return 'google';
        case ConnectorName.GOOGLE_DRIVE:
            return 'google-drive';
        case ConnectorName.GOOGLE_ANALYTICS:
            return 'google-analytics';
        case ConnectorName.FIGMA:
            return 'figma';
        case ConnectorName.MIRO:
            return 'miro';
        case ConnectorName.ASANA:
            return 'asana';
        case ConnectorName.GITLAB:
            return 'gitlab';
        case ConnectorName.TOGGL:
            return 'toggl';
        case ConnectorName.LINEAR:
            return 'linear';
        case ConnectorName.MONDAY:
            return 'monday';
        case ConnectorName.TRELLO:
            return 'trello';
        case ConnectorName.SLACK:
            return 'slack';
        case ConnectorName.GITHUB:
            return 'github';
        case ConnectorName.BITBUCKET:
            return 'bitbucket';
        case ConnectorName.HUBSPOT:
            return 'hubspot';
        default:
            throw new Error(`No icon name specified for ${connector}`);
    }
}

export function getIconPath(name: ConnectorName, extension?: 'svg' | 'png', pngSize: '14' | '18' = '18') {
    const fileName = getIconFileName(name);
    const ext = extension || 'svg';
    const additional = ext === 'png' ? `-${pngSize}x${pngSize}` : '';
    return `/icons/connector-icon-small/${ext}/${fileName}${additional}.${ext}`;
}
