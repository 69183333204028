// Copyright 2021
// ThatWorks.xyz Limited

import { LinkExtension, ParagraphExtension, TableExtension, wysiwygPreset } from 'remirror/extensions';
import styled from 'styled-components';

export const ProseWrapper = styled.div`
    .ProseMirror:focus {
        outline: none;
    }
    .remirror-is-empty:first-of-type::before {
        position: absolute;
        color: #aaa;
        pointer-events: none;
        content: attr(data-placeholder);
    }
    .placeholder-fixed-width:first-of-type::after {
        position: relative;
        color: #aaa;
        pointer-events: none;
        content: attr(data-placeholder);
        top: -20px;
    }
    .ProseMirror table {
        border-collapse: collapse;
        width: 100%;
        margin-top: 8px;
        border-bottom: 1px solid #f2eeeb;

        @media screen and (max-width: 768px) {
            /* Maintain same styles as desktop */
            border-bottom: 1px solid #f2eeeb;
            width: 100%;
            margin: 8px 0;
        }
    }
    .ProseMirror th,
    .ProseMirror td {
        padding-right: 4px;
        padding-bottom: 0px;
        text-align: left;
        vertical-align: top;

        @media screen and (max-width: 768px) {
            /* Stack cells while maintaining desktop styling */
            display: block;
            width: 100%;
            padding-right: 4px;
            padding-bottom: 0px;
            border: none; /* Remove any default borders */
        }
    }

    /* Handle table rows in mobile view */
    .ProseMirror tr {
        @media screen and (max-width: 768px) {
            display: block;
            width: 100%;
            border: none; /* Remove any default borders */
        }
    }

    /*
    /* custom css for nodes */
    /*
    /* inline-pill-paragraph with no margin */
    .ProseMirror .inline-pill-paragraph,
    .ProseMirror td .inline-pill-paragraph,
    .ProseMirror th .inline-pill-paragraph {
        margin: 14px 0px 0px 0px !important; /* Using !important to override any other styles */
    }
`;

export function getDefaultProseMirrorExtensions(nodesToIgnore: string[] = []) {
    // remove the link extension so we can manually add it with custom settings
    // because the wysiwygPreset function does not forward all settings to the link extension
    nodesToIgnore.push('link');

    const extensions = wysiwygPreset().filter((ext) => !nodesToIgnore.includes(ext.name));
    return [
        ...extensions,
        new TableExtension({}),
        new LinkExtension({ autoLink: true, defaultTarget: '_blank' }),
        new ParagraphExtension({
            // allow for paragraph styling
            extraAttributes: {
                class: { default: null },
            },
        }),
    ];
}
