// Copyright 2021
// ThatWorks.xyz Limited

export enum AsanaResourceType {
    Task = 'task',
    Story = 'story',
    Section = 'section',
    // --- non webhook
    Workspace = 'workspace',
    Project = 'project',
}

export enum JiraObjectTypes {
    Sprint = 'sprint',
    Board = 'board',
    BoardBacklog = 'board_backlog',
    Project = 'project',
    // jira may return "Epic" instead of "epic"
    EpicMaybeCaseSensitive = 'epic',
    ProductDiscoveryView = 'product_discovery_view',
}

export enum ConfluenceSpaceType {
    personal = 'personal',
    global = 'global',
}

export enum BitbucketObjectTypes {
    REPO = 'repo',
    PR = 'pr',
    BRANCH = 'branch',
    COMMIT = 'commit',
}

export enum ClickupObjectTypes {
    Task = 'task',
    Space = 'space',
    List = 'list',
    Folder = 'folder',
}

export enum FigmaObjectTypes {
    Document = 'document',
    Project = 'project',
    Team = 'team',
}

export enum FirefliesObjectTypes {
    Transcript = 'transcript',
}

export enum GithubObjectTypes {
    REPO = 'repo',
    PR = 'pr',
    BRANCH = 'branch',
    COMMIT = 'commit',
}

export enum GitlabObjectTypes {
    Project = 'project',
    MergeRequest = 'merge_request',
    Branch = 'branch',
    Commit = 'commit',
}

export enum GoogleAnalyticsObjectTypes {
    Account = 'account',
    Property = 'property',
    Report = 'report',
}

export enum GoogleDriveObjectTypes {
    GoogleSheetNamedRange = 'sheet_named_range',
}

export enum GdriveObjectTypes {
    Drive = 'drive',
}

// https://developers.google.com/drive/api/guides/mime-types
export enum GdriveMimeTypes {
    Document = 'application/vnd.google-apps.document',
    Spreadsheet = 'application/vnd.google-apps.spreadsheet',
    Presentation = 'application/vnd.google-apps.presentation',
    Folder = 'application/vnd.google-apps.folder',
}

export enum HubspotObjectType {
    Deal = 'deal',
    Contact = 'contact',
    Company = 'company',
    Engagement = 'engagement',
    Pipeline = 'pipeline',
}

export enum LinearObjectTypes {
    Project = 'project',
    Issue = 'issue',
    Milestone = 'milestone',
    Cycle = 'cycle',
    Team = 'team',
    Organization = 'organization',
}

export enum MiroObjectTypes {
    Board = 'board',
    Team = 'team',
}

export enum MondayObjectType {
    Workspace = 'workspace',
    Folder = 'folder',
    Board = 'board',
    Group = 'group',
    Item = 'item',
}

export enum NotionObjectTypes {
    Database = 'database',
    Page = 'page',
    Block = 'block',
}

export enum SlackObjectTypes {
    CHANNEL = 'channel',
}

export enum ToggleObjectTypes {
    Workspace = 'workspace',
    Project = 'project',
}

export enum TrelloObjectType {
    Organization = 'workspace', // workspace because that's what is shown in the UI
    Board = 'board',
    List = 'list',
    Card = 'card',
}
