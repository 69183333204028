// Copyright 2021
// ThatWorks.xyz Limited

import { useLazyQuery } from '@apollo/client';
import { autoPlacement, autoUpdate, useDismiss, useFloating, useInteractions } from '@floating-ui/react';
import { Colors } from '@thatworks/colors';
import { Box, Select } from 'grommet';
import { Close, Filter } from 'grommet-icons';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { gql } from '../../../../../../__generated__';
import { ActivityItemFilterOperator, GraphFilterType } from '../../../../../../__generated__/graphql';
import { IconButtonV2 } from '../../../../../../components/IconButton';
import { ToolbarButton } from '../../../../../../components/prosemirror/ToolbarButton';
import { useTelemetryContext } from '../../../../../../components/TelemetryContext';
import { TwHeading } from '../../../../../../components/TwHeading';
import { useUserStateContext } from '../../../../../../components/UserContext';
import { AddGroupButton } from './AddGroupButton';
import { FilterGroup } from './FilterGroup';
import { DEFAULT_PROPERTY_FILTERS, Operator, Property, PropertyFilterGroup } from './helpers';

export const GET_TIMELINE_PROPERTIES = gql(/* GraphQL */ `
    query TimelineProperties($timelineId: ID!) {
        timelineProperties(timelineId: $timelineId) {
            assignees
            priorities
            statuses
            connectorItemTypes
            tags
            fromStatuses
            customPropertyNames
            actors
        }
    }
`);

export const Listing = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export function FilterToolbarButton(props: {
    onChangePropertyFilters: (filters: PropertyFilterGroup[]) => void;
    onChangeFilterOperator: (operator: ActivityItemFilterOperator) => void;
    onChangeGraphFilterType: (graphFilterType: GraphFilterType) => void;
    graphFilterType: GraphFilterType;
    propertyFilterGroups: PropertyFilterGroup[];
    filtersOperator: ActivityItemFilterOperator;
    timelineId: string | undefined;
    dataLoading: boolean;
    v2?: boolean;
}): JSX.Element {
    const [buttonActive, setButtonActive] = useState(false);
    const [propertiesOptions, setPropertiesOptions] = useState<typeof DEFAULT_PROPERTY_FILTERS>(
        structuredClone(DEFAULT_PROPERTY_FILTERS),
    );

    const { logger } = useTelemetryContext();
    const { postErrorMessage } = useUserStateContext();

    const { refs, floatingStyles, context } = useFloating({
        whileElementsMounted: autoUpdate,
        middleware: [
            autoPlacement({
                allowedPlacements: ['top-start', 'bottom-start'],
            }),
        ],
        open: buttonActive,
        onOpenChange: setButtonActive,
    });

    const dismiss = useDismiss(context);
    const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

    // Valid filters flag
    const hasValidFilters = useMemo(
        () =>
            props.propertyFilterGroups.some((group) =>
                group.propertyFilters.some((f) => f.property && f.operator && f.value),
            ),
        [props.propertyFilterGroups],
    );

    // Property filter options
    const [getTimelineProperties] = useLazyQuery(GET_TIMELINE_PROPERTIES, {
        onError: (error) => {
            postErrorMessage({ title: 'Error', shortDesc: `Failed to get timeline properties` });
            logger.error(error.message);
        },
    });
    useEffect(() => {
        // Check if we already have the timeline ID
        if (!props.timelineId) {
            return;
        }

        // Get the timeline properties
        getTimelineProperties({
            variables: { timelineId: props.timelineId },
            onCompleted: (data) => {
                // Deep copy the default filters
                const propertyFilters = structuredClone(DEFAULT_PROPERTY_FILTERS);

                // Update the default filters with the received options
                const { assignees, priorities, statuses, connectorItemTypes, tags, fromStatuses, actors } =
                    data.timelineProperties;
                const mapping = [
                    {
                        property: Property.Assignee,
                        operators: [Operator.Eq, Operator.Neq, Operator.Regex, Operator.In],
                        receivedOptions: assignees,
                    },
                    {
                        property: Property.Priority,
                        operators: [Operator.Eq, Operator.Neq, Operator.Regex],
                        receivedOptions: priorities,
                    },
                    {
                        property: Property.Status,
                        operators: [Operator.Eq, Operator.Neq, Operator.Regex, Operator.From],
                        receivedOptions: statuses,
                    },
                    {
                        property: Property.Status,
                        operators: [Operator.From],
                        receivedOptions: fromStatuses,
                    },
                    {
                        property: Property.TypeOfItem,
                        operators: [Operator.Eq, Operator.Neq, Operator.Regex, Operator.In],
                        receivedOptions: connectorItemTypes,
                    },
                    {
                        property: Property.Tag,
                        operators: [Operator.Eq, Operator.Neq, Operator.Regex, Operator.In],
                        receivedOptions: tags,
                    },
                    {
                        property: Property.UpdatesBy,
                        operators: [Operator.In],
                        receivedOptions: actors,
                    },
                ];
                mapping.forEach(({ property, operators, receivedOptions }) => {
                    operators.forEach((operator) => {
                        const valueOptions = propertyFilters[property]?.operatorOptions[operator]?.valueOptions;
                        if (valueOptions) {
                            const previousOptions =
                                valueOptions.options?.filter((o) => !receivedOptions.includes(o.value)) || [];
                            valueOptions.options = [
                                ...receivedOptions.map((v) => ({ label: v, value: v })),
                                ...previousOptions,
                            ];
                        }
                    });
                });

                // Set the property filters
                setPropertiesOptions(propertyFilters);
            },
        });
    }, [props.timelineId, getTimelineProperties]);

    return (
        <Box ref={refs.setReference} {...getReferenceProps()} margin={props.v2 ? undefined : { left: '32px' }}>
            <ToolbarButton
                active={buttonActive || hasValidFilters}
                icon={Filter}
                label={props.v2 ? 'Customize' : 'Customize query'}
                onClick={async () => {
                    setButtonActive(!buttonActive);
                }}
                iconSize="14px"
                textProps={props.v2 ? undefined : { size: '12px' }}
                disabled={props.dataLoading}
                v2={props.v2}
            />
            {buttonActive && (
                <div ref={refs.setFloating} style={{ ...floatingStyles }} {...getFloatingProps()}>
                    <Box
                        height={{ min: '86px', max: '50vh' }}
                        width={{ min: '350px', width: '500px', max: '50vw' }}
                        background={{ color: Colors.background_front }}
                        border={{ color: Colors.border_dark, size: '1px' }}
                        pad={'xsmall'}
                        round={'25px'}
                        elevation="xsmall"
                        gap="xxsmall"
                        overflow={{ vertical: 'scroll' }}
                    >
                        {/* Operator and Close button */}
                        <Box direction="row" gap="xxsmall" align="center" justify="between" height={{ min: '32px' }}>
                            <Box direction="row" gap="xxsmall">
                                <Box
                                    border={{ color: Colors.accent_3, size: '1px' }}
                                    round="10px"
                                    height="32px"
                                    direction="row"
                                    align="center"
                                    justify="center"
                                    width="min-content"
                                    pad={{ left: 'xxsmall' }}
                                >
                                    <TwHeading level={6} style={{ whiteSpace: 'nowrap' }}>
                                        Operator:
                                    </TwHeading>
                                    <Select
                                        width="50px"
                                        plain
                                        focusIndicator
                                        icon={false}
                                        size="small"
                                        options={[
                                            { label: 'OR', value: ActivityItemFilterOperator.Or },
                                            { label: 'AND', value: ActivityItemFilterOperator.And },
                                        ]}
                                        valueKey="value"
                                        labelKey="label"
                                        value={props.filtersOperator}
                                        onChange={({ option }) => props.onChangeFilterOperator(option.value)}
                                    />
                                </Box>
                                <Box
                                    border={{ color: Colors.accent_3, size: '1px' }}
                                    round="10px"
                                    height="32px"
                                    direction="row"
                                    align="center"
                                    justify="center"
                                    width="min-content"
                                    pad={{ left: 'xxsmall' }}
                                >
                                    <TwHeading level={6} style={{ whiteSpace: 'nowrap' }}>
                                        Apply to:
                                    </TwHeading>
                                    <Select
                                        width="110px"
                                        plain
                                        focusIndicator
                                        icon={false}
                                        size="small"
                                        options={[
                                            { label: 'Everything', value: GraphFilterType.Full },
                                            { label: 'Parents', value: GraphFilterType.RootsOnly },
                                            { label: 'Children', value: GraphFilterType.ChildrenOfRootsOnly },
                                        ]}
                                        valueKey="value"
                                        labelKey="label"
                                        value={props.graphFilterType}
                                        onChange={({ option }) => props.onChangeGraphFilterType(option.value)}
                                    />
                                </Box>
                            </Box>
                            <IconButtonV2
                                icon={(hover) => <Close size="18px" color={hover ? Colors.brand : undefined} />}
                                reverse
                                onClick={() => setButtonActive(false)}
                            />
                        </Box>
                        {/* Filter groups */}
                        <Listing>
                            {props.propertyFilterGroups.map((propertyFilterGroup, index) => (
                                <Box key={`group-${index}`} gap={'8px'}>
                                    <FilterGroup
                                        groupIndex={index + 1}
                                        propertyFilterGroup={propertyFilterGroup}
                                        propertiesOptions={propertiesOptions}
                                        onChange={(group) => {
                                            const newSelection = [...props.propertyFilterGroups];
                                            newSelection[index] = group;
                                            props.onChangePropertyFilters(newSelection);
                                        }}
                                        onDelete={() => {
                                            const newSelection = [...props.propertyFilterGroups];
                                            newSelection.splice(index, 1);
                                            props.onChangePropertyFilters(newSelection);
                                        }}
                                    />
                                    {index !== props.propertyFilterGroups.length - 1 && (
                                        <TwHeading level={6}>{props.filtersOperator.toLowerCase()}</TwHeading>
                                    )}
                                </Box>
                            ))}
                        </Listing>
                        {/* Add group button */}
                        <AddGroupButton
                            onChange={props.onChangePropertyFilters}
                            propertyFilterGroups={props.propertyFilterGroups}
                        />
                    </Box>
                </div>
            )}
        </Box>
    );
}
