// Copyright 2021
// ThatWorks.xyz Limited

import { NetworkStatus, useLazyQuery, useQuery } from '@apollo/client';
import { ConnectorName } from '@thatworks/connector-api';
import { Box, Grid, Spinner } from 'grommet';
import { useMemo, useState } from 'react';
import { gql } from '../../../../__generated__';
import { GetHomeScopesQuery } from '../../../../__generated__/graphql';
import { BasicPage3, BasicPage3NavFunction, DefaultPagePaddingPixels } from '../../../../components/BasicPage3';
import { PageTitle } from '../../../../components/PageTitle';
import { getLabel, getNavIcon, SidebarNav } from '../../../../components/SidebarNav';
import { useTelemetryContext } from '../../../../components/TelemetryContext';
import { useUserStateContext } from '../../../../components/UserContext';
import { withAuth } from '../../../../components/withAuth';
import {
    DatePreset,
    TimelineDateSelection,
    timelineDateSelectionToTimelineCreateDate,
} from '../magic-composer/filters/timeline-date-selection';
import { TimeSelectionToolbarButton } from '../magic-composer/filters/TimeSelectionToolbarButton';
import { CreateAutomationDropdown } from './CreateAutomationDropdown';
import { FeedFetchStatus, FeedStatus } from './FeedFetchStatus';
import { ScopeFilterDropdown } from './ScopeFilterDropdown';
import { ScopesEmpty, ScopesList } from './ScopesList';

const GET_HOME_LAST_SCOPES = gql(/* GraphQL */ `
    query GetHomeLastScopes {
        homeLastScopes {
            dateIso
            numDays
            scopes {
                scopes {
                    scope {
                        id
                        connector
                        itemName
                        itemType
                        itemUuid
                        hierarchyType
                        parentName
                    }
                    favorite
                    numItems
                    timelineId
                    activity {
                        title
                        groups {
                            type
                            subgroups {
                                ids
                                name
                                props {
                                    ... on ItemSubgroupTaskStatus {
                                        isDoneStatus
                                        sortOrder
                                        status
                                    }
                                }
                                summary {
                                    summary {
                                        sections {
                                            markdown
                                            pills {
                                                value
                                                color
                                                iconUrl
                                                itemUuids
                                                connector
                                            }
                                            newRowForPills
                                        }
                                    }
                                }
                            }
                        }
                        items {
                            title
                            id
                            iconUrl
                            connector
                            url
                            actors {
                                names {
                                    color
                                    isUser
                                    name
                                }
                                hasMore
                            }
                            parents {
                                readableConnectorObjectType
                                name
                                url
                            }
                            properties {
                                color
                                name
                                value
                                iconUrl
                                valueType
                                propertyType
                            }
                            changeDescription {
                                color
                                decoration
                                value
                            }
                            timeRange {
                                newest
                                oldest
                            }
                            docDiff {
                                ... on DocDiffSummarized {
                                    summary
                                }
                            }
                            comments {
                                comments {
                                    authorDisplayName
                                    comment
                                    date
                                    userMention
                                }
                                more
                                total
                            }
                        }
                    }
                }
                minNumUpdates
                maxNumUpdates
            }
        }
    }
`);

const GET_HOME_SCOPES = gql(/* GraphQL */ `
    query GetHomeScopes($fromDate: TimelineCreateDate!, $cacheSessionKey: String!) {
        homeScopes(fromDate: $fromDate, cacheSessionKey: $cacheSessionKey) {
            scopes {
                scope {
                    id
                    connector
                    itemName
                    itemType
                    itemUuid
                    hierarchyType
                    parentName
                }
                favorite
                numItems
                timelineId
                activity {
                    title
                    groups {
                        type
                        subgroups {
                            ids
                            name
                            props {
                                ... on ItemSubgroupTaskStatus {
                                    isDoneStatus
                                    sortOrder
                                    status
                                }
                            }
                            summary {
                                summary {
                                    sections {
                                        markdown
                                        pills {
                                            value
                                            color
                                            iconUrl
                                            itemUuids
                                            connector
                                        }
                                        newRowForPills
                                    }
                                }
                            }
                        }
                    }
                    items {
                        title
                        id
                        iconUrl
                        connector
                        url
                        actors {
                            names {
                                color
                                isUser
                                name
                            }
                            hasMore
                        }
                        parents {
                            readableConnectorObjectType
                            name
                            url
                        }
                        properties {
                            color
                            name
                            value
                            iconUrl
                            valueType
                            propertyType
                        }
                        changeDescription {
                            color
                            decoration
                            value
                        }
                        timeRange {
                            newest
                            oldest
                        }
                        docDiff {
                            ... on DocDiffSummarized {
                                summary
                            }
                        }
                        comments {
                            comments {
                                authorDisplayName
                                comment
                                date
                                userMention
                            }
                            more
                            total
                        }
                    }
                }
            }
            minNumUpdates
            maxNumUpdates
        }
    }
`);

function Home(props: { onNav: BasicPage3NavFunction }): JSX.Element {
    const { logger } = useTelemetryContext();
    const { postErrorMessage } = useUserStateContext();
    const [sessionId, setSessionId] = useState<string>(Math.random().toString(36).substring(7));
    const [appFilter, setAppFilter] = useState<{ label: string; id: string }[]>([]);
    const [homeScopes, setHomeScopes] = useState<GetHomeScopesQuery['homeScopes']>();
    const [fromDateSelection, setFromDateSelection] = useState<TimelineDateSelection | undefined>();
    const fromDate = useMemo(
        () => (fromDateSelection ? timelineDateSelectionToTimelineCreateDate(fromDateSelection) : undefined),
        [fromDateSelection],
    );
    const [lastLoadDateIso, setLastLoadDateIso] = useState<string | undefined>();

    // Get last scopes
    const { loading: loadingLastScopes } = useQuery(GET_HOME_LAST_SCOPES, {
        fetchPolicy: 'no-cache',
        onError: (err) => {
            postErrorMessage({ title: `Error`, shortDesc: 'Failed to get data' });
            logger.error(err.message);
        },
        onCompleted: (data) => {
            setHomeScopes(data.homeLastScopes?.scopes);
            setLastLoadDateIso(data.homeLastScopes?.dateIso);

            if (data.homeLastScopes) {
                const days = data.homeLastScopes.numDays;
                if (days < 1) {
                    setFromDateSelection({ customDaysStr: '', preset: DatePreset.Today });
                } else if (days === 7) {
                    setFromDateSelection({ customDaysStr: '', preset: DatePreset.OneWeek });
                } else if (days === 14) {
                    setFromDateSelection({ customDaysStr: '', preset: DatePreset.TwoWeeks });
                } else {
                    setFromDateSelection({ customDaysStr: Math.round(days).toString(), preset: DatePreset.Custom });
                }
            }
        },
    });

    // Get new scopes
    const [fetchLatest, { loading: loadingLatest, networkStatus: networkStatusScopes }] = useLazyQuery(
        GET_HOME_SCOPES,
        {
            notifyOnNetworkStatusChange: true,
            onError: (err) => {
                postErrorMessage({ title: `Error`, shortDesc: 'Failed to get data' });
                logger.error(err.message);
            },
            onCompleted: (data) => {
                setHomeScopes(data.homeScopes);
            },
        },
    );

    const refetchStatus = useMemo(() => {
        if (loadingLatest || networkStatusScopes === NetworkStatus.refetch || (loadingLastScopes && loadingLatest)) {
            return FeedStatus.Refreshing;
        } else {
            return FeedStatus.RefreshFeed;
        }
    }, [loadingLastScopes, loadingLatest, networkStatusScopes]);

    return (
        <BasicPage3 activeNavButton={SidebarNav.Home} browserPageTitle={getLabel(SidebarNav.Home)} onNav={props.onNav}>
            <Grid columns={['100%']} rows={['flex', 'min-content']} width="100%" height="100%" responsive>
                <Box gridArea="1/1/3/2" overflow={{ vertical: 'auto' }} height="100%" width="100%">
                    <Box height={{ min: 'max-content' }}>
                        {/* Page title */}
                        <Box
                            pad={{
                                left: DefaultPagePaddingPixels,
                                right: DefaultPagePaddingPixels,
                                top: 'small',
                                bottom: 'xsmall',
                            }}
                            border={{ side: 'bottom', color: 'border' }}
                            direction="row"
                            align="center"
                            justify="between"
                            height={{ min: 'max-content' }}
                        >
                            <PageTitle icon={getNavIcon(SidebarNav.Home)}>{getLabel(SidebarNav.Home)}</PageTitle>
                        </Box>
                        {/* Home page content */}
                        <Box
                            pad={{
                                left: DefaultPagePaddingPixels,
                                right: DefaultPagePaddingPixels,
                                top: 'small',
                                bottom: 'small',
                            }}
                            gap="xxsmall"
                        >
                            <Box height="100%" justify="between">
                                <Box fill overflow={{ vertical: 'auto' }}>
                                    <Box
                                        gap="small"
                                        alignSelf="center"
                                        width={{ width: '900px', min: '840px', max: '900px' }}
                                        height={{ min: 'fit-content' }}
                                        pad={{ horizontal: 'xsmall', bottom: 'medium', top: 'xsmall' }}
                                    >
                                        {/* No cached data */}
                                        {!homeScopes && (
                                            <ScopesEmpty message={`We're building your feed. Check back later.`} />
                                        )}

                                        {/* Filters and create automation button */}
                                        {homeScopes && (
                                            <Box
                                                direction="row"
                                                gap="small"
                                                align="center"
                                                justify="between"
                                                margin={{ bottom: 'xxsmall' }}
                                            >
                                                <Box direction="row" gap="small" align="center">
                                                    {!fromDateSelection && <Spinner width="18px" height="18px" />}
                                                    {fromDateSelection && (
                                                        <TimeSelectionToolbarButton
                                                            onDateSelection={(s) => {
                                                                setFromDateSelection(s);
                                                                fetchLatest({
                                                                    variables: {
                                                                        fromDate:
                                                                            timelineDateSelectionToTimelineCreateDate(
                                                                                s,
                                                                            ),
                                                                        cacheSessionKey: sessionId,
                                                                    },
                                                                });
                                                            }}
                                                            selection={fromDateSelection}
                                                            loading={loadingLastScopes}
                                                            forceRerenderOnPropsChange
                                                        />
                                                    )}
                                                    {homeScopes.scopes.length > 0 && (
                                                        <ScopeFilterDropdown
                                                            scopes={homeScopes.scopes.map((s) => ({
                                                                name: s.scope.itemName,
                                                                id: s.scope.id,
                                                                connector: s.scope.connector as ConnectorName,
                                                            }))}
                                                            selected={appFilter}
                                                            onSelectionChange={(s) => setAppFilter([...s])}
                                                        />
                                                    )}
                                                </Box>
                                                {homeScopes.scopes.length > 0 && (
                                                    <CreateAutomationDropdown
                                                        scopes={homeScopes.scopes.map((s) => s.scope)}
                                                    />
                                                )}
                                            </Box>
                                        )}
                                        {/* Feed status/refresh */}
                                        {homeScopes && (
                                            <Box alignSelf="center">
                                                <FeedFetchStatus
                                                    onRefresh={() => {
                                                        if (!fromDate) {
                                                            return;
                                                        }

                                                        // Reset the last load date and session id
                                                        // so that we can fetch new data
                                                        setLastLoadDateIso(undefined);
                                                        const newSessionId = Math.random().toString(36).substring(7);
                                                        setSessionId(newSessionId);
                                                        fetchLatest({
                                                            variables: {
                                                                fromDate,
                                                                cacheSessionKey: newSessionId,
                                                            },
                                                        });
                                                    }}
                                                    lastLoadIso={lastLoadDateIso}
                                                    status={refetchStatus}
                                                />
                                            </Box>
                                        )}

                                        {/* Scopes list */}
                                        {fromDateSelection && homeScopes && (
                                            <ScopesList
                                                fromDate={fromDateSelection}
                                                sessionCacheId={sessionId}
                                                appFilter={appFilter}
                                                homeScopes={homeScopes}
                                                loading={
                                                    loadingLastScopes ||
                                                    // show loading if nothing that is returned from the cache
                                                    (loadingLatest &&
                                                        (homeScopes == null || homeScopes.scopes.length === 0))
                                                }
                                            />
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </BasicPage3>
    );
}

export default withAuth(Home);
