// Copyright 2021
// ThatWorks.xyz Limited

import { useQuery } from '@apollo/client';
import { useStatsigClient } from '@statsig/react-bindings';
import { ConnectorName } from '@thatworks/connector-api';
import { Gates } from '@thatworks/shared-frontend/metrics';
import { joinPagesPaths, Pages, QueryParams } from '@thatworks/shared-frontend/pages';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ConnectorScopeInput, ItemGroupPredefinedType } from '../../../../__generated__/graphql';
import { BasicPage3, BasicPage3NavFunction } from '../../../../components/BasicPage3';
import Loading from '../../../../components/Loading';
import { getLabel, SidebarNav } from '../../../../components/SidebarNav';
import { useTelemetryContext } from '../../../../components/TelemetryContext';
import { useUserStateContext } from '../../../../components/UserContext';
import { withAuth } from '../../../../components/withAuth';
import { getHomePageSummarySettings } from '../home/ScopeSummary';
import { GET_TEMPLATE } from '../templates/components/template-queries';
import { MagicComposer } from './components/MagicComposer';
import { CreationMode } from './components/MagicComposerBuilder';
import { getDefaultQueryTemplateBlockProps, TemplateBlockState } from './components/TemplateBlock';
import { PresetFilter } from './helpers/preset-filters';

function MagicComposerPage(props: { onNav: BasicPage3NavFunction }): JSX.Element {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { logger } = useTelemetryContext();
    const { postErrorMessage } = useUserStateContext();
    const [initialBlocks, setInitialBlocks] = useState<TemplateBlockState[] | undefined>(undefined);
    const { checkGate } = useStatsigClient();

    // Get magic composer mode
    const mode = searchParams.get(QueryParams.Mode);

    // Check if we received a template ID to edit
    const editTemplateId = searchParams.get(QueryParams.Edit);

    // Check if we received a template ID to used as a base
    const baseTemplateId = searchParams.get(QueryParams.Template);

    const scopes = searchParams.get(QueryParams.Scopes);

    // Validate mode received and redirect if necessary
    useEffect(() => {
        // Check if we have received scopes
        let parsedScopes: ConnectorScopeInput[] | undefined;
        if (scopes) {
            try {
                parsedScopes = JSON.parse(decodeURIComponent(scopes));
                if (Array.isArray(parsedScopes)) {
                    const blocks = parsedScopes.map((scope) => {
                        const block = getDefaultQueryTemplateBlockProps();
                        block.selectedScopes = [scope];
                        block.preset = PresetFilter.Updates;
                        block.groupSettings = { groupType: ItemGroupPredefinedType.None };
                        block.summarizationCustomSettings = getHomePageSummarySettings(
                            scope.connector as ConnectorName,
                        );
                        block.title = scope.itemName;
                        return block;
                    });
                    setInitialBlocks(blocks);
                    setSearchParams((p) => {
                        p.delete(QueryParams.Scopes);
                        return p;
                    });
                }
            } catch (e) {}
        }

        // If we received an unknown mode, redirect the user to the create page without mode
        if (mode && !(Object.values(CreationMode) as string[]).includes(mode)) {
            navigate(joinPagesPaths([Pages.app.root, Pages.app.subs.create]));
        }

        // If we received a template ID and we are in post mode or without mode,
        // we should redirect the user to the create page without mode and without template ID
        if ((!mode || mode === CreationMode.Post) && editTemplateId) {
            navigate(joinPagesPaths([Pages.app.root, Pages.app.subs.create]));
        }

        // If we received a template ID to edit and use as base then redirect the user to the create page without mode
        if (editTemplateId && baseTemplateId) {
            navigate(joinPagesPaths([Pages.app.root, Pages.app.subs.create]));
        }
    }, [baseTemplateId, editTemplateId, mode, navigate, scopes, setSearchParams]);

    // Get a template if an id is provided
    const { data, loading, refetch } = useQuery(GET_TEMPLATE, {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        variables: { workspacePostTemplateId: (editTemplateId ?? baseTemplateId)! },
        onError: (error) => {
            postErrorMessage({ title: 'Error', shortDesc: `Failed to get template ${editTemplateId}` });
            logger.error(error.message);
        },
        skip: !editTemplateId && !baseTemplateId,
        fetchPolicy: 'no-cache',
        // to ensure data+loading is updated on refetch
        notifyOnNetworkStatusChange: true,
    });

    // Return
    return (
        <BasicPage3
            browserPageTitle={getLabel(SidebarNav.Create)}
            activeNavButton={SidebarNav.Create}
            onNav={props.onNav}
        >
            {loading && <Loading />}
            {!loading && (
                <MagicComposer
                    mode={mode as CreationMode}
                    edit={editTemplateId != null}
                    data={data?.workspacePostTemplate}
                    initialBlocks={initialBlocks}
                    onTemplateRestored={() => refetch()}
                    useV2={checkGate(Gates.ProsemirrorComposer)}
                />
            )}
        </BasicPage3>
    );
}

export default withAuth(MagicComposerPage);
