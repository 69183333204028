// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box, Spinner, Text } from 'grommet';
import { Refresh } from 'grommet-icons';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { IconButtonV2 } from '../../../../components/IconButton';
import { MAGIC_COMPOSER_CTA_BUTTON_HEIGHT } from '../magic-composer/components/MagicComposer';

export enum FeedStatus {
    Refreshing = 'refreshing',
    RefreshFeed = 'refresh-feed',
}

export function FeedFetchStatus(props: {
    status: FeedStatus;
    onRefresh: () => void;
    lastLoadIso?: string;
}): JSX.Element | null {
    const hoursSinceLastLoad = useMemo(() => {
        if (!props.lastLoadIso) {
            return undefined;
        }
        const hours = DateTime.fromISO(props.lastLoadIso).diffNow('hours').hours;
        return Math.abs(hours);
    }, [props.lastLoadIso]);

    if (props.status === FeedStatus.Refreshing) {
        return (
            <Box
                direction="row"
                align="center"
                gap="xxsmall"
                pad={{ horizontal: 'xsmall' }}
                height={MAGIC_COMPOSER_CTA_BUTTON_HEIGHT}
            >
                <Spinner width="14px" height="14px" />
                <Text size="14px">Refreshing</Text>
            </Box>
        );
    } else if (hoursSinceLastLoad != null && hoursSinceLastLoad < 1) {
        return null;
    } else {
        return (
            <Box direction="row" gap="xxsmall" align="center">
                {props.lastLoadIso && (
                    <Text size="14px">
                        Currently showing data from {DateTime.fromISO(props.lastLoadIso).toRelative()}
                    </Text>
                )}
                <IconButtonV2
                    icon={(hover) => (
                        <Box
                            pad="xxsmall"
                            style={{ borderRadius: '50%' }}
                            background={{ color: Colors.background_back }}
                            border={{ color: hover ? Colors.accent_3 : Colors.border_dark }}
                            elevation="xsmall"
                        >
                            <Refresh size="16px" color={hover ? Colors.accent_3 : undefined} />
                        </Box>
                    )}
                    onClick={props.onRefresh}
                    tooltip="Refresh feed"
                />
            </Box>
        );
    }
}
