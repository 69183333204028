// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { ConnectorName, getFormattedConnectorName } from '@thatworks/connector-api';
import { Box, Button, Layer, Text } from 'grommet';
import { Actions, CircleAlert, StatusGood, StatusWarning } from 'grommet-icons';
import { useState } from 'react';
import { ConnectorPermissionStatus, OrgWideConnectorConnectedState } from '../__generated__/graphql';
import { ThreeDotsVertical } from '../icons/ThreeDotsVertical';
import { ConnectorIconSmall } from './ConnectorIcon';
import { IconButtonV2 } from './IconButton';
import { TwHeading } from './TwHeading';
import { useUserStateContext } from './UserContext';

export function OrgWideConnectionButton(props: {
    connector: OrgWideConnectorConnectedState;
    width: string;
}): JSX.Element {
    const [showOverlay, setShowOverlay] = useState(false);
    const { organizationDisplayName } = useUserStateContext();

    return (
        <Box width={props.width || 'medium'}>
            <Box
                focusIndicator={false}
                border={{ side: 'bottom', size: '1px', color: Colors.border_dark }}
                width={{ width: '820px', max: '820px' }}
                direction="row"
                justify="between"
            >
                <Box align="center" direction="row" gap="xsmall" pad="15px">
                    <ConnectorIconSmall name={props.connector.connector as ConnectorName} sizePixels={`35px`} />
                    <Box width="100%" justify="evenly" gap="xxsmall">
                        <Box>
                            <Text size="14px" wordBreak="break-all">
                                Slack bot for {props.connector.accountDisplayName}
                            </Text>
                        </Box>
                        <Box direction="row" align="center" gap="4px">
                            <Actions size="10px" />
                            <Text size="10px">
                                This connection is shared across {organizationDisplayName || 'your organization'}
                            </Text>
                        </Box>
                    </Box>
                </Box>
                <Box direction="row" align="center" gap="xsmall">
                    <Box round="8px" direction="row" align="center" gap="6px">
                        {props.connector.permissionStatus === ConnectorPermissionStatus.Ok && (
                            <StatusGood size="16px" color="status-ok" />
                        )}
                        {props.connector.permissionStatus !== ConnectorPermissionStatus.Ok && (
                            <CircleAlert size="16px" color="status-critical" />
                        )}
                        <Text size="14px">Permissions</Text>
                    </Box>
                    <IconButtonV2
                        icon={(hover) => (
                            <ThreeDotsVertical width="14px" height="14px" color={hover ? Colors.brand : undefined} />
                        )}
                        onClick={() => setShowOverlay(true)}
                    />
                </Box>
            </Box>

            {showOverlay && (
                <Layer
                    onClickOutside={() => {
                        setShowOverlay(false);
                    }}
                    onEsc={() => {
                        setShowOverlay(false);
                    }}
                    margin={{ vertical: 'small' }}
                >
                    <Box height="100%" overflow={{ vertical: 'auto' }}>
                        <Box
                            pad={{ bottom: 'small', left: 'small', right: 'small' }}
                            width={{ max: '40vw', min: '100%' }}
                            height={{ min: 'max-content' }}
                        >
                            <Box pad="xsmall">
                                <Box pad={{ vertical: 'xsmall' }}>
                                    <Box direction="row" gap="xsmall" align="center">
                                        {props.connector.permissionStatus === ConnectorPermissionStatus.Ok ? (
                                            <StatusGood size="26px" color="status-ok" />
                                        ) : (
                                            <StatusWarning size="26px" color="status-error" />
                                        )}
                                        <TwHeading level={3}>
                                            {getFormattedConnectorName(props.connector.connector)}
                                        </TwHeading>
                                    </Box>
                                    <Box gap="xsmall">
                                        <Text size="small" wordBreak="break-all" color={Colors.dark_4}>
                                            Account: {props.connector.accountDisplayName}
                                        </Text>
                                        {props.connector.description && (
                                            <Text size="16px">{props.connector.description}</Text>
                                        )}
                                        {props.connector.deletionInstructions && (
                                            <Text size="16px">{props.connector.deletionInstructions}</Text>
                                        )}
                                    </Box>
                                </Box>

                                <Box direction="row" gap="xsmall">
                                    <Button label="Cancel" onClick={() => setShowOverlay(false)} />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Layer>
            )}
        </Box>
    );
}
