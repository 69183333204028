// Copyright 2021
// ThatWorks.xyz Limited

import { autoPlacement, autoUpdate, useDismiss, useFloating, useInteractions } from '@floating-ui/react';
import { Colors } from '@thatworks/colors';
import { ConnectorName } from '@thatworks/connector-api';
import { JiraObjectTypes } from '@thatworks/connector-changes/connector-object-types';
import { FilterHierarchyType } from '@thatworks/shared-frontend/collections';
import { Box, Text } from 'grommet';
import { Close, Magic, Search } from 'grommet-icons';
import MiniSearch from 'minisearch';
import { useMemo, useState } from 'react';
import {
    ActivityItemFilterOperator,
    ConnectorScopeInput,
    ConnectorWideScopeInput,
    GraphFilterType,
} from '../../../../../__generated__/graphql';
import { IconButtonV2 } from '../../../../../components/IconButton';
import { PlainTextInput } from '../../../../../components/PlainTextInput';
import { ToolbarButton } from '../../../../../components/prosemirror/ToolbarButton';
import { TextDropButton } from '../../../../../components/TextDrop';
import { MagicWand } from '../../../../../icons/MagicWand';
import { FontFamily } from '../../../../../theme';
import { getPresetDescription, getPresetTitle, PresetFilter } from '../helpers/preset-filters';
import { FilterToolbarButton } from './filter-toolbar-button/FilterToolbarButton';
import { PropertyFilterGroup } from './filter-toolbar-button/helpers';

enum PresetGroups {
    Updates = 'updates',
    StatusBased = 'statusBased',
    DueBy = 'dueBy',
    Priority = 'priority',
    Visualize = 'visualize',
}

// Preset filters that are available for each connector (empty array means no connector name is required)
const PresetFiltersByConnectorName: Record<PresetFilter, ConnectorName[]> = {
    [PresetFilter.AllToDo]: [
        ConnectorName.ASANA,
        ConnectorName.ATLASSIAN_JIRA,
        ConnectorName.CLICKUP,
        ConnectorName.GITHUB,
        ConnectorName.GITLAB,
        ConnectorName.LINEAR,
        // TODO: TWD-1252
        // ConnectorName.MONDAY,
    ],
    [PresetFilter.Charts]: [ConnectorName.ATLASSIAN_JIRA, ConnectorName.GOOGLE_ANALYTICS],
    [PresetFilter.Created]: [],
    [PresetFilter.DueThisWeek]: [
        ConnectorName.ASANA,
        ConnectorName.ATLASSIAN_JIRA,
        ConnectorName.CLICKUP,
        ConnectorName.LINEAR,
        ConnectorName.TRELLO,
    ],
    [PresetFilter.HighPriority]: [
        ConnectorName.ASANA,
        ConnectorName.ATLASSIAN_JIRA,
        ConnectorName.CLICKUP,
        ConnectorName.LINEAR,
    ],
    [PresetFilter.Indicators]: [
        ConnectorName.ASANA,
        ConnectorName.ATLASSIAN_JIRA,
        ConnectorName.CLICKUP,
        ConnectorName.GOOGLE_DRIVE,
        ConnectorName.LINEAR,
        ConnectorName.MONDAY,
        ConnectorName.TRELLO,
    ],
    [PresetFilter.InProgress]: [
        ConnectorName.ATLASSIAN_JIRA,
        ConnectorName.BITBUCKET,
        ConnectorName.CLICKUP,
        ConnectorName.GITHUB,
        ConnectorName.GITLAB,
        ConnectorName.LINEAR,
    ],
    [PresetFilter.ItemsNoChanges]: [
        ConnectorName.ASANA,
        ConnectorName.ATLASSIAN_JIRA,
        ConnectorName.CLICKUP,
        ConnectorName.GITHUB,
        ConnectorName.GITLAB,
        ConnectorName.LINEAR,
        // TODO: TWD-1252
        // ConnectorName.MONDAY,
    ],
    [PresetFilter.ItemsBlocked]: [
        ConnectorName.ASANA,
        ConnectorName.ATLASSIAN_JIRA,
        ConnectorName.CLICKUP,
        ConnectorName.LINEAR,
        ConnectorName.MONDAY,
        ConnectorName.NOTION,
    ],
    [PresetFilter.OverdueDueSoon]: [
        ConnectorName.ASANA,
        ConnectorName.ATLASSIAN_JIRA,
        ConnectorName.CLICKUP,
        ConnectorName.LINEAR,
        ConnectorName.TRELLO,
    ],
    [PresetFilter.ToDoWithUpdates]: [
        ConnectorName.ASANA,
        ConnectorName.ATLASSIAN_JIRA,
        ConnectorName.CLICKUP,
        ConnectorName.GITHUB,
        ConnectorName.GITLAB,
        ConnectorName.LINEAR,
        ConnectorName.MONDAY,
    ],
    [PresetFilter.Updates]: [],
    [PresetFilter.UpdatesByOthers]: [],
    [PresetFilter.WorkCompleted]: [
        ConnectorName.ASANA,
        ConnectorName.ATLASSIAN_JIRA,
        ConnectorName.BITBUCKET,
        ConnectorName.CLICKUP,
        ConnectorName.GITHUB,
        ConnectorName.GITLAB,
        ConnectorName.LINEAR,
        ConnectorName.MONDAY,
        ConnectorName.TRELLO,
    ],
};

// Preset filters that require a hierarchy type to be selected (empty array means no hierarchy type is required)
// IMPORTANT:
// - If the array is empty, it means that the preset filter can be used with any hierarchy type
// - These values are independent of the connector name.
const PresetFiltersByHierarchyType: Record<PresetFilter, FilterHierarchyType[]> = {
    [PresetFilter.AllToDo]: [],
    [PresetFilter.Charts]: [FilterHierarchyType.JiraBoard, FilterHierarchyType.JiraSprint],
    [PresetFilter.Created]: [],
    [PresetFilter.DueThisWeek]: [],
    [PresetFilter.HighPriority]: [],
    [PresetFilter.Indicators]: [],
    [PresetFilter.InProgress]: [],
    [PresetFilter.ItemsNoChanges]: [],
    [PresetFilter.ItemsBlocked]: [],
    [PresetFilter.OverdueDueSoon]: [],
    [PresetFilter.ToDoWithUpdates]: [],
    [PresetFilter.Updates]: [],
    [PresetFilter.UpdatesByOthers]: [],
    [PresetFilter.WorkCompleted]: [],
};

// Preset filters that require a object type to be selected (empty array means no hierarchy type is required)
// IMPORTANT:
// - If the array is empty, it means that the preset filter can be used with any hierarchy type
// - These values are independent of the connector name.
const PresetFiltersByObjectType: Record<PresetFilter, string[]> = {
    [PresetFilter.AllToDo]: [],
    [PresetFilter.Charts]: [JiraObjectTypes.Board, JiraObjectTypes.Sprint],
    [PresetFilter.Created]: [],
    [PresetFilter.DueThisWeek]: [],
    [PresetFilter.HighPriority]: [],
    [PresetFilter.Indicators]: [],
    [PresetFilter.InProgress]: [],
    [PresetFilter.ItemsNoChanges]: [],
    [PresetFilter.ItemsBlocked]: [],
    [PresetFilter.OverdueDueSoon]: [],
    [PresetFilter.ToDoWithUpdates]: [],
    [PresetFilter.Updates]: [],
    [PresetFilter.UpdatesByOthers]: [],
    [PresetFilter.WorkCompleted]: [],
};

const PresetMapping: Record<
    PresetGroups,
    { label: string; presets: { id: PresetFilter; title: string; description: string }[] }
> = {
    [PresetGroups.StatusBased]: {
        label: 'Status based',
        presets: [
            {
                id: PresetFilter.WorkCompleted,
                title: getPresetTitle(PresetFilter.WorkCompleted),
                description: getPresetDescription(PresetFilter.WorkCompleted),
            },
            {
                id: PresetFilter.InProgress,
                title: getPresetTitle(PresetFilter.InProgress),
                description: getPresetDescription(PresetFilter.InProgress),
            },
            {
                id: PresetFilter.AllToDo,
                title: getPresetTitle(PresetFilter.AllToDo),
                description: getPresetDescription(PresetFilter.AllToDo),
            },
            {
                id: PresetFilter.Created,
                title: getPresetTitle(PresetFilter.Created),
                description: getPresetDescription(PresetFilter.Created),
            },
            {
                id: PresetFilter.ToDoWithUpdates,
                title: getPresetTitle(PresetFilter.ToDoWithUpdates),
                description: getPresetDescription(PresetFilter.ToDoWithUpdates),
            },
        ],
    },
    [PresetGroups.Updates]: {
        label: 'Updates',
        presets: [
            {
                id: PresetFilter.Updates,
                title: getPresetTitle(PresetFilter.Updates),
                description: getPresetDescription(PresetFilter.Updates),
            },
            {
                id: PresetFilter.UpdatesByOthers,
                title: getPresetTitle(PresetFilter.UpdatesByOthers),
                description: getPresetDescription(PresetFilter.UpdatesByOthers),
            },
            {
                id: PresetFilter.ItemsNoChanges,
                title: getPresetTitle(PresetFilter.ItemsNoChanges),
                description: getPresetDescription(PresetFilter.ItemsNoChanges),
            },
        ],
    },
    [PresetGroups.DueBy]: {
        label: 'Due by',
        presets: [
            {
                id: PresetFilter.DueThisWeek,
                title: getPresetTitle(PresetFilter.DueThisWeek),
                description: getPresetDescription(PresetFilter.DueThisWeek),
            },
            {
                id: PresetFilter.OverdueDueSoon,
                title: getPresetTitle(PresetFilter.OverdueDueSoon),
                description: getPresetDescription(PresetFilter.OverdueDueSoon),
            },
        ],
    },
    [PresetGroups.Priority]: {
        label: 'Priority',
        presets: [
            {
                id: PresetFilter.HighPriority,
                title: getPresetTitle(PresetFilter.HighPriority),
                description: getPresetDescription(PresetFilter.HighPriority),
            },
            {
                id: PresetFilter.ItemsBlocked,
                title: getPresetTitle(PresetFilter.ItemsBlocked),
                description: getPresetDescription(PresetFilter.ItemsBlocked),
            },
        ],
    },
    [PresetGroups.Visualize]: {
        label: 'Visualize',
        presets: [
            {
                id: PresetFilter.Indicators,
                title: getPresetTitle(PresetFilter.Indicators),
                description: getPresetDescription(PresetFilter.Indicators),
            },
            {
                id: PresetFilter.Charts,
                title: getPresetTitle(PresetFilter.Charts),
                description: getPresetDescription(PresetFilter.Charts),
            },
        ],
    },
};

const MiniSearchPresets = new MiniSearch({
    fields: ['title', 'description'],
    storeFields: ['id', 'title', 'description', 'disabled'],
    searchOptions: {
        prefix: true,
        boost: { title: 2 },
        fuzzy: 0.2,
    },
});

function filterPresets(
    presets: { id: PresetFilter; title: string; description: string; disabled: boolean }[],
    textInput: string,
): { id: PresetFilter; title: string; description: string; disabled: boolean }[] {
    if (!textInput) {
        return presets;
    }

    // Add all presets received to be indexed
    MiniSearchPresets.removeAll();
    MiniSearchPresets.addAll(presets);

    // Search
    const filteredPresets = MiniSearchPresets.search(textInput);

    // Return filtered inputs
    return filteredPresets.map((p) => ({
        id: p.id,
        title: p.title,
        description: p.description,
        disabled: p.disabled,
    }));
}

function getPresetsFilteredByConnectorScopes(
    selectedConnectorScopes: ConnectorScopeInput[],
    selectedWideScopes: ConnectorWideScopeInput[],
): Record<
    PresetGroups,
    {
        label: string;
        presets: {
            id: PresetFilter;
            title: string;
            description: string;
            disabled: boolean;
        }[];
    }
> {
    // Get preset values
    const presets = structuredClone(PresetMapping);

    // Filter out the presets that are not available for the selected connectors
    const filteredPresets = Object.keys(presets).reduce(
        (acc, group) => {
            const filteredPresets = presets[group as PresetGroups].presets.map((preset) => {
                let connectorValid = false;
                let hierarchyValid = false;

                // Validate by connector
                if (PresetFiltersByConnectorName[preset.id].length === 0) {
                    connectorValid = true;
                } else {
                    connectorValid = selectedConnectorScopes.some((c) => {
                        return PresetFiltersByConnectorName[preset.id].includes(c.connector as ConnectorName);
                    });
                    if (!connectorValid) {
                        connectorValid = selectedWideScopes.some((c) => {
                            return PresetFiltersByConnectorName[preset.id].includes(c.connector as ConnectorName);
                        });
                    }
                }

                // Validate by hierarchy type
                if (PresetFiltersByHierarchyType[preset.id].length === 0) {
                    hierarchyValid = true;
                } else {
                    hierarchyValid = selectedConnectorScopes.some((c) => {
                        return PresetFiltersByHierarchyType[preset.id].includes(c.hierarchyType as FilterHierarchyType);
                    });
                }

                if (selectedWideScopes.length > 0 && !hierarchyValid) {
                    if (PresetFiltersByObjectType[preset.id].length === 0) {
                        hierarchyValid = true;
                    } else {
                        const allObjectTypes = selectedWideScopes.flatMap((c) => c.objectTypes);
                        hierarchyValid = allObjectTypes.some((o) => {
                            return PresetFiltersByObjectType[preset.id].includes(o);
                        });
                    }
                }

                // Return
                return { ...preset, disabled: !(connectorValid && hierarchyValid) };
            });

            acc[group as PresetGroups] = { ...presets[group as PresetGroups], presets: filteredPresets };
            return acc;
        },
        {} as Record<
            PresetGroups,
            { label: string; presets: { id: PresetFilter; title: string; description: string; disabled: boolean }[] }
        >,
    );

    // Return the presets already filtered by the connector scopes
    return filteredPresets;
}

export function ActivityPresetsButton(props: {
    preset?: PresetFilter | undefined;
    timelineId: string | undefined;
    dataLoading: boolean;
    onPresetSelection: (p: PresetFilter) => void;
    onChangePropertyFilters: (filters: PropertyFilterGroup[]) => void;
    onChangeFilterOperator: (operator: ActivityItemFilterOperator) => void;
    propertyFilterGroups: PropertyFilterGroup[];
    filtersOperator: ActivityItemFilterOperator;
    selectedConnectorScopes: ConnectorScopeInput[];
    selectedWideScopes: ConnectorWideScopeInput[];
    onChangeGraphFilterType: (graphFilterType: GraphFilterType) => void;
    graphFilterType: GraphFilterType;
    v2?: boolean;
}): JSX.Element {
    const [buttonActive, setButtonActive] = useState(false);
    const [textInput, setTextInput] = useState('');

    const { refs, floatingStyles, context } = useFloating({
        whileElementsMounted: autoUpdate,
        middleware: [
            autoPlacement({
                allowedPlacements: ['top-start', 'bottom-start'],
            }),
        ],
        open: buttonActive,
        onOpenChange: setButtonActive,
    });

    const dismiss = useDismiss(context);
    const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

    const presets = useMemo(() => {
        // Filter presets by connector scopes
        const presets = getPresetsFilteredByConnectorScopes(props.selectedConnectorScopes, props.selectedWideScopes);

        // Filter preset groups by text
        (Object.keys(presets) as Array<PresetGroups>).forEach((groupName) => {
            const filteredPresets = filterPresets(presets[groupName].presets, textInput);
            if (filteredPresets.length > 0) {
                presets[groupName].presets = filteredPresets;
            } else {
                delete presets[groupName];
            }
        });

        // Return
        return presets;
    }, [props.selectedConnectorScopes, props.selectedWideScopes, textInput]);

    return (
        <Box
            direction={props.v2 ? 'row' : undefined}
            gap={props.v2 ? 'xsmall' : undefined}
            align={props.v2 ? 'center' : undefined}
        >
            <Box ref={refs.setReference} {...getReferenceProps()}>
                <ToolbarButton
                    active={buttonActive || props.preset !== undefined}
                    icon={MagicWand}
                    onClick={async () => {
                        setButtonActive(!buttonActive);
                    }}
                    disabled={props.dataLoading}
                    label={
                        props.preset
                            ? getPresetTitle(props.preset)
                            : props.v2
                            ? 'Choose a summary preset'
                            : 'Choose query'
                    }
                    v2={props.v2}
                />
                {buttonActive && (
                    <div ref={refs.setFloating} style={{ ...floatingStyles }} {...getFloatingProps()}>
                        <Box
                            background={{ color: Colors.background_front }}
                            border={{ color: Colors.border_dark, size: '1px' }}
                            round={'10px'}
                            elevation="xsmall"
                            overflow={{ vertical: 'auto' }}
                            height={{ height: '70vh', max: '70vh' }}
                            gap="xsmall"
                            pad="xsmall"
                        >
                            {/* Search bar and close button */}
                            <Box height={{ min: 'max-content' }} direction="row" gap="xsmall" pad={{ bottom: '3px' }}>
                                <Box
                                    direction="row"
                                    gap="10px"
                                    align="center"
                                    flex
                                    border={{ side: 'bottom', color: Colors.brand }}
                                >
                                    <Search size="14px" color={Colors.brand} />
                                    <PlainTextInput
                                        value={textInput}
                                        // eslint-disable-next-line jsx-a11y/no-autofocus
                                        autoFocus
                                        placeholder="Search for preset"
                                        fontSize="14px"
                                        autoComplete="off"
                                        style={{
                                            fontFamily: FontFamily.Mono,
                                            background: 'unset',
                                            width: '100%',
                                        }}
                                        onChange={async (e) => setTextInput(e.target.value)}
                                    />
                                </Box>
                                <IconButtonV2
                                    icon={(hover) => <Close size="18px" color={hover ? Colors.brand : undefined} />}
                                    reverse
                                    onClick={() => setButtonActive(false)}
                                    alignSelf="end"
                                />
                            </Box>

                            {/* Preset groups */}
                            {Object.values(presets).map((g, gi) => (
                                <Box key={`group-${gi}`} height={{ min: 'max-content' }} gap="xxsmall">
                                    <Text
                                        size="14px"
                                        weight={500}
                                        color={Colors.dark_6}
                                        style={{
                                            fontFamily: FontFamily.Callout,
                                            textTransform: 'uppercase',
                                            letterSpacing: '2px',
                                        }}
                                    >
                                        {g.label}
                                    </Text>
                                    {g.presets.map((p, pi) => (
                                        <TextDropButton
                                            size="14px"
                                            pad="2px"
                                            onClick={async () => {
                                                setButtonActive(false);
                                                setTextInput('');
                                                props.onPresetSelection(p.id);
                                            }}
                                            key={`preset-${pi}`}
                                            spinnerColor="background-back"
                                            icon={<Magic size="20px" />}
                                            fontStyle={{ fontFamily: FontFamily.Mono, fontSize: '14px' }}
                                            active={props.preset === p.id}
                                            render={(hover, active) => (
                                                <Box>
                                                    {p.title}
                                                    <Text
                                                        size="12px"
                                                        color={hover || active ? '#ffffff' : Colors.dark_4}
                                                    >
                                                        {p.description}
                                                    </Text>
                                                </Box>
                                            )}
                                            disabled={p.disabled}
                                        />
                                    ))}
                                </Box>
                            ))}
                        </Box>
                    </div>
                )}
            </Box>
            {props.v2 && <Text size="14px">or</Text>}
            <FilterToolbarButton
                v2={props.v2}
                onChangePropertyFilters={(f) => props.onChangePropertyFilters(f)}
                onChangeFilterOperator={(o) => props.onChangeFilterOperator(o)}
                propertyFilterGroups={props.propertyFilterGroups}
                filtersOperator={props.filtersOperator}
                timelineId={props.timelineId}
                dataLoading={props.dataLoading}
                graphFilterType={props.graphFilterType}
                onChangeGraphFilterType={props.onChangeGraphFilterType}
            />
        </Box>
    );
}
