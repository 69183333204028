// Copyright 2021
// ThatWorks.xyz Limited

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useRemirrorContext } from '@remirror/react';
import { Colors } from '@thatworks/colors';
import { ConnectorName } from '@thatworks/connector-api';
import { BasePmNodeAttributes, QueryBlockNode } from '@thatworks/shared-frontend/prosemirror-nodes';
import { Box, Text } from 'grommet';
import { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { CommandFunction } from 'remirror';
import {
    ActivityItemFilterOperator,
    ItemGroupPredefinedType,
    SummarizationStyle,
} from '../../../../../__generated__/graphql';
import { HoverPlainTextInput } from '../../../../../components/PlainTextInput';
import {
    BaseReactNodeComponentProps,
    BaseReactNodeInjected,
} from '../../../../../components/prosemirror/BaseReactNode';
import { useTelemetryContext } from '../../../../../components/TelemetryContext';
import { useUserStateContext } from '../../../../../components/UserContext';
import { GitFork } from '../../../../../icons/GitFork';
import { MagicWand } from '../../../../../icons/MagicWand';
import { FontFamily } from '../../../../../theme';
import { FrontendSummarizedActivityNodeParser } from '../filters/activity-node-parser';
import { GET_TIMELINE_CHARTS, GET_TIMELINE_INDICATORS } from '../filters/ActivityLists';
import { ActivityPresetsButton } from '../filters/ActivityPresetsButton';
import { DEFAULT_GROUP_SETTINGS, GroupToolbarButton } from '../filters/GroupToolbarButton';
import { filterConnectorScopes } from '../filters/SearchItems';
import { SearchItemsToolbarButton } from '../filters/SearchItemsToolbarButton';
import { getSummarySettingsForSummaryStyle, SummaryStyleButton } from '../filters/SummaryStyleButton';
import { timelineDateSelectionToTimelineCreateDate } from '../filters/timeline-date-selection';
import { getFiltersForPreset, getPresetTitle } from '../helpers/preset-filters';
import { useComposerStateManager } from './ComposerStateManagerContext';
import { TemplateBlockHeader, TemplateNodeBase } from './PmTemplateNodeBaseComponents';
import { generateSummaryFromBlockState } from './summary-utils';
import {
    APPS_CONNECTED_OPTIONS,
    BlockType,
    CREATE_TIMELINE_FROM_SCOPES,
    GET_ACTIVITY,
    QueryTemplateBlockState,
} from './TemplateBlock';

export type QueryBlockAttributes = BasePmNodeAttributes;

export class QueryBlockNodeReact extends BaseReactNodeInjected<QueryBlockAttributes> {
    _injectedNode = new QueryBlockNode();

    ComponentToRender: FunctionComponent<BaseReactNodeComponentProps<QueryBlockAttributes>> = (props) => {
        // Telemetry and error handling
        const { logger } = useTelemetryContext();
        const { postErrorMessage } = useUserStateContext();

        // Composer state
        const { sessionCacheId, onUpdateBlock, dateSelection, blocks } = useComposerStateManager();

        // Remirror
        const { commands } = useRemirrorContext();
        const getNodePosition = props.getNodePosition;

        const [getConnectorsScopesTimeline, { loading: createTimelineLoading }] = useMutation(
            CREATE_TIMELINE_FROM_SCOPES,
            {
                onError: (error) => {
                    postErrorMessage({ title: 'Error', shortDesc: `Failed to fetch timeline` });
                    logger.error(error.message);
                },
            },
        );

        const [getSummarizedActivity, { loading: activityLoading }] = useLazyQuery(GET_ACTIVITY, {
            onError: ({ message, graphQLErrors }) => {
                if (graphQLErrors) {
                    for (const err of graphQLErrors) {
                        switch (err.extensions?.code) {
                            case 'CACHE_INVALID':
                                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                                getTimelineId();
                                return;
                        }
                    }
                }

                postErrorMessage({ title: 'Error', shortDesc: `Failed to summarize` });
                logger.error(message);
            },
        });

        const [getIndicators, { loading: indicatorLoading }] = useLazyQuery(GET_TIMELINE_INDICATORS, {
            onError: (error) => {
                postErrorMessage({ title: 'Error', shortDesc: `Failed to get indicators` });
                logger.error(error.message);
            },
        });
        const [getCharts, { loading: chartsLoading }] = useLazyQuery(GET_TIMELINE_CHARTS, {
            onError: (error) => {
                postErrorMessage({ title: 'Error', shortDesc: `Failed to get charts` });
                logger.error(error.message);
            },
        });

        const generateSummary = useCallback(
            async (timelineId: string, state: QueryTemplateBlockState) => {
                return generateSummaryFromBlockState(timelineId, state, {
                    getIndicators,
                    getCharts,
                    getSummarizedActivity,
                });
            },
            [getCharts, getIndicators, getSummarizedActivity],
        );

        const generateSummaryAndNotify = useCallback(
            async (state: QueryTemplateBlockState) => {
                if (!state.timelineId) {
                    onUpdateBlock({ ...state, summary: undefined });
                    return;
                }

                try {
                    const summary = await generateSummary(state.timelineId, state);
                    onUpdateBlock({ ...state, summary: summary.summary, selectedInsightIds: summary.indicatorIds });
                } catch (e) {
                    logger.exception(e);
                }
            },
            [generateSummary, logger, onUpdateBlock],
        );

        const { data: appsConnectedOptionsData, loading: appsConnectedOptionsLoading } = useQuery(
            APPS_CONNECTED_OPTIONS,
            {
                onError: (error) => {
                    postErrorMessage({ title: `Error`, shortDesc: 'Failed to get data apps connected options' });
                    logger.error(error.message);
                },
            },
        );

        const thisBlock = useMemo(() => {
            const found = blocks.find((b) => b.id === props.currentAttributes.uuid);
            if (!found || found.type !== BlockType.Query) {
                return undefined;
            }

            return found;
        }, [blocks, props.currentAttributes.uuid]);

        const getTimelineId = useCallback(() => {
            if (!thisBlock || (thisBlock.selectedScopes.length === 0 && thisBlock.selectedWideScopes.length === 0)) {
                return;
            }

            // No additional checks required because graphql will return cached data
            // if the array values are the same
            const selectedScopes = [...thisBlock.selectedScopes];
            const selectedWideScopes = [...thisBlock.selectedWideScopes];
            // stable sort-ish to aid with returning cached values
            selectedScopes.sort((a, b) => a.id.localeCompare(b.id));
            selectedWideScopes.sort((a, b) => a.connector.localeCompare(b.connector));
            getConnectorsScopesTimeline({
                variables: {
                    scopes: selectedScopes.map((selectedScope) => ({
                        connector: selectedScope.connector,
                        hierarchyType: selectedScope.hierarchyType,
                        id: selectedScope.id,
                        itemName: selectedScope.itemName,
                        itemUuid: selectedScope.itemUuid,
                    })),
                    wideScopes: selectedWideScopes.map((selectedWideScope) => ({
                        connector: selectedWideScope.connector,
                        objectTypes: selectedWideScope.objectTypes,
                    })),
                    fromDate: timelineDateSelectionToTimelineCreateDate(dateSelection),
                    cacheSessionKey: sessionCacheId,
                },
                onCompleted: (data) => {
                    const newState = { ...thisBlock, timelineId: data.timelineCreateFromScopes };
                    onUpdateBlock(newState);
                    generateSummaryAndNotify(newState);
                },
            });
        }, [
            dateSelection,
            generateSummaryAndNotify,
            getConnectorsScopesTimeline,
            onUpdateBlock,
            sessionCacheId,
            thisBlock,
        ]);

        const dataLoading = useMemo(() => {
            return activityLoading || indicatorLoading || chartsLoading || createTimelineLoading;
        }, [activityLoading, indicatorLoading, chartsLoading, createTimelineLoading]);

        useEffect(() => {
            getTimelineId();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [thisBlock?.selectedScopes, thisBlock?.selectedWideScopes, dateSelection]);

        // Add summary content as children to this node
        const addContentAsChildrenToThisNode = useCallback(() => {
            const cmd: CommandFunction = (cmdArgs) => {
                if (!cmdArgs.dispatch) {
                    return false;
                }

                if (!thisBlock || !thisBlock.summary) {
                    return false;
                }

                const nodePos = getNodePosition();
                if (nodePos == null) {
                    logger.error('Node position is null when trying to add content as children to the query node');
                    return false;
                }

                const parser = new FrontendSummarizedActivityNodeParser();
                const res = parser.toProseMirrorNodes(
                    // Setting title to undefined because it is handled via a separate ui input element below
                    undefined,
                    thisBlock.summary.type === 'activity'
                        ? { type: 'activity', activity: thisBlock.summary.activity }
                        : thisBlock.summary.type === 'charts'
                        ? { type: 'charts', chart: thisBlock.summary.charts }
                        : {
                              type: 'indicator',
                              indicator: thisBlock.selectedInsightIds
                                  ? {
                                        groupedInsights: thisBlock.summary.indicators.groupedInsights.filter((gi) =>
                                            thisBlock.selectedInsightIds.includes(gi.identifier),
                                        ),
                                        insights: thisBlock.summary.indicators.insights.filter((ind) =>
                                            thisBlock.selectedInsightIds.includes(ind.identifier),
                                        ),
                                        items: thisBlock.summary.indicators.items,
                                        metricBoxes: thisBlock.summary.indicators.metricBoxes.filter((mb) =>
                                            thisBlock.selectedInsightIds.includes(mb.identifier),
                                        ),
                                    }
                                  : thisBlock.summary.indicators,
                          },
                    cmdArgs.state.schema,
                    (_nodeName, message) => logger.error(`Failed parsing prosemirror node: ${message}`),
                );

                cmdArgs.tr.delete(nodePos + 1, nodePos + props.node.nodeSize - 1).insert(nodePos + 1, res);
                cmdArgs.dispatch(cmdArgs.tr);
                return true;
            };

            commands.customDispatch(cmd);
        }, [commands, thisBlock, getNodePosition, props.node.nodeSize, logger]);

        useEffect(() => {
            addContentAsChildrenToThisNode();
        }, [addContentAsChildrenToThisNode, thisBlock?.timelineId]);

        if (!thisBlock) {
            return null;
        }
        return (
            <TemplateNodeBase
                contentEditable={false}
                suppressContentEditableWarning={true}
                uuid={props.currentAttributes.uuid}
            >
                <TemplateBlockHeader type={BlockType.Query} showSpinner={dataLoading} showMinimize>
                    <Box direction="row" align="center" justify="between">
                        <Box gap="xxsmall">
                            {/* Data sources */}
                            <Box direction="row" align="center" gap="xxsmall">
                                <Box
                                    style={{ borderRadius: '50%', backgroundColor: Colors.background_back }}
                                    width={'20px'}
                                    height={'20px'}
                                    align="center"
                                    justify="center"
                                >
                                    <Text
                                        size="14px"
                                        weight="bold"
                                        color={Colors.dark_6}
                                        textAlign="center"
                                        style={{ fontFamily: FontFamily.Callout }}
                                    >
                                        1
                                    </Text>
                                </Box>
                                <SearchItemsToolbarButton
                                    appsConnectedOptions={appsConnectedOptionsData?.appsConnectedOptions || []}
                                    appsConnectedOptionsLoading={appsConnectedOptionsLoading}
                                    scopes={thisBlock.selectedScopes}
                                    wideScopes={thisBlock.selectedWideScopes}
                                    onScopesChange={(s, ws) => {
                                        // Filter out the selectedScopes that are already in the selectedWideScopes
                                        const newScopes = filterConnectorScopes(
                                            s,
                                            new Map(ws.map((ss) => [ss.connector, ss])),
                                        );

                                        // Create a new state with the new selectedScopes and the new selectedWideScopes
                                        const updatedState = {
                                            ...thisBlock,
                                            selectedWideScopes: ws,
                                            selectedScopes: newScopes,
                                        };

                                        // Summary customization based on selected scopes
                                        if (
                                            thisBlock.selectedScopes.length === 0 &&
                                            thisBlock.selectedWideScopes.length === 0
                                        ) {
                                            const isSlack =
                                                s.find((ss) => ss.connector === ConnectorName.SLACK) !== undefined ||
                                                ws.find((ss) => ss.connector === ConnectorName.SLACK) !== undefined;
                                            const hasWideScope = ws.length > 0;

                                            // For Slack, set the summarization style to Discussions
                                            if (isSlack) {
                                                updatedState.summarizationCustomSettings =
                                                    getSummarySettingsForSummaryStyle(SummarizationStyle.Discussions);
                                            }

                                            // For wide scopes or for Slack, set the group type to Parent
                                            if (hasWideScope || isSlack) {
                                                updatedState.groupSettings = {
                                                    groupType: ItemGroupPredefinedType.Parent,
                                                };
                                            }
                                        }

                                        // Update the block
                                        onUpdateBlock(updatedState);
                                    }}
                                    v2
                                />
                            </Box>
                            {/* Presets */}
                            <Box direction="row" align="center" gap="xxsmall">
                                <Box
                                    style={{ borderRadius: '50%', backgroundColor: Colors.background_back }}
                                    width={'20px'}
                                    height={'20px'}
                                    align="center"
                                    justify="center"
                                >
                                    <Text
                                        size="14px"
                                        weight="bold"
                                        color={Colors.dark_6}
                                        textAlign="center"
                                        style={{ fontFamily: FontFamily.Callout }}
                                    >
                                        2
                                    </Text>
                                </Box>
                                <ActivityPresetsButton
                                    v2
                                    preset={thisBlock.preset}
                                    timelineId={thisBlock.timelineId}
                                    dataLoading={dataLoading}
                                    onPresetSelection={(p) => {
                                        const propertyFilterGroups = getFiltersForPreset(p);
                                        const newState = {
                                            ...thisBlock,
                                            preset: p,
                                            title: thisBlock.title || getPresetTitle(p),
                                            propertyFilterGroups,
                                            filtersOperator: ActivityItemFilterOperator.Or,
                                        };
                                        generateSummaryAndNotify(newState);
                                    }}
                                    propertyFilterGroups={thisBlock.propertyFilterGroups}
                                    onChangePropertyFilters={(f) => {
                                        const newState = { ...thisBlock, propertyFilterGroups: f };
                                        generateSummaryAndNotify(newState);
                                    }}
                                    filtersOperator={thisBlock.filtersOperator}
                                    onChangeFilterOperator={(o) => {
                                        const newState = { ...thisBlock, filtersOperator: o };
                                        generateSummaryAndNotify(newState);
                                    }}
                                    selectedConnectorScopes={thisBlock.selectedScopes}
                                    selectedWideScopes={thisBlock.selectedWideScopes}
                                    graphFilterType={thisBlock.graphFilterType}
                                    onChangeGraphFilterType={(g) => {
                                        const newState = { ...thisBlock, graphFilterType: g };
                                        generateSummaryAndNotify(newState);
                                    }}
                                />
                            </Box>
                            {/* Grouping, Style */}
                            <Box direction="row" align="center" gap="xsmall">
                                <Box
                                    style={{ borderRadius: '50%', backgroundColor: Colors.background_back }}
                                    width={'20px'}
                                    height={'20px'}
                                    align="center"
                                    justify="center"
                                >
                                    <Text
                                        size="14px"
                                        weight="bold"
                                        color={Colors.dark_6}
                                        textAlign="center"
                                        style={{ fontFamily: FontFamily.Callout }}
                                    >
                                        3
                                    </Text>
                                </Box>
                                <Text size="14px">Customize summary</Text>
                                <GroupToolbarButton
                                    v2
                                    onChange={(group) => {
                                        const newState: QueryTemplateBlockState = { ...thisBlock };

                                        newState.groupSettings.groupType = group;
                                        // Reset subgroup ordering if group type changes
                                        // because the values/names will be different
                                        newState.groupSettings.subgroupOrdering = undefined;
                                        generateSummaryAndNotify(newState);
                                    }}
                                    group={thisBlock.groupSettings || DEFAULT_GROUP_SETTINGS}
                                    timelineLoading={dataLoading}
                                    subgroupNames={[]}
                                    onOrderingChange={(newOrder) => {
                                        const newState: QueryTemplateBlockState = { ...thisBlock };

                                        newState.groupSettings.subgroupOrdering = newOrder;
                                        generateSummaryAndNotify(newState);
                                    }}
                                    disabled={false}
                                    timelineId={thisBlock.timelineId}
                                />
                                <Text size="14px">and</Text>
                                <SummaryStyleButton
                                    v2
                                    onCustomSettingsChange={(customSettings) => {
                                        const newState: QueryTemplateBlockState = {
                                            ...thisBlock,
                                        };

                                        newState.summarizationCustomSettings = customSettings;
                                        generateSummaryAndNotify(newState);
                                    }}
                                    timelineId={thisBlock.timelineId}
                                    timelineLoading={dataLoading}
                                    summaryLoading={false}
                                    customSettings={
                                        thisBlock.summarizationCustomSettings ||
                                        getSummarySettingsForSummaryStyle(SummarizationStyle.Highlights)
                                    }
                                    disabled={false}
                                />
                            </Box>
                        </Box>
                    </Box>
                </TemplateBlockHeader>

                {!thisBlock.summary && (
                    <Box pad={{ horizontal: 'xsmall', vertical: 'small' }} direction="row" align="center" gap="xxsmall">
                        <Text color={Colors.status_disabled} size="14px">
                            Choose a
                        </Text>
                        <GitFork color={Colors.status_disabled} size="14px" />
                        <Text color={Colors.status_disabled} size="14px">
                            data source and
                        </Text>
                        <MagicWand color={Colors.status_disabled} size="14px" />
                        <Text color={Colors.status_disabled} size="14px">
                            summary preset to get started.
                        </Text>
                    </Box>
                )}

                {thisBlock.summary && (
                    <Box pad={{ horizontal: 'xsmall', top: 'small' }} direction="row" align="center" fill="horizontal">
                        <HoverPlainTextInput
                            hoverBgColor={Colors.border_light}
                            inputProps={{ fontSize: '1.5em' }}
                            inputAttr={{
                                placeholder: 'Optional Section Title',
                                onChange: (e) => {
                                    onUpdateBlock({ ...thisBlock, title: e.target.value });
                                },
                                maxLength: 100,
                            }}
                            style={{ width: '100%' }}
                            inputStyle={{
                                // match css style for heading
                                fontFamily: FontFamily.Standard,
                                fontSize: '1.5em',
                                color: '#444444',
                                background: 'unset',
                                padding: '0px',
                                overflow: 'hidden',
                                resize: 'none',
                                width: '100%',
                                fontWeight: 'bold',
                            }}
                            disableEdit={dataLoading}
                            value={thisBlock.title}
                        />
                    </Box>
                )}

                <Box ref={props.forwardRef} pad={{ horizontal: 'xsmall' }}>
                    {/* The prosemirror child nodes will be automatically rendered here because of forwardRef */}
                </Box>
            </TemplateNodeBase>
        );
    };
}
