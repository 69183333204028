// Copyright 2021
// ThatWorks.xyz Limited

import {
    FloatingFocusManager,
    FloatingOverlay,
    useClick,
    useDismiss,
    useFloating,
    useInteractions,
    useRole,
} from '@floating-ui/react';

export function FloatingLayer(props: {
    children: JSX.Element;
    open: boolean;
    onOpenChange: (open: boolean) => void;
}): JSX.Element {
    const data = useFloating({
        open: props.open,
        onOpenChange: props.onOpenChange,
    });
    const context = data.context;

    const click = useClick(context);
    const dismiss = useDismiss(context, { outsidePressEvent: 'mousedown' });
    const role = useRole(context);
    const { getFloatingProps } = useInteractions([click, dismiss, role]);

    return (
        <FloatingOverlay
            style={{
                placeItems: 'center',
                background: 'rgba(0, 0, 0, 0.7)',
                display: 'grid',
                zIndex: 1000,
            }}
        >
            <FloatingFocusManager context={context}>
                <div {...getFloatingProps()}>{props.children}</div>
            </FloatingFocusManager>
        </FloatingOverlay>
    );
}
