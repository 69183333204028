// Copyright 2021
// ThatWorks.xyz Limited

import objectHash from 'object-hash';
import {
    ActivityItemFiltersInput,
    GetTimelineMetricChartsQuery,
    GroupSettingsInput,
    InputMaybe,
    ItemSort,
    Scalars,
    TimelineActivityQuery,
    TimelineActivitySummarizationInput,
    TimelineIndicatorsQuery,
} from '../../../../../__generated__/graphql';
import { getActivityFilters } from '../filters/activity-property-helpers/from-filters';
import { DEFAULT_GROUP_SETTINGS } from '../filters/GroupToolbarButton';
import { getActivityQueryVars, PresetFilter } from '../helpers/preset-filters';
import { QueryTemplateBlockState, SHOW_ALL_INSIGHTS_SPECIAL_CASE_ID, TemplateBlockSummaryData } from './TemplateBlock';

export async function generateSummaryFromBlockState(
    timelineId: string,
    state: QueryTemplateBlockState,
    fn: {
        updateAsyncSymmaryId?: (id: string | undefined) => void;
        getIndicators: (props: {
            variables: { timelineId: string };
        }) => Promise<{ data: TimelineIndicatorsQuery | undefined }>;
        getCharts: (props: {
            variables: { timelineId: string };
        }) => Promise<{ data: GetTimelineMetricChartsQuery | undefined }>;
        getSummarizedActivity: (props: {
            variables: {
                idForCache: Scalars['ID']['input'];
                timelineId: Scalars['String']['input'];
                sort?: InputMaybe<ItemSort>;
                grouping?: InputMaybe<GroupSettingsInput>;
                filters: ActivityItemFiltersInput;
                summarize?: InputMaybe<TimelineActivitySummarizationInput>;
            };
        }) => Promise<{ data: TimelineActivityQuery | undefined }>;
    },
) {
    let summary: TemplateBlockSummaryData | undefined;
    const indicatorIds: string[] = [SHOW_ALL_INSIGHTS_SPECIAL_CASE_ID];
    let summaryId: string | undefined;
    if (timelineId) {
        // Indicators
        if (state.preset === PresetFilter.Indicators) {
            // Get indicators
            const variables = { timelineId };
            summaryId = objectHash(variables);
            fn.updateAsyncSymmaryId?.(summaryId);
            const d = await fn.getIndicators({ variables });

            // If we've received indicators data, update the summary
            if (d.data) {
                summary = { type: 'indicators', indicators: d.data.timelineIndicators };
                d.data.timelineIndicators.insights.forEach((insight) => indicatorIds.push(insight.identifier));
                d.data.timelineIndicators.groupedInsights.forEach((groupedInsight) =>
                    indicatorIds.push(groupedInsight.identifier),
                );
                d.data.timelineIndicators.metricBoxes.forEach((metricBox) => indicatorIds.push(metricBox.identifier));
            }
        }
        // Charts
        else if (state.preset === PresetFilter.Charts) {
            // Get charts
            const variables = { timelineId };
            summaryId = objectHash(variables);
            fn.updateAsyncSymmaryId?.(summaryId);
            const d = await fn.getCharts({ variables });

            // If we've received charts data, update the summary
            if (d.data) {
                summary = { type: 'charts', charts: d.data.timelineMetricCharts };
            }
        }
        // Other presets
        else if (state.propertyFilterGroups.length > 0 || state.preset) {
            // Get activity filters
            const f = getActivityQueryVars(
                state.groupSettings,
                getActivityFilters(
                    state.propertyFilterGroups,
                    state.filtersOperator,
                    state.graphFilterType,
                    state.dateSelection,
                ),
            );
            // the variables are used to generate a unique id for the cache
            // this way we can invalidate the cache when the variables change
            // but also reuse the cache when the variables are the same
            const variables = {
                timelineId,
                filters: f.filters,
                grouping: f.grouping || DEFAULT_GROUP_SETTINGS,
                sort: f.sort,
                summarize: {
                    summarize: true,
                    settings: state.summarizationCustomSettings,
                    // itemIds: [...selectedItems],
                },
            };
            const idForCache = objectHash(variables);
            summaryId = idForCache;
            fn.updateAsyncSymmaryId?.(summaryId);
            // Get summarized acitivity
            const d = await fn.getSummarizedActivity({
                variables: {
                    ...variables,
                    idForCache,
                },
            });

            // If we've received summarized activity data, update the summary
            if (d.data) {
                summary = { type: 'activity', activity: d.data.timelineActivity };
            }
        }
    } else if (state.title) {
        fn.updateAsyncSymmaryId?.(summaryId);
        summary = { type: 'activity', activity: { groups: [], items: [] } };
    } else {
        fn.updateAsyncSymmaryId?.(summaryId);
    }

    // Return summary
    return { summary, indicatorIds, summaryId };
}
