// Copyright 2021
// ThatWorks.xyz Limited

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Colors } from '@thatworks/colors';
import { Box, BoxExtendedProps, BoxProps, Spinner, Text } from 'grommet';
import { Contract, Expand, FormTrash } from 'grommet-icons';
import React, { useState } from 'react';
import { IconButtonV2 } from '../../../../../components/IconButton';
import { VerticalSixDots } from '../../../../../icons/VerticalSixDots';
import { FontFamily } from '../../../../../theme';
import { useComposerStateManager } from './ComposerStateManagerContext';
import { BlockType } from './TemplateBlock';

export const SUMMARY_BLOCK_HEADER_BG_COLOR = '#FAEEAA';
export const TEXT_BLOCK_HEADER_BG_COLOR = '#F3EEEB';

export function TemplateBlockHeader(props: {
    type: BlockType;
    children?: React.ReactNode;
    showSpinner?: boolean;
    showMinimize?: boolean;
    boxProps?: BoxProps;
}): JSX.Element {
    const [minimized, setMinimized] = useState(false);

    return (
        <Box
            background={{
                color: props.type === BlockType.Query ? SUMMARY_BLOCK_HEADER_BG_COLOR : TEXT_BLOCK_HEADER_BG_COLOR,
            }}
            round={{ corner: 'top', size: '15px' }}
            pad={{ horizontal: 'xsmall', vertical: 'xxsmall' }}
            contentEditable="false"
            suppressContentEditableWarning={true}
            gap="xxsmall"
            {...props.boxProps}
        >
            <Box
                direction="row"
                align="center"
                justify="between"
                onClick={props.showMinimize ? () => setMinimized(!minimized) : undefined}
            >
                <Box direction="row" align="center" gap="xxsmall">
                    <Text
                        style={{ fontFamily: FontFamily.Callout, textTransform: 'uppercase' }}
                        size="14px"
                        color="#5D565F"
                    >
                        {props.type === BlockType.Query ? `Summary Section` : `Custom Text Section`}
                    </Text>
                    {props.showSpinner && <Spinner width="14px" height="14px" />}
                </Box>
                {props.showMinimize && (
                    <IconButtonV2
                        icon={(hover) =>
                            minimized ? (
                                <Expand size="14px" color={hover ? Colors.accent_3 : undefined} />
                            ) : (
                                <Contract size="14px" color={hover ? Colors.accent_3 : undefined} />
                            )
                        }
                        onClick={() => setMinimized(!minimized)}
                    />
                )}
            </Box>
            {!minimized ? props.children : null}
        </Box>
    );
}

export function TemplateNodeBase(props: { uuid: string; children?: React.ReactNode } & BoxExtendedProps): JSX.Element {
    const { onDeleteBlock, updateActiveBlockId, activeBlockId } = useComposerStateManager();
    const [hover, setHover] = useState(false);

    // Drag and drop
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: props.uuid,
    });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: isDragging ? '100' : 'auto',
        opacity: isDragging ? 0.3 : 1,
    };

    return (
        <Box
            ref={setNodeRef}
            style={{ userSelect: 'none', cursor: 'unset', ...style }}
            direction="row"
            align="start"
            fill="horizontal"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            // detect ANY click event on the block to set it as active,
            onClick={() => {
                updateActiveBlockId(props.uuid, true);
            }}
            {...props}
        >
            <Box
                pad={{ top: '6px' }}
                gap="xxsmall"
                width={'22px'}
                contentEditable="false"
                suppressContentEditableWarning
                style={{ userSelect: 'none' }}
            >
                {hover && (
                    <Box gap="xxsmall" animation={[{ type: 'fadeIn', duration: 100 }]}>
                        <IconButtonV2
                            icon={(hover) => (
                                <VerticalSixDots
                                    size="22px"
                                    color={hover ? Colors.brand : undefined}
                                    {...listeners}
                                    {...attributes}
                                />
                            )}
                        />
                        <IconButtonV2
                            icon={(hover) => (
                                <FormTrash
                                    size="22px"
                                    color={hover ? Colors.brand : undefined}
                                    onClick={() => onDeleteBlock(props.uuid)}
                                />
                            )}
                        />
                    </Box>
                )}
            </Box>
            <Box
                border={{ color: activeBlockId === props.uuid ? Colors.accent_3 : Colors.border_dark, size: '1px' }}
                round="15px"
                margin={{ bottom: 'xsmall' }}
                fill="horizontal"
            >
                {props.children}
            </Box>
        </Box>
    );
}
