// Copyright 2021
// ThatWorks.xyz Limited

import { useAuth0 } from '@auth0/auth0-react';
import { Spinner } from 'grommet';
import { useEffect } from 'react';
import { AuthProvider, setAuthProviderInSessionStorage } from '../../../components/AuthProvider';
import { AUTH0_AUDIENCE, AUTH0_SCOPES } from '../../../shared/auth0-props';

export default function LoginPage(): JSX.Element {
    const { loginWithRedirect } = useAuth0();

    useEffect(() => {
        (async () => {
            setAuthProviderInSessionStorage(AuthProvider.Auth0);
            await loginWithRedirect({ authorizationParams: { scope: AUTH0_SCOPES, audience: AUTH0_AUDIENCE } });
        })();
    }, [loginWithRedirect]);

    return <Spinner />;
}
