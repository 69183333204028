// Copyright 2021
// ThatWorks.xyz Limited

import { useMutation } from '@apollo/client';
import { useStatsigClient } from '@statsig/react-bindings';
import { Colors } from '@thatworks/colors';
import { Events } from '@thatworks/shared-frontend/metrics';
import { joinPagesPaths, Pages } from '@thatworks/shared-frontend/pages';
import { Box, Spinner, Text } from 'grommet';
import { Close } from 'grommet-icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { gql } from '../../../../../__generated__';
import { SlackChannelMention, SlackTeamChannels } from '../../../../../__generated__/graphql';
import { CtaButtonSpinnerV2 } from '../../../../../components/CtaButtonSpinner';
import { IconButtonV2 } from '../../../../../components/IconButton';
import { useTelemetryContext } from '../../../../../components/TelemetryContext';
import { useUserStateContext } from '../../../../../components/UserContext';
import { FontFamily } from '../../../../../theme';
import { DELETE_TEMPLATE_DRAFT } from '../../templates/components/template-queries';
import { TemplatePreview } from '../../templates/components/TemplatePreview';
import {
    SHARE_SECTION_BOX_PROPS,
    SHARE_SECTION_TITLE_PROPS,
    ShareEmail,
    ShareThatWorks,
    ShareTitleSection,
} from './ShareComponents';
import { SlackChannelsCached } from './SlackChannelCached';

const SAVE_WORKSPACE_POST = gql(/* GraphQL */ `
    mutation WorkspacePostCreate(
        $organizationId: ID!
        $workspaceId: ID!
        $title: String!
        $content: String!
        $slackNotifications: [SlackNotificationCreate!]!
        $slackChannelMention: SlackChannelMention
        $emailNotifications: [String!]!
    ) {
        workspacePostCreate(
            organizationId: $organizationId
            workspaceId: $workspaceId
            title: $title
            content: $content
            slackNotifications: $slackNotifications
            slackChannelMention: $slackChannelMention
            emailNotifications: $emailNotifications
        )
    }
`);

export function MagicComposerShareTo(props: {
    title: string;
    summaryContent: string;
    onCloseModal: () => void;
    loadingSummary: boolean;
    loadingTitle: boolean;
}): JSX.Element {
    const navigate = useNavigate();
    const { logger } = useTelemetryContext();
    const { organizationId, postErrorMessage } = useUserStateContext();
    const [workspace, setWorkspace] = useState<{ id: string; label: string }>();
    const [content, setContent] = useState<string>(props.summaryContent);
    const [slackNotifications, setSlackNotifications] = useState<SlackTeamChannels[]>([]);
    const [slackChannelMention, setSlackChannelMention] = useState<SlackChannelMention>(SlackChannelMention.None);
    const [emailNotifications, setEmailNotifications] = useState<string[]>([]);
    const [title, setTitle] = useState(props.title);
    const { logEvent } = useStatsigClient();

    const [deleteDraft] = useMutation(DELETE_TEMPLATE_DRAFT, {
        onError: (e) => {
            console.error(e);
        },
    });

    useEffect(() => {
        setTitle(props.title);
    }, [props.title]);

    const goToTopic = useCallback(
        (workspaceId: string) => {
            navigate(`${joinPagesPaths([Pages.app.root, Pages.app.subs.topics.root])}/${workspaceId}`);
        },
        [navigate],
    );

    const [savePost] = useMutation(SAVE_WORKSPACE_POST, {
        onError: (err) => {
            postErrorMessage({ title: `Error`, shortDesc: 'Failed to publish post' });
            logger.error(err.message);
        },
    });

    const sharePost = useCallback(async () => {
        if (organizationId && workspace && content) {
            const result = await savePost({
                variables: {
                    organizationId,
                    workspaceId: workspace.id,
                    title,
                    content,
                    slackNotifications: slackNotifications.map((n) => ({
                        channelIds: n.channels.map((c) => c.id),
                        teamId: n.teamId,
                    })),
                    slackChannelMention,
                    emailNotifications,
                },
            });
            logEvent(Events.FrontendComposerPublished);

            if (result && !result.errors) {
                await deleteDraft();
                goToTopic(workspace.id);
            }
        }
    }, [
        organizationId,
        workspace,
        content,
        savePost,
        title,
        slackNotifications,
        slackChannelMention,
        emailNotifications,
        logEvent,
        deleteDraft,
        goToTopic,
    ]);

    const loading = useMemo(
        () => props.loadingSummary || props.loadingTitle,
        [props.loadingTitle, props.loadingSummary],
    );

    return (
        <Box
            background={Colors.light_2}
            round="15px"
            width={{ min: '800px', width: '1000px', max: '1400px' }}
            height="80vh"
            gap="xsmall"
            overflow={{ vertical: 'auto' }}
        >
            {loading && (
                <Box fill justify="center">
                    <Spinner alignSelf="center" />
                </Box>
            )}
            {!loading && (
                <Box height={{ min: 'max-content' }}>
                    <Box gap="xsmall">
                        {/* Title and close button */}
                        <Box
                            direction="row"
                            justify="between"
                            align="center"
                            pad={{ horizontal: 'small', top: 'xsmall' }}
                        >
                            <Text style={{ fontFamily: FontFamily.Callout }} size="23px" weight={'bold'}>
                                Share
                            </Text>
                            <IconButtonV2
                                icon={(hover) => <Close size="18px" color={hover ? Colors.brand : undefined} />}
                                reverse
                                onClick={props.onCloseModal}
                            />
                        </Box>

                        {/* Post title */}
                        <ShareTitleSection title={title} onChange={setTitle} />

                        {/* Publish */}
                        <Box {...SHARE_SECTION_BOX_PROPS}>
                            <Text {...SHARE_SECTION_TITLE_PROPS}>Where to publish</Text>
                            {/* Workspace/Topic */}
                            <ShareThatWorks workspace={workspace} onSelected={(s) => setWorkspace(s)} />
                            {/* Share to aka Slack notifications */}
                            <SlackChannelsCached
                                onSelectionChange={setSlackNotifications}
                                existingNotifications={slackNotifications}
                                channelMention={slackChannelMention}
                                onChannelMentionChange={setSlackChannelMention}
                            />
                            {/* Share email */}
                            <ShareEmail emails={emailNotifications} onEmailsUpdate={setEmailNotifications} />
                        </Box>

                        {/* Share button */}
                        <Box
                            {...SHARE_SECTION_BOX_PROPS}
                            direction="row"
                            pad={{ top: 'xxsmall', bottom: 'xsmall', left: 'small' }}
                        >
                            <CtaButtonSpinnerV2
                                label="Share Now"
                                style={{ padding: '1px 14px' }}
                                onClick={sharePost}
                                disabled={!workspace || title === ''}
                            />
                        </Box>

                        {/* Preview and edit content */}
                        <Box pad={{ horizontal: 'small' }}>
                            <Text {...SHARE_SECTION_TITLE_PROPS}>Post Preview</Text>
                            <Text size="14px">Tip: you can edit the text before sharing.</Text>
                        </Box>
                        <Box
                            background="white"
                            round={'15px'}
                            pad={{ horizontal: 'xsmall' }}
                            height={{ min: 'max-content' }}
                            margin={{ horizontal: 'small', bottom: 'small' }}
                        >
                            {/* Template title */}
                            <Box pad={{ top: 'xsmall' }}>
                                <Text weight="bold" size="26px">
                                    {title}
                                </Text>
                            </Box>
                            <Box flex>
                                <TemplatePreview
                                    initialContent={JSON.parse(content)}
                                    // empty because initialContent is set
                                    blocks={[]}
                                    onChange={(content) => setContent(content)}
                                    editable={true}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
}
