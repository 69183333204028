// Copyright 2021
// ThatWorks.xyz Limited

import { Box, Text } from 'grommet';
import { StatusWarning } from 'grommet-icons';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { WorkspacePost } from '../../../__generated__/graphql';
import Loading from '../../../components/Loading';
import Api from '../../../shared/Api';
import { PublicWorkspacePost } from './PublicWorkspacePost';

export function PublicLinkOutlet(props: { type: 'post' | 'token' }): JSX.Element {
    // publicPostUuid and slackTokenId are defined in param-keys.ts
    const { publicPostUuid, slackTokenId } = useParams();

    const [loading, setLoading] = useState(true);
    const [post, setPost] = useState<WorkspacePost | undefined>(undefined);
    const [error, setError] = useState<{ title: string; message?: string } | undefined>(undefined);

    useEffect(() => {
        const fetchData = async () => {
            const uuid = props.type === 'post' ? publicPostUuid : slackTokenId;
            if (!uuid) {
                setError({ title: 'No post to fetch' });
                setLoading(false);
                return;
            }

            const notFoundErrorMessage =
                props.type === 'post'
                    ? { title: 'Post not found' }
                    : { title: 'Not found or expired.', message: `Please re-open the link from Slack.` };

            try {
                const route =
                    props.type === 'post'
                        ? [Api.routes.PUBLIC, 'posts', uuid]
                        : [Api.routes.PUBLIC, 'posts', 'slack', uuid];
                const post = await Api.get<WorkspacePost>(route);
                if (!post || Object.keys(post).length === 0) {
                    setError(notFoundErrorMessage);
                    setLoading(false);
                    return;
                }
                setPost(post);
                setLoading(false);
            } catch (error) {
                setError(notFoundErrorMessage);
                setLoading(false);
            }
        };

        fetchData();
    }, [props.type, publicPostUuid, slackTokenId]);

    if (loading) {
        return <Loading />;
    }

    if (error || !post) {
        return (
            <Box
                align="center"
                justify="center"
                pad="small"
                border={{ color: 'status-critical', size: '2px' }}
                round="10px"
                gap="xxsmall"
                alignSelf="center"
                width={{ width: 'medium', min: 'medium', max: 'medium' }}
            >
                {
                    <Box direction="row" gap="4px" align="center">
                        <StatusWarning size="18px" />{' '}
                        <Text weight={'bold'}>{error?.title || 'Failed to get post'}</Text>
                    </Box>
                }
                {error && <Text textAlign="center">{error.message}</Text>}
            </Box>
        );
    }

    return <PublicWorkspacePost post={post} />;
}
