// Copyright 2021
// ThatWorks.xyz Limited

import { AutomationScheduleInput } from '../../../../../__generated__/graphql';

export type TimeInSchedule = Omit<Omit<AutomationScheduleInput, 'every'>, 'isoDayOfWeek'>;

export enum HalfHourTimeInterval {
    AM0600 = '6:00 AM',
    AM0630 = '6:30 AM',
    AM0700 = '7:00 AM',
    AM0730 = '7:30 AM',
    AM0800 = '8:00 AM',
    AM0830 = '8:30 AM',
    AM0900 = '9:00 AM',
    AM0930 = '9:30 AM',
    AM1000 = '10:00 AM',
    AM1030 = '10:30 AM',
    AM1100 = '11:00 AM',
    AM1130 = '11:30 AM',
    PM1200 = '12:00 PM',
    PM1230 = '12:30 PM',
    PM0100 = '1:00 PM',
    PM0130 = '1:30 PM',
    PM0200 = '2:00 PM',
    PM0230 = '2:30 PM',
    PM0300 = '3:00 PM',
    PM0330 = '3:30 PM',
    PM0400 = '4:00 PM',
    PM0430 = '4:30 PM',
    PM0500 = '5:00 PM',
    PM0530 = '5:30 PM',
    PM0600 = '6:00 PM',
    PM0630 = '6:30 PM',
    PM0700 = '7:00 PM',
    PM0730 = '7:30 PM',
    PM0800 = '8:00 PM',
    PM0830 = '8:30 PM',
    PM0900 = '9:00 PM',
    PM0930 = '9:30 PM',
    PM1000 = '10:00 PM',
    PM1030 = '10:30 PM',
    PM1100 = '11:00 PM',
    PM1130 = '11:30 PM',
    AM1200 = '12:00 AM',
    AM1230 = '12:30 AM',
    AM0100 = '1:00 AM',
    AM0130 = '1:30 AM',
    AM0200 = '2:00 AM',
    AM0230 = '2:30 AM',
    AM0300 = '3:00 AM',
    AM0330 = '3:30 AM',
    AM0400 = '4:00 AM',
    AM0430 = '4:30 AM',
    AM0500 = '5:00 AM',
    AM0530 = '5:30 AM',
}

export enum DaysOfWeek {
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday',
    Sunday = 'Sunday',
}

export function getIsoWeekday(day: DaysOfWeek): number {
    switch (day) {
        case DaysOfWeek.Monday:
            return 1;
        case DaysOfWeek.Tuesday:
            return 2;
        case DaysOfWeek.Wednesday:
            return 3;
        case DaysOfWeek.Thursday:
            return 4;
        case DaysOfWeek.Friday:
            return 5;
        case DaysOfWeek.Saturday:
            return 6;
        case DaysOfWeek.Sunday:
            return 7;
    }
}

export function getDaysOfWeekFromIsoDay(isoDay: number): DaysOfWeek {
    switch (isoDay) {
        case 1:
            return DaysOfWeek.Monday;
        case 2:
            return DaysOfWeek.Tuesday;
        case 3:
            return DaysOfWeek.Wednesday;
        case 4:
            return DaysOfWeek.Thursday;
        case 5:
            return DaysOfWeek.Friday;
        case 6:
            return DaysOfWeek.Saturday;
        case 7:
            return DaysOfWeek.Sunday;
        default:
            throw new Error(`Invalid isoDay ${isoDay}`);
    }
}

export function to24Hour(time: HalfHourTimeInterval): { hour: number; minute: number } {
    // HalfHourTimeInterval has time formatted as '12:00 AM' or '12:30 PM'
    // We need to convert it to 24 hour format. For  '12:00 AM' it would be { hour: 0, minute: 0 }
    // For '12:30 PM' it would be { hour: 12, minute: 30 }
    const timeParts = time.split(' ');
    const [hour, minute] = timeParts[0].split(':').map((part) => parseInt(part));
    if (timeParts[1] === 'PM' && hour !== 12) {
        return { hour: hour + 12, minute };
    } else if (timeParts[1] === 'AM' && hour === 12) {
        return { hour: 0, minute };
    }
    return { hour, minute };
}

export function scheduleToTime(schedule: TimeInSchedule): HalfHourTimeInterval {
    const { hour, minute } = schedule;
    const isPM = hour >= 12;
    const h = hour % 12;
    const m = minute === 0 ? '00' : '30';
    const amPM = isPM ? 'PM' : 'AM';
    if (h === 0) {
        return `${12}:${m} ${amPM}` as HalfHourTimeInterval;
    }
    return `${h}:${m} ${amPM}` as HalfHourTimeInterval;
}

export function twentyFourHrsTo12Hrs(hours: number, minute: number): string {
    let h = hours;
    const m = minute.toString().padStart(2, '0');
    let ampm = 'AM';
    if (h >= 12) {
        ampm = 'PM';
        h = h - 12;
    }
    if (h === 0) {
        h = 12;
    }
    return `${h}:${m} ${ampm}`;
}
