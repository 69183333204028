// Copyright 2021
// ThatWorks.xyz Limited

import { createContext, useContext } from 'react';
import { DatePreset, TimelineDateSelection } from '../filters/timeline-date-selection';
import { BlockType, TemplateBlockState } from './TemplateBlock';

export interface ComposerStateManager {
    sessionCacheId: string;
    dateSelection: TimelineDateSelection;
    blocks: TemplateBlockState[];
    activeBlockId: string | undefined;
    onAddBlock: (type: BlockType) => void;
    onUpdateBlock: (block: TemplateBlockState) => void;
    onDeleteBlock: (blockId: string) => void;
    updateActiveBlockId: (blockId: string, active: boolean) => void;
    onChangeBlockIndex: (activeId: string, overId: string | undefined) => void;
    onLoading: (loading: boolean) => void;
}

const DEFAULT_STATE_MANAGER: ComposerStateManager = {
    blocks: [],
    sessionCacheId: '',
    dateSelection: {
        preset: DatePreset.OneWeek,
        customDaysStr: '',
    },
    activeBlockId: undefined,
    onAddBlock: () => {
        throw new Error('not implemented');
    },
    onUpdateBlock: () => {
        throw new Error('not implemented');
    },
    onDeleteBlock: () => {
        throw new Error('not implemented');
    },
    updateActiveBlockId: () => {
        throw new Error('not implemented');
    },
    onChangeBlockIndex: () => {
        throw new Error('not implemented');
    },
    onLoading: () => {
        throw new Error('not implemented');
    },
};

export const ComposerStateManagerContext = createContext<ComposerStateManager>(DEFAULT_STATE_MANAGER);
export const useComposerStateManager = () => useContext(ComposerStateManagerContext);
