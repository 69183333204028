// Copyright 2021
// ThatWorks.xyz Limited

import { useQuery } from '@apollo/client';
import { Box, Layer, Spinner } from 'grommet';
import { gql } from '../../../../__generated__';
import { useTelemetryContext } from '../../../../components/TelemetryContext';
import { useUserStateContext } from '../../../../components/UserContext';
import { WorkspacePostErrorBox } from './components/WorkspacePostErrorBox';
import { WorkspacePost } from './WorkspacePost';

const GET_WORKSPACE_POST = gql(/* GraphQL */ `
    query GetWorkspacePost($workspaceId: ID!, $postId: ID!) {
        workspacePost(workspaceId: $workspaceId, postId: $postId) {
            post {
                id
                title
                content
                visibility
                userAbilities
                creators {
                    name
                }
                created
                updated
                template {
                    templateId
                    templateName
                    automation {
                        fromDateIso
                        toDateIso
                    }
                    creatorName
                }
            }
            permissionError
        }
    }
`);

function WorkspacePostExpansion(props: {
    workspaceId: string;
    postId: string;
    onEdit: (postTitle: string, postContent: string) => void;
    onDelete: () => Promise<void>;
}): JSX.Element | null {
    const { postErrorMessage } = useUserStateContext();
    const { logger } = useTelemetryContext();

    const { loading, data } = useQuery(GET_WORKSPACE_POST, {
        variables: {
            workspaceId: props.workspaceId,
            postId: props.postId,
        },
        onError: (err) => {
            postErrorMessage({ title: `Error`, shortDesc: 'Failed to get post' });
            logger.error(err.message);
        },
    });

    if (loading) {
        return (
            <Box pad="medium">
                <Spinner />
            </Box>
        );
    }

    if (!data) {
        return <WorkspacePostErrorBox message="😔 Failed to get this post." />;
    }

    if (data.workspacePost.permissionError) {
        return (
            <WorkspacePostErrorBox
                message="😔 You have no permissions to view this post. Please contact the admin of this
            topic."
            />
        );
    }

    if (!data.workspacePost.post) {
        return <WorkspacePostErrorBox message="😔 Failed to get this post." />;
    }

    return (
        <WorkspacePost
            handleOverflow
            plain
            onDelete={props.onDelete}
            onEdit={() => {
                if (data.workspacePost.post) {
                    props.onEdit(data.workspacePost.post.title, data.workspacePost.post.content);
                }
            }}
            post={data.workspacePost.post}
            workspaceId={props.workspaceId}
        />
    );
}

export function WorkspacePostExpansionModal(props: {
    workspaceId: string;
    postId: string;
    onClose: () => void;
    onEdit: (postTitle: string, postContent: string) => void;
    onDelete: () => Promise<void>;
}): JSX.Element {
    return (
        <Layer
            modal
            onClickOutside={() => props.onClose()}
            onEsc={() => props.onClose()}
            background={{ color: 'unset' }}
        >
            <Box height={{ max: '80vh' }} background="background-front" round="6px" pad="2px">
                <Box gap="small" alignSelf="center" width={{ width: '900px', min: '840px', max: '900px' }}>
                    <WorkspacePostExpansion
                        workspaceId={props.workspaceId}
                        postId={props.postId}
                        onDelete={async () => {
                            await props.onDelete();
                            props.onClose();
                        }}
                        onEdit={(title, content) => {
                            props.onEdit(title, content);
                            props.onClose();
                        }}
                    />
                </Box>
            </Box>
        </Layer>
    );
}
