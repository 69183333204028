// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box, Button, Text, Tip } from 'grommet';
import { useMemo } from 'react';
import { ONBOARD_BANNER_HEADING_FONT_SIZE, OnboardBanner } from '../../../../components/OnboardBanner';
import { OnboardProgressIndicator } from '../../../../components/OnboardProgressIndicator';
import { getNavIcon, SidebarNav } from '../../../../components/SidebarNav';
import { FontFamily } from '../../../../theme';

export const CREATE_SUMMARY_LABEL = 'Create your first summary';
const ConnectIcon = getNavIcon(SidebarNav.Connect);

export function createFirstSummaryCtaEnabled(connectedCount: number): boolean {
    return connectedCount > 0;
}

export function OnboardConnect(props: { connectedCount: number; onClick: () => void }): JSX.Element {
    const bodyText = useMemo(() => {
        if (props.connectedCount === 0) {
            return `Connect your apps to get the most out of your data. Click on the icons to get started.`;
        }
        if (props.connectedCount === 1) {
            return `🎉 Nice! Connect at least two apps to get the most out of That Works.`;
        }
        return `🎉 Nice! Connect more apps to get the most out of That Works.`;
    }, [props.connectedCount]);

    return (
        <OnboardBanner>
            <OnboardProgressIndicator step={'connect'} />
            <Box direction="row" align="center" gap="xsmall">
                <ConnectIcon size="28px" color={Colors.black} />
                <Text
                    style={{ fontFamily: FontFamily.Callout }}
                    size={ONBOARD_BANNER_HEADING_FONT_SIZE}
                    color={Colors.black}
                >
                    Connect your apps
                </Text>
            </Box>
            <Text textAlign="center">{bodyText}</Text>
            <Tip
                content={props.connectedCount === 0 ? 'First connect your apps!' : undefined}
                plain={props.connectedCount > 0}
            >
                <Box>
                    <Button
                        primary
                        disabled={!createFirstSummaryCtaEnabled(props.connectedCount)}
                        label={CREATE_SUMMARY_LABEL}
                        onClick={async () => props.onClick()}
                    />
                </Box>
            </Tip>
        </OnboardBanner>
    );
}
