// Copyright 2021
// ThatWorks.xyz Limited

import { useStatsigClient } from '@statsig/react-bindings';
import { Colors } from '@thatworks/colors';
import { ConnectorName } from '@thatworks/connector-api';
import { Events } from '@thatworks/shared-frontend/metrics';
import { Pages } from '@thatworks/shared-frontend/pages';
import { Box, Button, Text } from 'grommet';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../../components/AuthProvider';
import { BasicPage3, BasicPage3NavFunction } from '../../../../components/BasicPage3';
import { ConnectorIconSmall } from '../../../../components/ConnectorIcon';
import { ONBOARD_BANNER_HEADING_FONT_SIZE, OnboardBanner } from '../../../../components/OnboardBanner';
import { OnboardCircleCta } from '../../../../components/OnboardCircleCta';
import { TwHeading } from '../../../../components/TwHeading';
import { useNavNoRerender } from '../../../../shared/UseNavNoRerender';
import { FontFamily } from '../../../../theme';
import { WorkspacePostContent } from '../ws/WorkspacePostContent';
import { ONBOARD_EXAMPLE_POST } from './example-config';

const DEFAULT_TEXT_FADEIN_TIME = 1000;
const EXAMPLE_WIDTH = '720px';
export const CONNECT_APPS_LABEL = 'Connect your apps';

export function OnboardBannerWelcome(props: { onNext: () => void }) {
    const { getUserInfo } = useAuth();
    const [firstName, setFirstName] = useState<string | undefined>(undefined);

    useEffect(() => {
        getUserInfo()
            .then((info) => {
                setFirstName(info?.firstName);
            })
            .catch(() => {
                // ignore
            });
    }, [getUserInfo]);

    return (
        <OnboardBanner>
            <Text
                style={{ fontFamily: FontFamily.Callout }}
                size={ONBOARD_BANNER_HEADING_FONT_SIZE}
                color={Colors.black}
            >
                Welcome{firstName ? `, ${firstName}!` : '!'} 👋
            </Text>
            <Text textAlign="center">
                Start creating easy summaries of work you care about:
                <br />
                📚docs, 🏃 sprints, 📝 tasks, 💼 projects, 📈 metrics
                <br />
                from all your tools in one place.
            </Text>
            <Button primary label={CONNECT_APPS_LABEL} onClick={async () => props.onNext()} />
        </OnboardBanner>
    );
}

export function OnboardWelcome(props: { onNav: BasicPage3NavFunction }): JSX.Element {
    const navigate = useNavNoRerender();
    const { logEvent } = useStatsigClient();

    useEffect(() => {
        logEvent(Events.FrontendOnboardWelcomePageLoaded);
    }, [logEvent]);

    return (
        <BasicPage3 browserPageTitle={'Welcome'} onNav={props.onNav} onboarding>
            <Box gap="small" fill="horizontal" pad={{ horizontal: 'medium', vertical: 'small' }}>
                <OnboardBannerWelcome onNext={() => navigate(`../${Pages.app.subs.connect.root}`)} />
                <Box
                    gap="small"
                    animation={{ type: 'fadeIn', duration: DEFAULT_TEXT_FADEIN_TIME, delay: 1000 }}
                    align="center"
                >
                    <Box
                        width={EXAMPLE_WIDTH}
                        height={{ min: 'max-content' }}
                        gap="xxsmall"
                        direction="row"
                        align="center"
                        wrap
                    >
                        <Text style={{ alignItems: 'center' }}>Here is an example with data from</Text>
                        <ConnectorIconSmall name={ConnectorName.GOOGLE_DRIVE} sizePixels="22px" />
                        <ConnectorIconSmall name={ConnectorName.ATLASSIAN_JIRA} sizePixels="22px" />
                        <ConnectorIconSmall name={ConnectorName.GITHUB} sizePixels="22px" />
                        <ConnectorIconSmall name={ConnectorName.CLICKUP} sizePixels="22px" />
                    </Box>
                    <Box
                        height={{ min: 'max-content' }}
                        width={EXAMPLE_WIDTH}
                        pad="small"
                        round="20px"
                        elevation="large"
                        gap="xxsmall"
                        background={{ color: Colors.background_back }}
                    >
                        <Box gap="2px">
                            <TwHeading
                                level={3}
                                style={{
                                    fontFamily: FontFamily.Standard,
                                    fontWeight: 'bold',
                                    letterSpacing: '0.02em',
                                }}
                                color={Colors.black}
                            >
                                Weekly Update
                            </TwHeading>
                        </Box>

                        <WorkspacePostContent content={JSON.stringify(ONBOARD_EXAMPLE_POST)} />
                    </Box>
                    <Box height={{ min: 'max-content' }} align="center">
                        <OnboardCircleCta
                            onClick={() => navigate(`../${Pages.app.subs.connect.root}`)}
                            emoji={'🚀'}
                            buttonLabel={CONNECT_APPS_LABEL}
                            text="Ready?"
                        />
                    </Box>
                    <br />
                    <br />
                </Box>
            </Box>
        </BasicPage3>
    );
}
