// Copyright 2021
// ThatWorks.xyz Limited

import { SignIn, SignUp } from '@clerk/clerk-react';
import { Colors } from '@thatworks/colors';
import { Box } from 'grommet';
import { useEffect } from 'react';
import { AuthProvider, setAuthProviderInSessionStorage } from '../../../components/AuthProvider';

export default function Signup(): JSX.Element {
    useEffect(() => {
        setAuthProviderInSessionStorage(AuthProvider.Clerk);
    }, []);

    return (
        <Box fill align="center" justify="center">
            <SignUp
                appearance={{
                    variables: {
                        colorPrimary: Colors.accent_3,
                    },
                    layout: {
                        termsPageUrl: 'https://thatworks.ai/terms',
                        privacyPageUrl: 'https://thatworks.ai/privacy',
                    },
                }}
            />
        </Box>
    );
}
