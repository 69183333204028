// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { BasePmNodeAttributes, TextBlockNode } from '@thatworks/shared-frontend/prosemirror-nodes';
import { Box } from 'grommet';
import { FunctionComponent, useMemo } from 'react';
import styled from 'styled-components';
import {
    BaseReactNodeComponentProps,
    BaseReactNodeInjected,
} from '../../../../../components/prosemirror/BaseReactNode';
import { MarkdownToolbar } from '../../../../../components/TextEditorMarkdown';
import { useComposerStateManager } from './ComposerStateManagerContext';
import { TemplateBlockHeader, TemplateNodeBase } from './PmTemplateNodeBaseComponents';
import { BlockType } from './TemplateBlock';

const PlaceholderText = styled.div<{ text: string; showPlaceholder: boolean }>`
    position: relative;
    &::before {
        content: '${(props) => props.text}';
        display: ${(props) => (props.showPlaceholder ? 'block' : 'none')};
        font-size: 14px;
        position: absolute;
        top: 20px;
        left: 16px;
        color: ${Colors.status_disabled};
        pointer-events: none;
    }
`;

export type TextBlockAttributes = BasePmNodeAttributes;

export class TextBlockNodeReact extends BaseReactNodeInjected<TextBlockAttributes> {
    _injectedNode = new TextBlockNode();

    ComponentToRender: FunctionComponent<BaseReactNodeComponentProps<TextBlockAttributes>> = (props) => {
        const { blocks } = useComposerStateManager();

        const thisBlock = useMemo(() => {
            const found = blocks.find((b) => b.id === props.currentAttributes.uuid);
            if (!found || found.type !== BlockType.Text) {
                return undefined;
            }

            return found;
        }, [blocks, props.currentAttributes.uuid]);

        if (!thisBlock) {
            return null;
        }

        return (
            <TemplateNodeBase uuid={props.currentAttributes.uuid}>
                <TemplateBlockHeader type={BlockType.Text} showMinimize>
                    <MarkdownToolbar boxProps={{ pad: { horizontal: '0px' } }} />
                </TemplateBlockHeader>
                <PlaceholderText
                    showPlaceholder={props.node.textContent.length === 0}
                    text="Enter text: link to dashboards and docs or a add custom message."
                >
                    <Box
                        ref={props.forwardRef}
                        pad={{ horizontal: 'xsmall', vertical: 'xxsmall' }}
                        height={{ min: '36px' }}
                        // remove focus border
                        style={{ outline: 'none' }}
                    >
                        {/* The prosemirror child nodes will be automatically rendered here because of forwardRef */}
                    </Box>
                </PlaceholderText>
            </TemplateNodeBase>
        );
    };
}
