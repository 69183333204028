// Copyright 2021
// ThatWorks.xyz Limited

import { useQuery } from '@apollo/client';
import { Colors } from '@thatworks/colors';
import { Box, Text } from 'grommet';
import { Lock } from 'grommet-icons';
import { WorkspacePresetGroup } from '../../../../../__generated__/graphql';
import { DropdownMenuComponent } from '../../../../../components/DropdownMenu';
import { useTelemetryContext } from '../../../../../components/TelemetryContext';
import { useUserStateContext } from '../../../../../components/UserContext';
import { GET_WORKSPACES_FOR_NAV } from '../../../../../components/WorkspacesNav';
import { Hashtag } from '../../../../../icons/Hashtag';
import { FrontendStorageKeys } from '../../../../../storage-keys';
import { FontFamily } from '../../../../../theme';

export function WorkspacesBrowser(props: {
    organizationId: string;
    selected?: { id: string; label: string };
    onSelected: (s: { id: string; label: string } | undefined) => void;
}): JSX.Element {
    const { logger } = useTelemetryContext();
    const { postErrorMessage } = useUserStateContext();

    const { data, loading } = useQuery(GET_WORKSPACES_FOR_NAV, {
        onError: (error) => {
            postErrorMessage({ title: 'Error', shortDesc: 'Failed to get topics' });
            logger.error(error.message);
        },
        variables: {
            organizationId: props.organizationId,
        },
        onCompleted(data) {
            // If we have a topic Id in the local storage, pre select it
            const topicId = localStorage.getItem(FrontendStorageKeys.LastVisitedTopic);
            const ws = data.workspaces.find((w) => w.id === topicId);
            if (!props.selected && data.workspaces.length > 0) {
                const selectedTopic = { id: data.workspaces[0].id, label: data.workspaces[0].name };
                if (ws) {
                    selectedTopic.id = ws.id;
                    selectedTopic.label = ws.name;
                }
                props.onSelected(selectedTopic);
            }
        },
    });

    return (
        <DropdownMenuComponent
            label={props.selected ? props.selected.label : 'Choose a topic'}
            onSelectionChange={(s) => {
                if (s.length > 0) {
                    props.onSelected({ id: s[0].id, label: s[0].label });
                } else {
                    props.onSelected(undefined);
                }
            }}
            options={
                data
                    ? data.workspaces.map((t) => ({
                          id: t.id,
                          label: t.name,
                          icon:
                              t.group === WorkspacePresetGroup.Private ? <Lock size="12px" /> : <Hashtag size="12px" />,
                      }))
                    : []
            }
            selected={props.selected ? [props.selected] : []}
            singleSelectionOnly
            boxProps={{
                border: { color: Colors.black },
                width: '400px',
                justify: 'between',
                round: '8px',
                pad: '5px 10px',
                margin: '0',
                background: { color: Colors.background_front },
            }}
            getTextProps={() => ({
                color: Colors.dark_3,
                size: '14px',
                style: { fontWeight: 'bold', fontFamily: FontFamily.Callout },
            })}
            showLoadingSpinner={loading}
            dropMenuLabel={
                data && data.workspaces.length === 0 ? (
                    <Box pad={{ horizontal: 'small', top: 'xsmall' }}>
                        <Text size="14px">No Topics available</Text>
                    </Box>
                ) : null
            }
        />
    );
}
