// Copyright 2021
// ThatWorks.xyz Limited

import { useQuery } from '@apollo/client';
import { Colors } from '@thatworks/colors';
import { ConnectorName } from '@thatworks/connector-api';
import { Box, Text } from 'grommet';
import objectHash from 'object-hash';
import { useEffect, useMemo, useState } from 'react';
import { gql } from '../../../../../__generated__';
import {
    ActivityItem,
    GetTimelineMetricChartsQuery,
    TimelineActivityItemsQuery,
    TimelineIndicatorsQuery,
} from '../../../../../__generated__/graphql';
import { MetricBoxV2 } from '../../../../../components/MetricBox';
import { MetricChartDataQl } from '../../../../../components/MetricChartDataQl';
import { useTelemetryContext } from '../../../../../components/TelemetryContext';
import { useUserStateContext } from '../../../../../components/UserContext';
import { GroupInsightLabelComponent } from '../../ws/components/pm-nodes/GroupedInsightNode';
import { InsightLabelComponent } from '../../ws/components/pm-nodes/InsightNode';
import { TaskOverview } from '../../ws/components/pm-nodes/TaskPreviewNode';
import { ActivityQueryVars } from '../helpers/preset-filters';
import { EmptyGroupMapping, fromActivityItemToPreview } from './activity-node-parser';

export const GET_ACTIVITY_ITEMS = gql(/* GraphQL */ `
    query TimelineActivityItems(
        $idForCache: ID!
        $timelineId: String!
        $sort: ItemSort
        $grouping: GroupSettingsInput
        $filters: ActivityItemFiltersInput!
    ) {
        timelineActivity(
            idForCache: $idForCache
            timelineId: $timelineId
            sort: $sort
            grouping: $grouping
            filters: $filters
        ) {
            groups {
                type
                subgroups {
                    ids
                    name
                    itemTypeGrouping {
                        label
                        connector
                        iconUrl
                        color
                        ids
                    }
                    props {
                        ... on ItemSubgroupTaskStatus {
                            isDoneStatus
                            sortOrder
                            status
                        }
                    }
                }
            }
            items {
                title
                id
                iconUrl
                connector
                url
                actors {
                    names {
                        color
                        isUser
                        name
                    }
                    hasMore
                }
                parents {
                    readableConnectorObjectType
                    name
                    url
                }
                properties {
                    color
                    name
                    value
                    iconUrl
                    valueType
                    propertyType
                }
                changeDescription {
                    color
                    decoration
                    value
                }
                timeRange {
                    newest
                    oldest
                }
                docDiff {
                    ... on DocDiffSummarized {
                        summary
                    }
                }
                comments {
                    comments {
                        authorDisplayName
                        comment
                        date
                        userMention
                    }
                    more
                    total
                }
            }
        }
    }
`);

export const GET_TIMELINE_INDICATORS = gql(/* GraphQL */ `
    query TimelineIndicators($timelineId: ID!) {
        timelineIndicators(timelineId: $timelineId) {
            metricBoxes {
                title
                identifier
                value
                valueFormatted
                connector
                itemUuid
                parents {
                    name
                    connectorObjectType
                    url
                }
                deltas {
                    color
                    label
                    value
                }
                xAxisValue {
                    type
                    value
                }
            }
            insights {
                connector
                iconUrl
                identifier
                data {
                    value
                    color
                    iconUrl
                    itemUuids
                    delta
                }
                itemUuids
                title {
                    value
                    type
                }
            }
            groupedInsights {
                title
                description
                identifier
                insights {
                    connector
                    iconUrl
                    identifier
                    data {
                        value
                        color
                        iconUrl
                        itemUuids
                        delta
                    }
                    itemUuids
                    title {
                        value
                        type
                    }
                }
            }
            items {
                title
                id
                iconUrl
                connector
                url
                actors {
                    names {
                        color
                        isUser
                        name
                    }
                    hasMore
                }
                parents {
                    readableConnectorObjectType
                    name
                    url
                }
                properties {
                    color
                    name
                    value
                    iconUrl
                    valueType
                    propertyType
                }
                changeDescription {
                    color
                    decoration
                    value
                }
                timeRange {
                    newest
                    oldest
                }
                docDiff {
                    ... on DocDiffSummarized {
                        summary
                    }
                }
                comments {
                    comments {
                        authorDisplayName
                        comment
                        date
                        userMention
                    }
                    more
                    total
                }
            }
        }
    }
`);

export const GET_TIMELINE_CHARTS = gql(/* GraphQL */ `
    query GetTimelineMetricCharts($timelineId: ID!) {
        timelineMetricCharts(timelineId: $timelineId) {
            id
            data {
                title
                chartData
                connector
                summary {
                    summary {
                        ... on MetricChartSummaryTrend {
                            trend
                            type
                        }
                        ... on MetricChartSummaryDelta {
                            value
                            label
                            type
                        }
                        ... on MetricChartSummaryLatestValue {
                            formattedValue
                            dateIso
                            type
                        }
                    }
                    axisId
                    dataTitle
                }
            }
            errors {
                title
                error
                connector
            }
            isCombined
        }
    }
`);

const MAX_AUTO_SELECTED_ITEMS = 5;

interface SubgroupActivityItems {
    subgroupName: string | undefined;
    items: {
        itemType: string | undefined;
        items: TimelineActivityItemsQuery['timelineActivity']['items'];
    }[];
}

export function ActivityItemList(props: {
    timelineId: string;
    queryVars: ActivityQueryVars;
    onChange: (subGroups: SubgroupActivityItems[] | undefined) => void;
    selectedItems: Set<ActivityItem['id']>;
    onChangeLoading: (loading: boolean) => void;
}): JSX.Element | null {
    const { logger } = useTelemetryContext();
    const { postErrorMessage } = useUserStateContext();
    const [itemSubgroups, setItemSubgroups] = useState<SubgroupActivityItems[] | undefined>(undefined);
    const variablesWithId = useMemo(() => {
        const variables = {
            timelineId: props.timelineId,
            filters: props.queryVars.filters,
            grouping: props.queryVars.grouping || undefined,
            sort: props.queryVars.sort,
        };
        // the variables are used to generate a unique id for the cache
        // this way we can invalidate the cache when the variables change
        // but also reuse the cache when the variables are the same
        return {
            ...variables,
            idForCache: objectHash(variables),
        };
    }, [props.queryVars.filters, props.queryVars.grouping, props.queryVars.sort, props.timelineId]);
    const { loading } = useQuery(GET_ACTIVITY_ITEMS, {
        variables: variablesWithId,
        onError: ({ message, graphQLErrors }) => {
            if (graphQLErrors) {
                for (const err of graphQLErrors) {
                    switch (err.extensions?.code) {
                        case 'CACHE_INVALID':
                            // Just return since the timeline will be created in the template block
                            return;
                    }
                }
            }
            postErrorMessage({ title: 'Error', shortDesc: `Failed to fetch items` });
            logger.error(message);
        },
        onCompleted: (data) => {
            if (!data) {
                return;
            }
            const timelineMap = new Map<string, TimelineActivityItemsQuery['timelineActivity']['items'][0]>(
                data.timelineActivity.items.map((item) => [item.id, item]),
            );
            const subGroupActivityItems: SubgroupActivityItems[] = [];
            const preSelectedItems: Set<ActivityItem['id']> = new Set();
            data.timelineActivity.groups.forEach((group) => {
                group.subgroups.forEach((subgroup) => {
                    let subgroupName = subgroup.name ?? undefined;
                    if (subgroupName) {
                        const mappedLabel = EmptyGroupMapping.get(subgroupName);
                        if (mappedLabel) {
                            subgroupName = mappedLabel;
                        }
                    }
                    const subgroupItems: SubgroupActivityItems = {
                        subgroupName,
                        items: [],
                    };
                    if (subgroup.itemTypeGrouping) {
                        subgroup.itemTypeGrouping.forEach((itemTypeGrouping) => {
                            const items = itemTypeGrouping.ids
                                .map((id) => timelineMap.get(id))
                                .filter((v) => v) as TimelineActivityItemsQuery['timelineActivity']['items'];
                            if (items.length > 0) {
                                subgroupItems.items.push({
                                    itemType: itemTypeGrouping.label,
                                    items,
                                });
                                items.forEach((item) => {
                                    if (item) {
                                        // TODO verify if this is correct
                                        if (preSelectedItems.size < MAX_AUTO_SELECTED_ITEMS) {
                                            preSelectedItems.add(item.id);
                                        }
                                    }
                                });
                            }
                        });
                    } else {
                        subgroup.ids.forEach((id, index) => {
                            const item = timelineMap.get(id);
                            if (item) {
                                subgroupItems.items.push({
                                    itemType: undefined,
                                    items: [item],
                                });

                                if (index < MAX_AUTO_SELECTED_ITEMS) {
                                    preSelectedItems.add(item.id);
                                }
                            }
                        });
                    }
                    subGroupActivityItems.push(subgroupItems);
                });
            });
            setItemSubgroups(subGroupActivityItems);
            props.onChange(subGroupActivityItems);
        },
    });

    const onChangeLoading = props.onChangeLoading;
    useEffect(() => {
        onChangeLoading(loading);
    }, [onChangeLoading, loading]);

    if (itemSubgroups === undefined) {
        return null;
    }

    if (itemSubgroups.length === 0) {
        return <Text size="14">No items found</Text>;
    }

    return (
        <Box height={{ min: 'max-content', height: 'max-content' }} gap="xsmall">
            {itemSubgroups.map((subgroup, si) => (
                <Box key={`subgroup-${si}`} height={{ min: 'max-content', height: 'max-content' }} gap="xsmall">
                    {subgroup.subgroupName && (
                        <Text
                            weight="bold"
                            size="18px"
                            style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                        >
                            {subgroup.subgroupName}
                        </Text>
                    )}
                    {subgroup.items.map((it, iti) => (
                        <Box key={`${si}-${iti}`} gap="xsmall">
                            {it.itemType && (
                                <Text weight="bold" size="16px">
                                    {it.itemType}
                                </Text>
                            )}
                            {it.items.map((item) => (
                                <Box key={`${iti}-item-${item.id}`} gap="xxsmall">
                                    <TaskOverview
                                        cardColor={{
                                            color: Colors.background_back,
                                            opacity: 0,
                                        }}
                                        item={{
                                            connector: item.connector as ConnectorName,
                                            iconUrl: item.iconUrl || undefined,
                                            id: item.id,
                                            title: item.title,
                                            parents: item.parents.map((p) => ({
                                                connectorObjectType: p.readableConnectorObjectType,
                                                name: p.name,
                                                url: p.url || undefined,
                                            })),
                                            properties: item.properties.map((p) => ({
                                                name: p.name,
                                                propertyType: p.propertyType,
                                                value: p.value,
                                                valueType: p.valueType,
                                                iconUrl: p.iconUrl || undefined,
                                            })),
                                            changeDescription: item.changeDescription,
                                            docDiff:
                                                item.docDiff && item.docDiff.__typename === 'DocDiffSummarized'
                                                    ? {
                                                          summary: item.docDiff.summary,
                                                      }
                                                    : undefined,
                                            url: item.url || undefined,
                                            comments: item.comments || undefined,
                                        }}
                                        // selected={props.selectedItems.has(item.id)}
                                        // onItemSelected={(s, sId) => props.onItemsSelected([{ selected: s, id: sId }])}
                                    />
                                </Box>
                            ))}
                        </Box>
                    ))}
                </Box>
            ))}
        </Box>
    );
}

export function ActivityIndicatorList(props: {
    timelineId: string;
    selectedIndicators: string[];
    onIndicatorToggled: (indicatorId: string) => void;
    onChange: (indicators: TimelineIndicatorsQuery | undefined) => void;
    onChangeLoading: (loading: boolean) => void;
}): JSX.Element | null {
    const { logger } = useTelemetryContext();
    const { postErrorMessage } = useUserStateContext();

    const onChange = props.onChange;
    useEffect(() => {
        return () => {
            onChange(undefined);
        };
        // need this to run only once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { loading, data } = useQuery(GET_TIMELINE_INDICATORS, {
        variables: {
            timelineId: props.timelineId,
        },
        onError: (error) => {
            postErrorMessage({ title: 'Error', shortDesc: `Failed to fetch indicators` });
            logger.error(error.message);
        },
        onCompleted: (d) => {
            if (d) {
                props.onChange(d);
            }
        },
    });

    const onChangeLoading = props.onChangeLoading;
    useEffect(() => {
        onChangeLoading(loading);
    }, [onChangeLoading, loading]);

    if (!data) {
        return null;
    }

    if (
        data.timelineIndicators.metricBoxes.length === 0 &&
        data.timelineIndicators.insights.length === 0 &&
        data.timelineIndicators.groupedInsights.length === 0
    ) {
        return <Text size="14">No indicators found</Text>;
    }

    return (
        <Box height={{ min: 'max-content', height: 'max-content' }} gap="xsmall">
            {data.timelineIndicators.metricBoxes.map((metricBox, i) => (
                <MetricBoxV2
                    key={`indicator-${i}`}
                    metricBox={metricBox}
                    mode="inspector"
                    selectedIndicators={props.selectedIndicators}
                    onToggle={() => props.onIndicatorToggled(metricBox.identifier)}
                />
            ))}
            {data.timelineIndicators.insights.map((insight, i) => (
                <InsightLabelComponent
                    key={`insight-${i}`}
                    insight={{
                        data: insight.data,
                        title: insight.title,
                        connector: insight.connector,
                        iconUrl: insight.iconUrl,
                        identifier: insight.identifier,
                        items: data.timelineIndicators.items.map((item) => fromActivityItemToPreview(item)),
                    }}
                    boxProps={{ margin: { bottom: 'xxsmall' } }}
                    mode="inspector"
                    selectedIndicators={props.selectedIndicators}
                    onToggle={() => props.onIndicatorToggled(insight.identifier)}
                />
            ))}
            {data.timelineIndicators.groupedInsights.map((group, i) => (
                <GroupInsightLabelComponent
                    key={`grouped-insight-${i}`}
                    group={{
                        insights: group.insights.map((ins) => ({
                            data: ins.data,
                            title: ins.title,
                            connector: ins.connector,
                            iconUrl: ins.iconUrl,
                            identifier: ins.identifier,
                            items: data.timelineIndicators.items.map((item) => fromActivityItemToPreview(item)),
                        })),
                        title: group.title,
                        identifier: group.identifier,
                        description: group.description || undefined,
                    }}
                    boxProps={{ margin: { bottom: 'xxsmall' } }}
                    mode="inspector"
                    selectedIndicators={props.selectedIndicators}
                    onToggle={() => props.onIndicatorToggled(group.identifier)}
                />
            ))}
        </Box>
    );
}

export function ActivityChartList(props: {
    timelineId: string;
    onChange: (charts: GetTimelineMetricChartsQuery | undefined) => void;
    onChangeLoading: (loading: boolean) => void;
}): JSX.Element | null {
    const { logger } = useTelemetryContext();
    const { postErrorMessage } = useUserStateContext();

    const onChange = props.onChange;
    useEffect(() => {
        return () => {
            onChange(undefined);
        };
        // need this to run only once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { loading, data } = useQuery(GET_TIMELINE_CHARTS, {
        variables: {
            timelineId: props.timelineId,
        },
        onError: (error) => {
            postErrorMessage({ title: 'Error', shortDesc: `Failed to fetch charts` });
            logger.error(error.message);
        },
        onCompleted: (d) => {
            if (d) {
                props.onChange(d);
            }
        },
    });

    const onChangeLoading = props.onChangeLoading;
    useEffect(() => {
        onChangeLoading(loading);
    }, [onChangeLoading, loading]);

    if (!data) {
        return null;
    }

    if (data.timelineMetricCharts.data.length === 0) {
        return <Text size="14">No charts found</Text>;
    }

    return (
        <Box height={{ min: 'max-content', height: 'max-content' }} gap="xsmall">
            {data.timelineMetricCharts.data.map((chart, i) => (
                <MetricChartDataQl key={`chart-${i}`} chart={chart} headingSize={6} hideLegend />
            ))}
        </Box>
    );
}
